export class CallbackRegistryService {
  #callbacks = new Set();

  addCallback(callback) {
    this.#callbacks.add(callback);
  }

  removeCallback(callback) {
    this.#callbacks.delete(callback);
  }

  runCallbacks() {
    this.#callbacks.forEach(callback => callback());
  }
}
