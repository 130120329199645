<template>
  <div>
    <AulaNavigationToolbar class="tabs-nav">
      <AulaButtons>
        <AulaButton
          v-for="(tab, index) in tabs"
          :key="index"
          fill="clear"
          class="tab-item text-uppercase"
          :class="{ 'active-tab': tabValue === tab.value }"
          :aria-selected="tabValue === tab.value"
          @click="selectTab(tab)"
        >
          {{ tab.name }}
        </AulaButton>
      </AulaButtons>
    </AulaNavigationToolbar>
    <div class="my-4">
      <UserDetailsAdministrationTabsRolesAndRights v-if="tabsEnum.ROLES_AND_RIGHTS === tabValue" />
      <UserDetailsAdministrationTabsGroups v-if="tabsEnum.GROUPS === tabValue" />
      <UserDetailsPortalAdministrationTabsLockUser v-if="tabsEnum.LOCK_USER === tabValue" />
      <UserDetailsAdministrationTabsUserData v-if="tabsEnum.USER_DATA === tabValue" />
      <UserDetailsResponseHistory v-if="tabsEnum.RESPONSE_HISTORY === tabValue" />
    </div>
  </div>
</template>
<script lang="js">
import AulaNavigationToolbar from '../navigation/AulaNavigationToolbar';
import AulaButtons from '../AulaButtons';
import AulaButton from '../AulaButton';
import { UserDetailsAdministrationTabsEnum } from '../../enums/UserDetailsAdministrationTabsEnum';
import UserDetailsAdministrationTabsRolesAndRights from './UserDetailsAdministrationTabsRolesAndRights';
import UserDetailsAdministrationTabsGroups from './UserDetailsAdministrationTabsGroups';
import UserDetailsPortalAdministrationTabsLockUser from './UserDetailsAdministrationTabsLockUser';
import { providerKeyEnum } from '../../enums/providerKeyEnum';
import { mapGetters } from 'vuex';
import { types } from '../../../src/store/types/types';
import { permissionEnum } from '../../enums/permissionEnum';
import UserDetailsAdministrationTabsUserData from "../../../src_adm/components/users/AllUserData";
import UserDetailsResponseHistory from "./UserDetailsResponseHistory.vue";

export default {
  name: 'UserDetailsPortalAdministrationTabs',
  components: {
    UserDetailsResponseHistory,
    UserDetailsAdministrationTabsGroups,
    UserDetailsAdministrationTabsRolesAndRights,
    UserDetailsAdministrationTabsUserData,
    UserDetailsPortalAdministrationTabsLockUser,
    AulaButton,
    AulaButtons,
    AulaNavigationToolbar,
  },
  inject: {
    getHasPermissionToLockInstitutionProfile: providerKeyEnum.hasLockPermission,
  },
  provide() {
    return {
      [providerKeyEnum.institutionProfile]: () => this.institutionProfile,
    };
  },
  props: {
    institutionProfile: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      tabValue: null,
      tabsEnum: UserDetailsAdministrationTabsEnum,
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      selectedInstitution: types.GET_SELECTED_INSTITUTION,
      activeProfile: types.GET_ACTIVE_PROFILE,
      hasPermissionInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
    }),
    isInstitutionProfileDeactivated() {
      return this.institutionProfile.deactivated;
    },
    hasPermissionToLockInstitutionProfile() {
      return this.getHasPermissionToLockInstitutionProfile();
    },
    tabs() {
      const tabs = [];
      if (!this.isInstitutionProfileDeactivated) {
       if (this.hasPermissionInstitution(permissionEnum.HANDLE_USER_ROLES, this.activeProfile.institutionCode)) {
         tabs.push({
           name: this.$options.filters.fromTextKey('USER_TABLE_USER_ROLES'),
           value: UserDetailsAdministrationTabsEnum.ROLES_AND_RIGHTS,
         });
       }
        tabs.push({
          name: this.$options.filters.fromTextKey('GROUP_HEADER_LABEL'),
          value: UserDetailsAdministrationTabsEnum.GROUPS,
        });
      }
      if (this.hasPermissionToLockInstitutionProfile) {
        tabs.push({
          name: this.$options.filters.fromTextKey('USERS_LOCK_USER'),
          value: UserDetailsAdministrationTabsEnum.LOCK_USER,
        });
      }
      if (this.hasPermissionInstitution(permissionEnum.HANDLE_USER_DATA, this.activeProfile.institutionCode)) {
        tabs.push({
          name: `${this.$options.filters.fromTextKey('ALL_USER_DATA_HEADER')} ${this.institutionProfile.fullName}`,
          value: UserDetailsAdministrationTabsEnum.USER_DATA,
        });
      }
      if (this.hasPermissionInstitution(permissionEnum.HANDLE_CONSENT_REVISION, this.activeProfile.institutionCode)
        || this.hasPermissionInstitution(permissionEnum.HANDLE_ADDITIONAL_MASTER_DATA_REVISION, this.activeProfile.institutionCode)) {
        tabs.push({
          name: this.$t('user.response_history'),
          value: UserDetailsAdministrationTabsEnum.RESPONSE_HISTORY,
        });
      }
      return tabs;
    },
  },
  mounted() {
    this.tabValue = this.getInitialTabValue();
  },
  methods: {
    getInitialTabValue() {
      return this.tabs[0].value;
    },
    selectTab(tab) {
      this.tabValue = tab.value;
    },
  },
};
</script>
