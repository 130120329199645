





























import { PropType } from 'vue';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    institutions: { type: Array as PropType<InstitutionProfileModel[]>, required: true },
    value: { type: Number, default: null },
    disabled: { type: Boolean, default: false },
  },
  emits: ['change'],
  methods: {
    emitChange(value) {
      this.$emit('change', value);
    },
  },
};
