<template>
  <div class="user-data-table mt-3">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="div" role="tab" @click="unfoldTable">
        {{ 'ALL_USER_DATA_HEADER_TEXT_MESSAGES' | fromTextKey }}
        <div class="pull-right">
          <i :class="showTable ? 'icon-Aula_up-arrow' : 'icon-Aula_down-arrow'" />
        </div>
      </b-card-header>
      <b-collapse :id="tableRef" v-model="showTable" :accordion="tableRef" role="tabpanel">
        <b-card-body v-if="showTable">
          <b-row class="mb-2">
            <b-col cols="4">
              {{ 'TABLE_DATE_FILTER_START' | fromTextKey }}
              <el-date-picker
                id="startDate"
                v-model="startDate"
                :class="{ 'red-border': validationMissingStartDate || validationStartDateAfterEndDate }"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                type="date"
                :editable="false"
                :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
                @focus="datePickerModifier()"
                @change="validateDates()"
              />
              <b-alert variant="danger" :show="validationMissingStartDate">
                {{ 'ALL_USER_MISSING_START_DATE' | fromTextKey }}
              </b-alert>
              <b-alert variant="danger" :show="validationStartDateAfterEndDate">
                {{ 'ALL_USER_DATA_START_DATE_IS_AFTER_END_DATE' | fromTextKey }}
              </b-alert>
            </b-col>
            <b-col cols="4">
              {{ 'TABLE_DATE_FILTER_END' | fromTextKey }}
              <el-date-picker
                id="endDate"
                v-model="endDate"
                :class="{ 'red-border': validationMissingEndDate || validationStartDateAfterEndDate }"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                type="date"
                :editable="false"
                :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
                @focus="datePickerModifier()"
                @change="validateDates()"
              />
              <b-alert variant="danger" :show="validationMissingEndDate">
                {{ 'ALL_USER_MISSING_END_DATE' | fromTextKey }}
              </b-alert>
            </b-col>
          </b-row>
          <b-table-simple>
            <b-thead>
              <b-tr>
                <b-th class="text-uppercase">
                  {{ 'USERS_FIELDS_MESSAGE_REVISIONS_CREATION_DATE' | fromTextKey }}
                </b-th>
                <b-th class="text-uppercase">
                  {{ 'USERS_FIELDS_MESSAGE_REVISIONS_THREAD_SUBJECT' | fromTextKey }}
                </b-th>
                <b-th class="text-uppercase">
                  {{ 'USERS_FIELDS_MESSAGE_REVISIONS_THREAD_PARTICIPANTS' | fromTextKey }}
                </b-th>
                <b-th class="text-uppercase">
                  {{ 'USERS_FIELDS_MESSAGE_REVISIONS_MESSAGE_CONTENT_BEFORE' | fromTextKey }}
                </b-th>
                <b-th class="text-uppercase">
                  {{ 'USERS_FIELDS_MESSAGE_REVISIONS_MESSAGE_CONTENT_AFTER' | fromTextKey }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-if="messageRevisionList.messageRevisions.length === 0">
                <b-td colspan="5" class="text-center cursor-default">
                  {{ 'USERS_MESSAGE_REVISIONS_NO_RESULTS' | fromTextKey }}
                </b-td>
              </b-tr>
              <b-tr v-for="messageRevision in messageRevisionList.messageRevisions" v-else :key="messageRevision.id">
                <b-td class="cursor-default">{{
                  moment(messageRevision.revisionDate).format('DD-MM-YYYY HH:mm')
                }}</b-td>
                <b-td class="cursor-default">{{ messageRevision.threadSubject }}</b-td>
                <b-td class="cursor-default">
                  <span v-for="participant in messageRevision.threadParticipants" :key="participant.id">
                    {{ participant | displayProfileNameWithMetadata }} <br />
                  </span>
                </b-td>
                <b-td class="cursor-default" v-html="messageRevision.message.text.html"></b-td>
                <b-td v-if="isDeleted(messageRevision)" class="cursor-default">
                  <em>{{ 'USERS_MESSAGE_REVISIONS_MESSAGE_DELETED' | fromTextKey }}</em>
                </b-td>
                <b-td v-else class="cursor-default" v-html="messageRevision.updatedContent.html"></b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div v-if="messageRevisionList.messageRevisions.length !== 0 && !hasValidationErrors" class="text-center">
            <template v-if="messageRevisionList.lastEvaluatedKey !== null">
              <b-button @click="loadMore">
                {{ 'USERS_LOAD_MORE_MESSAGE_REVISIONS' | fromTextKey }}
              </b-button>
            </template>
            <template v-else>
              <b-button variant="link" :disabled="true">
                {{ 'USERS_LOAD_MORE_MESSAGE_REVISIONS_NO_MORE_RESULTS' | fromTextKey }}
              </b-button>
            </template>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { messageRevisionTypes } from '../../../shared/enums/messageRevisionTypes';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { types } from '../../store/types/types';

export default {
  props: {
    selectedUser: { type: Object, default: null },
  },
  data() {
    return {
      parentTypes: parentTypes,
      moment: moment,
      validationMissingStartDate: false,
      validationMissingEndDate: false,
      validationStartDateAfterEndDate: false,
      showTable: false,
      startKey: null,
      startDate: moment().subtract(1, 'years').format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
      endDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
      isLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      messageRevisionList: types.MESSAGES_GET_MESSAGE_REVISION_LIST_ADMIN,
    }),
    hasValidationErrors() {
      return this.validationMissingStartDate || this.validationMissingEndDate || this.validationStartDateAfterEndDate;
    },
  },
  mounted() {
    this.resetMessageRevisions();
  },
  methods: {
    unfoldTable() {
      this.showTable = !this.showTable;
      if (this.showTable && !this.isLoaded) {
        this.loadMore();
      }
    },
    loadMore() {
      this.isLoaded = true;
      this.loadMessageRevisions({
        instProfileId: this.selectedUser.id,
        start: moment(this.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
        end: moment(this.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
        limit: 20,
        startKey: this.messageRevisionList.lastEvaluatedKey,
      });
    },
    validateDates() {
      this.validationMissingStartDate = this.startDate === null;
      this.validationMissingEndDate = this.endDate === null;
      this.validationStartDateAfterEndDate = moment(this.startDate).isAfter();

      if (!this.hasValidationErrors) {
        this.resetMessageRevisions();
        this.loadMore();
      }
    },
    isDeleted(revision) {
      return revision.revisionType === messageRevisionTypes.DELETE;
    },
    ...mapMutations({
      resetMessageRevisions: types.MUTATE_RESET_MESSAGE_REVISION_LIST_ADMIN,
    }),
    ...mapActions({
      loadMessageRevisions: types.MESSAGES_LOAD_MESSAGE_REVISIONS_ADMIN,
    }),
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
/deep/ .aula-table {
  thead th:last-child {
    padding-right: 50px;
  }
}

.select-all-checkbox {
  position: absolute;
  right: 25px;
  top: 90px;
  z-index: 1050;
}

.icon-Aula_down-arrow,
.icon-Aula_up-arrow {
  font-size: 10px;
}
/deep/ .b-table tbody tr td {
  vertical-align: top;
}
</style>
