<template>
  <div class="additional-questions">
    <div :class="{
      questions: isMultipleProfiles,
      'question-yesno': !isMultipleProfiles,
    }">
      <div class="question">
        {{ question.additionalDataQuestion }}
      </div>
      <div v-for="(profile, profileIndex) in profiles" :key="profileIndex" :class="{
        mobile: isMobile,
        textarea: question.answerType === additionalDataGrossListEnum.TEXT,
        'p-2': question.answerType !== additionalDataGrossListEnum.YESNO && !isRevisionHistoryViewMode,
        'red-border': showYesNoQuestionWarning̣(question, profile),
      }" class="answer">
        <template v-if="profile.additionals[question.additionalDataId] != undefined">
          <template v-if="isRevisionHistoryViewMode">
            <template v-if="
              profile.additionals[question.additionalDataId].updaterName &&
              profile.additionals[question.additionalDataId].updatedAt
            ">
              {{ 'ADDITIONAL_DATA_EDITED_BY' | fromTextKey }}
              <b>{{ profile.additionals[question.additionalDataId].updaterName }}
                <template v-if="profile.additionals[question.additionalDataId].updaterInstitutionRole != null">
                  ({{
                    ('INSTITUTION_ROLE_' +
                      profile.additionals[question.additionalDataId].updaterInstitutionRole
                        .replace('-', '')
                        .toUpperCase())
                    | fromTextKey
                  }})</template>
              </b>
              {{ 'ADDITIONAL_DATA_EDITED_AT' | fromTextKey }}
              <b>{{ profile.additionals[question.additionalDataId].updatedAt }}</b>
            </template>
          </template>
          <template v-else>
            <template v-if="question.answerType == additionalDataGrossListEnum.YESNO">
              <b-form-radio-group v-if="!isDisabled"
                v-model="profile.additionals[question.additionalDataId].yesNoResponse"
                :disabled="!isAbleToEdit(profile.additionals[question.additionalDataId].canCurrentProfileRespond)"
                class="d-flex justify-content-between" required
                @change="showQuestionYesNoMultipleProfiles(question.additionalDataId, profile, $event)"
                @input="showQuestionYesNoMultipleProfiles(question.additionalDataId, profile, $event)">
                <b-form-radio class="checked-is-disabled" value="yes">
                  <span aria-hidden="true">{{ 'YES' | fromTextKey }}</span>
                  <span class="sr-only">{{
                    'ARIA_LABEL_CHECK_YES'
                    | fromTextKey({
                      question: question.additionalDataQuestion,
                      profileName: profile.institutionProfile.name,
                      institutionName: profile.institutionProfile.institutionName,
                    })
                  }}</span>
                </b-form-radio>
                <b-form-radio class="checked-is-disabled" value="no">
                  <span aria-hidden="true">{{ 'NO' | fromTextKey }}</span>
                  <span class="sr-only">{{
                    'ARIA_LABEL_CHECK_NO'
                    | fromTextKey({
                      question: question.additionalDataQuestion,
                      profileName: profile.institutionProfile.name,
                      institutionName: profile.institutionProfile.institutionName,
                    })
                  }}</span>
                </b-form-radio>
              </b-form-radio-group>
              <div v-else class="answer-text">
                {{
                  ('ADDITIONAL_ANSWER_' + profile.additionals[question.additionalDataId].yesNoResponse).toUpperCase()
                  | fromTextKey
                }}
              </div>
            </template>
            <template v-else-if="question.answerType == additionalDataGrossListEnum.PHONENUMBER">
              <b-form-input v-model="profile.additionals[question.additionalDataId].response" :class="{
                'red-border': showPhoneQuestionWarning(question, profile),
              }" :disabled="!isAbleToEdit(profile.additionals[question.additionalDataId].canCurrentProfileRespond)"
                :placeholder="'PLACEHOLDER_PHONE' | fromTextKey" class="answer-input" type="tel" />
            </template>
            <template v-else>
              <b-form-textarea v-model="profile.additionals[question.additionalDataId].response"
                :aria-label="question.additionalDataQuestion" :class="{
                  'red-border': showTextQuestionWarning̣(question, profile),
                }" :disabled="!isAbleToEdit(profile.additionals[question.additionalDataId].canCurrentProfileRespond)"
                :placeholder="'PLACEHOLDER_TEXT' | fromTextKey" class="answer-textarea-input" rows="3" />
            </template>
          </template>
        </template>
      </div>
    </div>
    <div v-if="!isRevisionHistoryViewMode && question.showOptionQuestionYes" class="questions optional-question">
      <div class="question optional-question">
        {{ question.optionalQuestionForYes }}
      </div>
      <div v-for="(profile, profileIndex) in profiles" :key="profileIndex" :class="{
        disabled:
          profile.additionals != null &&
          profile.additionals[question.additionalDataId] != undefined &&
          profile.additionals[question.additionalDataId].yesNoResponse != 'yes',
      }" class="answer p-2">
        <b-form-textarea v-if="
          profile.additionals[question.additionalDataId] != undefined &&
          profile.additionals[question.additionalDataId].yesNoResponse == 'yes'
        " v-model="profile.additionals[question.additionalDataId].responding"
          :aria-label="question.additionalDataQuestion"
          :disabled="!isAbleToEdit(profile.additionals[question.additionalDataId].canCurrentProfileRespond)"
          :placeholder="'PLACEHOLDER_TEXT' | fromTextKey" class="answer-textarea-input" />
      </div>
    </div>
    <div v-if="!isRevisionHistoryViewMode && question.showOptionQuestionNo" class="questions optional-question">
      <div class="question optional-question">
        {{ question.optionalQuestionForNo }}
      </div>
      <div v-for="(profile, profileIndex) in profiles" :key="profileIndex" :class="{
        disabled:
          profile.additionals != null &&
          profile.additionals[question.additionalDataId] != undefined &&
          profile.additionals[question.additionalDataId].yesNoResponse != 'no',
      }" class="answer p-2">
        <b-form-textarea v-if="
          profile.additionals[question.additionalDataId] != undefined &&
          profile.additionals[question.additionalDataId].yesNoResponse == 'no'
        " v-model="profile.additionals[question.additionalDataId].responding"
          :aria-label="question.additionalDataQuestion"
          :disabled="!isAbleToEdit(profile.additionals[question.additionalDataId].canCurrentProfileRespond)"
          :placeholder="'PLACEHOLDER_TEXT' | fromTextKey" class="answer-textarea-input" />
      </div>
    </div>
  </div>
</template>
<script>
import {additionalDataGrossListEnum} from '../../enums/additionalDataGrossList';
import {mapGetters} from 'vuex';
import {types} from '../../../src/store/types/types';
import Vue from 'vue';
import {portalRoles} from '../../enums/portalRoles';

export default {
  props: {
    isSubmitted: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    question: { type: Object, default: null },
    profiles: { type: Array, default: () => [] },
    profile: { type: Object, default: null },
    isRevisionHistoryViewMode: { type: Boolean, default: false },
    isEditOnBehalfMode: { type: Boolean, default: false },
    isMultipleProfiles: { type: Boolean, default: true },
  },
  data() {
    return {
      additionalDataGrossListEnum: additionalDataGrossListEnum,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      loggedInProfile: types.GET_CURRENT_PROFILE,
    }),
  },
  methods: {
    isAbleToEdit(canCurrentUserRespond) {
      return !this.isDisabled && canCurrentUserRespond;
    },
    showQuestionYesNoMultipleProfiles(additionalDataId, profile, event = null) {
      let value = null;
      if (event != null && event.target != undefined) {
        value = event.target.value;
        profile.additionals[additionalDataId].yesNoResponse = value;
      } else {
        value = profile.additionals[additionalDataId].yesNoResponse;
      }
      const allCheckTheSame =
        this.profiles.filter(
          p =>
            p.institutionProfile.id != profile.institutionProfile.id &&
            p.additionals[additionalDataId] != undefined &&
            p.additionals[additionalDataId].yesNoResponse != null &&
            p.additionals[additionalDataId].yesNoResponse != value
        ).length == 0;
      if (this.question.response != null) {
        // eslint-disable-next-line vue/no-mutating-props
        this.question.responding = this.question.response;
      }
      if (value == 'yes') {
        profile.additionals[additionalDataId].showOptionQuestionNo = false;
        if (profile.additionals[additionalDataId].optionalQuestionForYes != null) {
          // eslint-disable-next-line vue/no-mutating-props
          this.question.showOptionQuestionYes = true;
          profile.additionals[additionalDataId].showOptionQuestionYes = true;
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          this.question.showOptionQuestionYes = false;
        }
        if (allCheckTheSame) {
          // eslint-disable-next-line vue/no-mutating-props
          this.question.showOptionQuestionNo = false;
          profile.additionals[additionalDataId].showOptionQuestionNo = false;
        }
      } else {
        profile.additionals[additionalDataId].showOptionQuestionYes = false;
        if (profile.additionals[additionalDataId].optionalQuestionForNo != null) {
          // eslint-disable-next-line vue/no-mutating-props
          this.question.showOptionQuestionNo = true;
          profile.additionals[additionalDataId].showOptionQuestionNo = true;
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          this.question.showOptionQuestionNo = false;
        }
        if (allCheckTheSame) {
          // eslint-disable-next-line vue/no-mutating-props
          this.question.showOptionQuestionYes = false;
          profile.additionals[additionalDataId].showOptionQuestionYes = false;
        }
      }
      profile.additionals[additionalDataId].responding = profile.additionals[additionalDataId].response;
    },
    checkRequiredResponseForChild(question, profile) {
      // Response for child is required for guardian
      return (
        (profile.additionals[question.additionalDataId] &&
          !profile.additionals[question.additionalDataId].canCurrentProfileRespondForChild) ||
        this.loggedInProfile.role !== portalRoles.EMPLOYEE ||
        this.$route.name === 'stepOnboarding'
      );
    },
    showYesNoQuestionWarning̣(question, profile) {
      const isRequiredResponseForChild = this.checkRequiredResponseForChild(question, profile);

      return (
        this.isSubmitted &&
        question.answerType == additionalDataGrossListEnum.YESNO &&
        typeof profile.additionals[question.additionalDataId] !== 'undefined' &&
        profile.additionals[question.additionalDataId] &&
        profile.additionals[question.additionalDataId].canCurrentProfileRespond &&
        isRequiredResponseForChild &&
        !profile.additionals[question.additionalDataId].yesNoResponse
      );
    },
    showTextQuestionWarning̣(question, profile) {
      const isRequiredResponseForChild = this.checkRequiredResponseForChild(question, profile);

      return (
        this.isSubmitted &&
        typeof profile.additionals[question.additionalDataId] !== 'undefined' &&
        profile.additionals[question.additionalDataId] &&
        profile.additionals[question.additionalDataId].canCurrentProfileRespond &&
        isRequiredResponseForChild &&
        !profile.additionals[question.additionalDataId].response
      );
    },
    showPhoneQuestionWarning(question, profile) {
      const isRequiredResponseForChild = this.checkRequiredResponseForChild(question, profile);

      return (
        this.isSubmitted &&
        typeof profile.additionals[question.additionalDataId] !== 'undefined' &&
        profile.additionals[question.additionalDataId] &&
        profile.additionals[question.additionalDataId].canCurrentProfileRespond &&
        ((!profile.additionals[question.additionalDataId].response && isRequiredResponseForChild) ||
          !Vue.filter('isPhoneNumber')(profile.additionals[question.additionalDataId].response))
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/core/variables';
@import '../../assets/scss/core/breakpoints';
@import '../../assets/scss/elements/consentAdditional';
</style>
