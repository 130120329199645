<template>
  <b-table-simple class="table">
    <slot />
  </b-table-simple>
</template>
<script lang="js">
import {tableProvider} from "../providers/tableProvider";

export default {
  name: 'TableContainer',
  provide() {
    return {
      [tableProvider.IS_HOVERABLE]: () => this.hoverable,
      [tableProvider.VERTICAL_ALIGN]: () => this.verticalAlign,
    }
  },
  props: {
    hoverable: {
      required: false,
      default: () => false,
      type: Boolean,
    },
    verticalAlign: {
      required: false,
      default: () => false,
      type: Boolean,
    },
  },
};
</script>
<style lang="scss" scoped>
.table {
  height: 1px;
}
</style>
