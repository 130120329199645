<template>
  <aula-modal
    ref="sessionTimeOutWarning"
    :show-cancel="false"
    :disable-close="true"
    ok-text="BUTTON_YES"
    @okClicked="
      keepAlive();
      $refs.sessionTimeOutWarning.hide();
    "
  >
    {{ 'MESSAGE_SESSION_TIMEOUT_WARNING_2' | fromTextKey }} {{ countDownTime }}
    {{ 'MESSAGE_SESSION_TIMEOUT_WARNING_3' | fromTextKey }}<br />
    {{ 'MESSAGE_SESSION_TIMEOUT_WARNING_1' | fromTextKey }}
    {{ 'MESSAGE_SESSION_TIMEOUT_WARNING_1_CONTINUE' | fromTextKey }}
  </aula-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../src/store/types/types';
import { sessionTimeOut } from '../enums/sessionTimeout';
import moment from 'moment-timezone';

export default {
  data: function () {
    return {
      countDownTime: moment().format('mm:ss'),
      lastActivity: null,
      refreshInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      getKeepAlive: types.GET_KEEP_ALIVE,
    }),
  },
  methods: {
    ...mapActions({
      keepAlive: types.ACTION_KEEPALIVE,
      disableImpersonationMode: types.DISABLE_IMPERSONATION_MODE,
    }),
    startSessionTimeout() {
      this.lastActivity = moment();
      let counter = 0;
      this.refreshInterval = setInterval(() => {
        if (moment().diff(this.lastActivity, 'miliseconds') >= sessionTimeOut.logoutTime) {
          this.disableImpersonationMode();
          window.location.href = '/auth/logout.php';
        } else if (moment().diff(this.lastActivity, 'miliseconds') >= sessionTimeOut.warningTime) {
          counter++;
          this.countDownTime = moment(sessionTimeOut.countDownTime).subtract(counter, 'seconds').format('mm:ss');
          if (this.$refs.sessionTimeOutWarning != null) {
            this.$refs.sessionTimeOutWarning.show();
          }
        }
      }, 1000);
    },
  },
  watch: {
    getKeepAlive() {
      clearInterval(this.refreshInterval);
      this.startSessionTimeout();
    },
  },
  mounted() {
    this.startSessionTimeout();
  },
};
</script>
