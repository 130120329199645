<template>
  <ul>
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'AulaList',
};
</script>

<style scoped></style>
