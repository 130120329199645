export const providerKeyEnum = Object.freeze({
  institutionProfile: Symbol(),
  institution: Symbol(),
  selectedInstitution: Symbol(),
  group: Symbol(),
  hasLockPermission: Symbol(),
  selectedUserAssignedRoles: Symbol(),
  groupsWithAssignedRoles: Symbol(),
  canCurrentUserAccessGroups: Symbol(),
  assignRolesToUser: Symbol(),
  groupsWithRoleDefinitions: Symbol(),
  numberOfGroups: Symbol(),
  setNoticeBoardCalendarScope: Symbol(),
  noticeBoardCalendarScope: Symbol(),
  onDescriptionLinkClicked: Symbol(),
  isMunicipalAdministrator: Symbol(),
  isCentralAdministrator: Symbol(),
});
