
















import AulaButton from '../../../shared/components/AulaButton.vue';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import { consentAnswerType } from '../../../shared/enums/consentAnswerType.js';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { types } from '../../store/types/types.js';
import { PropType } from 'vue';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';

export default {
  components: { Icon, AulaButton },
  props: {
    profile: { type: Object as PropType<InstitutionProfileModel>, required: true },
  },
  data() {
    return {
      isExpanded: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      profileConsents: types.GET_PROFILE_CONSENTS,
    }),
    icon() {
      if (this.isExpanded) {
        return iconClassEnum.MINUS;
      }
      return iconClassEnum.PLUS;
    },
    hasConsentResponses() {
      return this.profileConsents.some(consent => consent.consentResponses.length > 0);
    },
  },
  methods: {
    ...mapMutations({
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
      resetConsents: types.MUTATE_RESET_PROFILE_CONSENT,
    }),
    ...mapActions({
      getConsentResponse: types.ACTION_GET_CONSENTS_RESPONSE_BY_INSTITUTION_PROFILEID,
    }),
    toggle() {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded) {
        this.expand();
      }
    },
    expand() {
      if (!this.isSteppedUp) {
        this.redirectToStepUp();
        return;
      }
      this.resetConsents();
      this.loadConsents();
    },
    redirectToStepUp() {
      this.setStepUpNotification({
        showStepUpNotification: true,
        redirectedUrl: window.location.href,
      });
    },
    async loadConsents() {
      this.isLoading = true;
      await this.getConsentResponse({ id: this.profile.id });
      this.isLoading = false;
    },
    getConsentAnswerText(answer) {
      switch (answer) {
        case consentAnswerType.YEAR_AND_SFO:
          return this.$options.filters.fromTextKey('CONSENT_ANSWER_YEAR_AND_SFO');
        case consentAnswerType.INSTITUTION:
          return this.$options.filters.fromTextKey('CONSENT_ANSWER_INSTITUTION');
        case consentAnswerType.CLASS_OR_STUE:
          return this.$options.filters.fromTextKey('CONSENT_ANSWER_CLASS_OR_STUE');
        case consentAnswerType.ACCEPTED:
          return this.$options.filters.fromTextKey('CONSENT_ANSWER_ACCEPTED');
        case consentAnswerType.DECLINED:
          return this.$options.filters.fromTextKey('CONSENT_ANSWER_DECLINED');
        case consentAnswerType.NOT_AT_ALL:
          return this.$options.filters.fromTextKey('CONSENT_ANSWER_NOT_AT_ALL');
        default:
          return this.$options.filters.fromTextKey('CONSENT_ANSWER_NOT_ANSWERED');
      }
    },
  },
};
