<template>
  <div>
    <aula-spinner v-if="isLoading" />
    <template v-else>
      <p>{{ 'DESCRIPTION_ROLES_AND_RIGHTS' | fromTextKey }}</p>
      <div class="d-flex justify-content-between align-items-center">
        <a href="#" class="description-modal-link" @click.prevent="onDescriptionsClicked">
          <u>{{ 'USER_RIGHT_OVERVIEW_OPEN' | fromTextKey }}</u>
        </a>
        <RoleAndRightAssignments />
      </div>
      <UserDetailsAdministrationIndividualUserRights class="mt-2 mb-4" />
      <UserDetailsAdministrationGroupMembershipRights class="mt-2 mb-4" />
    </template>
  </div>
</template>
<script>
import UserDetailsAdministrationIndividualUserRights from './UserDetailsAdministrationIndividualUserRights';
import UserDetailsAdministrationGroupMembershipRights from './UserDetailsAdministrationGroupMembershipRights';
import RoleAndRightAssignments from '../RoleAndRightAssignments';
import { mapGetters, mapActions } from 'vuex';
import { types } from '../../../src_adm/store/types/types';
import { providerKeyEnum } from '../../enums/providerKeyEnum';
import { institutionTypes } from '../../enums/institutionTypes';
import { scopeType } from '../../enums/scopeType';
import { roleUtil } from '../../utils/roleUtil';

export default {
  name: 'UserDetailsAdministrationTabsRolesAndRights',
  components: {
    RoleAndRightAssignments,
    UserDetailsAdministrationGroupMembershipRights,
    UserDetailsAdministrationIndividualUserRights,
  },
  inject: {
    getInstitutionProfile: providerKeyEnum.institutionProfile,
    onDescriptionLinkClicked: providerKeyEnum.onDescriptionLinkClicked,
  },
  provide() {
    return {
      [providerKeyEnum.selectedInstitution]: () => this.selectedInstitution,
      [providerKeyEnum.roleDefinitions]: () => this.roleDefinitions,
      [providerKeyEnum.selectedUserAssignedRoles]: () => this.selectedUserAssignedRoles,
      [providerKeyEnum.groupsWithAssignedRoles]: () => this.groupsWithAssignedRoles,
      [providerKeyEnum.canCurrentUserAccessGroups]: () => this.canCurrentUserAccessGroups,
      [providerKeyEnum.assignRolesToUser]: ({ roleIdsToAssign, roleIdsToUnAssign }) =>
        this.assignRolesToUser({
          roleIdsToAssign: roleUtil.getRoleIdsToAssign({
            existingRoles: this.selectedUserAssignedRoles,
            roleIdsToAssign,
            roleIdsToUnAssign,
          }),
          institutionCode: this.institutionProfile.institutionCode,
          institutionProfileId: this.institutionProfile.id,
        }),
    };
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      roleDefinitions: types.GET_ROLE_DEFINITIONS,
      selectedInstitution: types.GET_SELECTED_INSTITUTION,
      selectedUserAssignedRoles: types.GET_SELECTED_USER_EXPLICIT_ROLES,
      groupsWithAssignedRoles: types.GET_SELECTED_USER_IMPLICIT_ROLES,
      canCurrentUserAccessGroups: types.GET_CAN_SELECTED_USER_ACCESS_GROUPS,
    }),
    institutionProfile() {
      return this.getInstitutionProfile();
    },
    scopeType() {
      if (this.institutionProfile.institutionType === institutionTypes.MUNICIPALITY) {
        return scopeType.MUNICIPALITY;
      }
      if (this.institutionProfile.institutionType === institutionTypes.CENTRAL) {
        return scopeType.CENTRAL;
      }
      if (
        this.institutionProfile.institutionType === institutionTypes.SCHOOL ||
        this.institutionProfile.institutionType === institutionTypes.DAYCARE
      ) {
        return scopeType.INSTITUTION;
      }
      return null;
    },
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    ...mapActions({
      getSelectedUserAssignedRoles: types.ACTION_GET_SELECTED_USER_ASSIGNED_ROLES,
      getSelectedUserRoleDefinitions: types.ACTION_GET_SELECTED_USER_ROLE_DEFINITIONS,
      assignRolesToUser: types.ASSIGN_ROLES_TO_SELECTED_USER,
    }),
    initializeData() {
      const requests = [];
      this.isLoading = true;
      requests.push(
        this.getSelectedUserAssignedRoles({
          institutionProfileId: this.institutionProfile.id,
        })
      );
      requests.push(
        this.getSelectedUserRoleDefinitions({
          portalRole: this.institutionProfile.role,
          scopeType: this.scopeType,
        })
      );
      Promise.all(requests).then(() => {
        this.isLoading = false;
      });
    },
    onDescriptionsClicked() {
      this.onDescriptionLinkClicked();
    },
  },
};
</script>
<style></style>
