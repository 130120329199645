import { render, staticRenderFns } from "./UserDetailsAdditionalDataResponseRevision.vue?vue&type=template&id=479d3351&scoped=true&"
import script from "./UserDetailsAdditionalDataResponseRevision.vue?vue&type=script&lang=ts&"
export * from "./UserDetailsAdditionalDataResponseRevision.vue?vue&type=script&lang=ts&"
import style0 from "./UserDetailsAdditionalDataResponseRevision.vue?vue&type=style&index=0&id=479d3351&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479d3351",
  null
  
)

export default component.exports