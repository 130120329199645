<template>
  <div>
    <b-table-simple>
      <caption>
        {{
          'INDIVIDUAL_USER_RIGHTS' | fromTextKey
        }}
      </caption>
      <b-thead>
        <b-tr>
          <b-th class="text-capitalize" colspan="2">{{ 'USER_RIGHT' | fromTextKey }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-if="selectedUserAssignedRoles.length === 0">
          <b-td colspan="2" class="text-center cursor-default">{{ 'NO_ASSIGNED_ROLES' | fromTextKey }}</b-td>
        </b-tr>
        <b-tr v-for="role in selectedUserAssignedRoles" :key="role.id">
          <b-td class="cursor-default">
            {{ role.name }}
            <UserDetailsAdministrationUserRightHelpBox :role="role" />
          </b-td>
          <b-td class="action-container cursor-default">
            <b-btn
              class="py-0 px-1"
              :aria-label="'ARIA_LABEL_DELETE' | fromTextKey"
              size="sm"
              variant="text"
              @click="onRemoveRoleClicked(role.id)"
            >
              <i class="icon-Aula_bin m-0" />
            </b-btn>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <aula-modal
      ref="modalDeleteRolesAndRights"
      ok-text="YES"
      @cancelClicked="$refs.modalDeleteRolesAndRights.hide()"
      @okClicked="onDeleteRoleClicked"
    >
      {{ 'DELETE_ROLE_WARNING' | fromTextKey }}<br />
      {{ 'DELETE_ROLE_CONFIRMATION' | fromTextKey }}
    </aula-modal>
  </div>
</template>
<script lang="js">
import {providerKeyEnum} from "../../enums/providerKeyEnum";
import UserDetailsAdministrationUserRightHelpBox from "./UserDetailsAdministrationUserRightHelpBox";

export default {
  name: 'UserDetailsAdministrationIndividualUserRights',
  components: {
    UserDetailsAdministrationUserRightHelpBox
  },
  inject: {
    getSelectedUserAssignedRoles: providerKeyEnum.selectedUserAssignedRoles,
    assignRolesToUser: providerKeyEnum.assignRolesToUser,
  },
  data() {
    return {
      selectedRoleId: null,
    }
  },
  computed: {
    selectedUserAssignedRoles() {
      return this.getSelectedUserAssignedRoles();
    },
  },
  methods: {
    onRemoveRoleClicked(roleId) {
      this.selectedRoleId = roleId;
      this.$refs.modalDeleteRolesAndRights.show();
    },
    onDeleteRoleClicked() {
      this.deleteRole(this.selectedRoleId);
      this.$refs.modalDeleteRolesAndRights.hide();
    },
    deleteRole(roleId) {
      const roleIdsToAssign = this.selectedUserAssignedRoles
        .filter(assignedRole => assignedRole.id !== roleId)
        .map(assignedRole => assignedRole.id);
      const requestPayload = {
        roleIdsToAssign,
        roleIdsToUnAssign: [roleId],
      };

      this.assignRolesToUser(requestPayload);
    },
  },
}
</script>
<style lang="scss" scoped>
.action-container {
  width: 200px;
  text-align: center;
}

.b-table {
  --table-btn-link-decoration: none;
  --questionmark-icon-background-color: var(--color-grey-base);
  --questionmark-icon-color: var(--color-primary-darker);
  --table-icon-font-size: 1rem;
}
</style>
