<template>
  <aula-modal
    ref="debugApiError"
    header-text="API_DEBUG_ERROR_MODAL"
    :show-cancel="false"
    @okClicked="hideDebugApiError"
    @cancelClicked="hideDebugApiError"
    @closeClicked="hideDebugApiError"
  >
    <template v-if="debugApiError">
      <div class="api-information">
        <b-row>
          <b-col cols="2"><strong>URL: </strong></b-col>
          <b-col class="api-url"
            ><code>{{ debugApiError.api }}</code></b-col
          >
        </b-row>
        <b-row>
          <b-col cols="2"><strong>Message: </strong></b-col>
          <b-col
            ><strong>{{ debugApiError.message }}</strong></b-col
          >
        </b-row>
        <b-row>
          <b-col cols="2"><strong>Type: </strong></b-col>
          <b-col>{{ debugApiError.type }}</b-col>
        </b-row>
        <b-row>
          <b-col cols="2"><strong>File: </strong></b-col>
          <b-col>{{ debugApiError.file }}:{{ debugApiError.line }}</b-col>
        </b-row>
      </div>

      <h5 class="mt-3">Trace</h5>
      <div class="error-trace">
        <div v-for="(traceLine, index) in debugApiError.trace" :key="index">
          <b-row>
            <b-col cols="2"><strong>File: </strong></b-col>
            <b-col>{{ traceLine.file }}:{{ traceLine.line }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="2"><strong>Class: </strong></b-col>
            <b-col>{{ traceLine.class }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="2"><strong>Function: </strong></b-col>
            <b-col>{{ traceLine.function }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="2"><strong>Args: </strong></b-col>
            <b-col>{{ traceLine.args }}</b-col>
          </b-row>
          <hr />
        </div>
      </div>
    </template>
  </aula-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { types } from '../../src/store/types/types';

export default {
  name: 'DebugApiErrorModal',
  computed: {
    ...mapGetters({
      debugApiError: types.GET_DEBUG_API_ERROR,
    }),
  },
  watch: {
    debugApiError() {
      if (this.debugApiError != null && this.$refs.debugApiError !== undefined) {
        this.$refs.debugApiError.show();
      }
    },
  },
  methods: {
    ...mapMutations({
      setDebugApiError: types.MUTATE_DEBUG_API_ERROR,
    }),
    hideDebugApiError() {
      this.setDebugApiError(null);
      this.$refs.debugApiError.hide();
    },
  },
};
</script>

<style scoped lang="scss">
.api-information {
  background-color: var(--color-grey-light);
  padding: 20px;
  border-radius: 15px;
  .api-url code {
    font-size: 1rem;
    font-weight: bold;
  }
}
.error-trace {
  padding: 5px 20px;
}
</style>
