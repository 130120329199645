<template>
  <b-tr :class="classes">
    <slot />
  </b-tr>
</template>
<script lang="js">
import {collapseDirectionEnum} from "../enums/collapseDirectionEnum";
import {tableProvider} from "../providers/tableProvider";

export default {
  name: 'TableRow',
  provide() {
    return {
      [tableProvider.COLLAPSE_DIRECTIONS]: () => this.collapseDirections,
    }
  },
  inject: {
    getIsHoverable: tableProvider.IS_HOVERABLE,
  },
  props: {
    collapseDirections: {
      required: false,
      default: () => [],
      type: Array,
      validator(directions) {
        let isValid = true;
        const validValues = Array.from(Object.values(collapseDirectionEnum));
        for (const direction of directions) {
          if (!validValues.includes(direction)) {
            isValid = false;
            break;
          }
        }
        return isValid;
      }
    }
  },
  computed: {
    isHoverable() {
      return this.getIsHoverable();
    },
    classes() {
      const classes = ['table-row'];
      if (!this.isHoverable) {
        classes.push('not-hoverable');
      }
      return classes;
    },
  }
};
</script>
<style lang="scss" scoped>
.table-row {
  &.not-hoverable:hover {
    background: var(--color-base, var(--color-white));
  }
}
</style>
