<template>
  <help-box :id="userRightButtonUniqueId" :title="title" :body="description">
    <template v-if="administratorDescriptions" #body>
      <div v-for="(rightDescription, key) in administratorDescriptions" :key="key" class="my-2">
        <b>{{ rightDescription.userRightTextKey | fromTextKey }}:</b>
        {{ rightDescription.rightDescriptionTextKey | fromTextKey }}
      </div>
    </template>
  </help-box>
</template>

<script>
import { roleEnum } from '../../enums/roleEnum';
import {
  institutionsAdministratorDescriptions,
  municipalAdministratorRightDescriptions,
  otherRightsDescriptions,
} from '../../enums/RolesAndRightsDescriptionEnum';
import uniqueId from 'lodash/uniqueId';

export default {
  name: 'UserDetailsAdministrationUserRightHelpBox',
  props: {
    role: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    userRightButtonUniqueId() {
      return `user-right-${uniqueId()}`;
    },
    isInstitutionalAdministrator() {
      return this.role.id === roleEnum.INSTITUTIONAL_ADMINISTRATOR.toString();
    },
    isMunicipalAdministrator() {
      return this.role.id === roleEnum.MUNICIPAL_ADMINISTRATOR.toString();
    },
    administratorDescriptions() {
      if (this.isInstitutionalAdministrator) {
        return institutionsAdministratorDescriptions;
      }
      if (this.isMunicipalAdministrator) {
        return municipalAdministratorRightDescriptions;
      }
      return [];
    },
    roleDescription() {
      return this.roleAndRightDescriptions.find(
        description => description.roleDefinitionEnum.toString() === this.role.id
      );
    },
    title() {
      if (this.isInstitutionalAdministrator || this.isMunicipalAdministrator) {
        return this.role.name;
      }
      return this.$options.filters.fromTextKey(this.roleDescription?.userRightTextKey) || '';
    },
    description() {
      return this.$options.filters.fromTextKey(this.roleDescription?.rightDescriptionTextKey) || '';
    },
    roleAndRightDescriptions() {
      return [
        ...municipalAdministratorRightDescriptions,
        ...institutionsAdministratorDescriptions,
        ...otherRightsDescriptions,
      ];
    },
  },
};
</script>
<style>
.tooltip-body {
  --helpbox-body-overflow: auto;
  --helpbox-max-height-overflow: 300px;
}
</style>
