import debounce from 'lodash/debounce';

class PerformanceUtil {
  debounce(func, timeout = 25) {
    return debounce(func, timeout);
  }
  asyncDebounce(func, timeout = 25) {
    const debounced = debounce(async (resolve, reject, bind, args) => {
      try {
        const result = await func.bind(bind)(...args);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    }, timeout);

    return function (...args) {
      return new Promise((resolve, reject) => {
        debounced(resolve, reject, this, args);
      });
    };
  }
}

export const performanceUtil = new PerformanceUtil();
