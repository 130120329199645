<template>
  <b-tbody>
    <slot />
  </b-tbody>
</template>
<script lang="js">
export default {
  name: 'TableBody',
};
</script>
