




























































import { PropType } from 'vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import Avatar from '../../../shared/components/Avatar.vue';
import AulaButtons from '../../../shared/components/AulaButtons.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';
import IconContainer from '../../../shared/components/IconContainer.vue';
import Icon from '../../../shared/components/Icon.vue';
import ContactInformationContactForm from './ContactInformationContactForm.vue';
import * as dateUtil from '../../../shared/utils/dateUtil.js';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import { InstitutionProfileMasterDataForm } from '../../business/institutionProfileMasterDataForm';
import ContactInformationProfileActions from './ContactInformationProfileActions.vue';
import { portalRoles } from '../../../shared/enums/portalRoles';

export default {
  components: {
    ContactInformationProfileActions,
    ContactInformationContactForm,
    Icon,
    IconContainer,
    AulaButton,
    AulaButtons,
    Avatar,
  },
  props: {
    profile: { type: Object as PropType<InstitutionProfileModel>, required: true },
    isEditing: { type: Boolean, default: false },
    showShortcuts: { type: Boolean, default: false },
  },
  emits: ['input', 'deleteAvatar', 'editAvatar'],
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    addressStreet() {
      if (!this.profile.address || this.profile.address.postalDistrict == null) {
        return this.$options.filters.fromTextKey('PROFILE_ADDRESS_UNKOWN');
      }
      return this.profile.address.street;
    },
    relation() {
      return this.profile.role != portalRoles.CHILD;
    },
    birthday() {
      if (!this.profile.birthday) {
        return '';
      }
      return dateUtil.format(this.profile.birthday, 'D[.] MMM[.]');
    },
    lastActivity() {
      if (!this.profile.lastActivity) {
        return '';
      }
      return dateUtil.format(this.profile.lastActivity, 'D[.] MMM[.] YYYY');
    },
    hasCustodyText() {
      if (this.profile.hasCustody) {
        return this.$options.filters.fromTextKey('YES');
      }
      return this.$options.filters.fromTextKey('NO');
    },
    canSeeCustody() {
      return typeof this.profile.hasCustody === 'boolean';
    },
    canDeleteAvatar() {
      return this.profile.currentUserCanDeleteProfilePicture;
    },
    canEditAvatar() {
      return this.profile.currentUserCanEditProfilePicture;
    },
    canViewDescription() {
      return this.profile.currentUserCanSeeProfileDescription;
    },
  },
  methods: {
    emitInput(form: InstitutionProfileMasterDataForm) {
      this.$emit('input', form);
    },
    emitDeleteAvatar() {
      this.$emit('deleteAvatar', this.profile);
    },
    emitEditAvatar() {
      this.$emit('editAvatar', this.profile);
    },
  },
};
