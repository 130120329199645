<template>
  <footer v-if="!hide">
    <hr v-if="showLine" />
    <div class="hide-tablet-down">
      <b-button v-if="showDelete" variant="light" class="mr-4" @click="$emit('deleteClicked')">
        <i v-if="showDeleteIcon" class="icon-Aula_bin" />
        {{ deleteText | fromTextKey }}
      </b-button>
      <b-button v-if="showCancel" variant="link" class="mr-4" @click="$emit('cancelClicked')">
        {{ cancelText | fromTextKey }}
      </b-button>
      <div v-if="showSubmit" class="submit-container">
        <b-button
          data-test="aulaFormFooterSubmit"
          variant="primary"
          class="send-btn mr-0"
          :disabled="isLoading || submitDisabled"
          @click="$emit('submitClicked')"
        >
          <slot name="submit">
            {{ submitText | fromTextKey }}
          </slot>
        </b-button>
        <aula-spinner v-if="isLoading" class="aula-spinner" />
      </div>
    </div>
    <div class="hide-tablet-up">
      <template v-if="buttonShapeMobile === 'square'">
        <b-button v-if="showDeleteMobile" variant="light" class="mr-4" @click.stop="$emit('deleteClicked')">
          <i v-if="showDeleteIcon" class="icon-Aula_bin" />
          {{ deleteText | fromTextKey }}
        </b-button>
        <b-button v-if="showCancelMobile" variant="link" class="mr-4" @click.stop="mobileCancelClicked">
          {{ cancelText | fromTextKey }}
        </b-button>
        <div v-if="showSubmit" class="submit-container">
          <b-button
            data-test="aulaFormFooterSubmit"
            variant="primary"
            class="send-btn mr-0"
            :disabled="isLoading || submitDisabled"
            @keydown.enter="mobileSubmitClicked"
            @click="mobileSubmitClicked"
          >
            <slot name="submit">
              {{ submitText | fromTextKey }}
            </slot>
          </b-button>
          <aula-spinner v-if="isLoading" class="aula-spinner" />
        </div>
      </template>
      <template v-else>
        <div v-if="showCancelMobile && showCancel" class="doubleBtn first">
          <div class="tool-link large">
            <div class="aula-circle-large" @click.stop="mobileCancelClicked">
              <div class="aula-circle-small">
                <a href="#" class="aula-circle">{{ cancelText | fromTextKey }}</a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showDeleteMobile && showDelete" class="doubleBtn first">
          <div class="tool-link large">
            <div class="aula-circle-large" @click.stop="$emit('deleteClicked')">
              <div class="aula-circle-small">
                <a href="#" class="aula-circle">
                  <i v-if="showDeleteIcon" class="icon-Aula_bin" />
                  {{ deleteText | fromTextKey }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="showSubmit" :class="{ doubleBtn: showCancelMobile && showCancel }">
          <div class="d-flex justify-content-center mb-2">
            <div class="submit-container">
              <aula-spinner v-if="isLoading" class="aula-spinner mobile" />
              <div
                tabindex="0"
                class="aula-circle-large send-btn"
                :class="{ disabled: isLoading || submitDisabled }"
                @keydown.enter="mobileSubmitClicked"
                @click="mobileSubmitClicked"
              >
                <div class="aula-circle-small">
                  <div class="aula-circle">
                    <slot name="submit">
                      {{ submitText | fromTextKey }}
                    </slot>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </footer>
</template>
<script>
import $ from 'jquery';

export default {
  props: {
    showLine: { type: Boolean, default: true },
    hide: { type: Boolean, default: false },
    cancelText: { type: String, default: 'CANCEL' },
    submitText: { type: String, default: 'OK' },
    deleteText: { type: String, default: 'DELETE' },
    isLoading: { type: Boolean, default: true },
    showDelete: { type: Boolean, default: false },
    showDeleteIcon: { type: Boolean, default: false },
    showDeleteMobile: { type: Boolean, default: false },
    showSubmit: { type: Boolean, default: true },
    showCancel: { type: Boolean, default: true },
    showCancelMobile: { type: Boolean, default: false },
    submitDisabled: { type: Boolean, default: false },
    buttonShapeMobile: {
      type: String,
      default: 'circle',
      validator: value => ['circle', 'square'].includes(value.toLowerCase()),
    },
  },
  mounted() {
    $('.send-btn').click(function () {
      setTimeout(function () {
        if (document.getElementsByClassName('alert-danger').length) {
          document.getElementsByClassName('alert-danger')[0].scrollIntoView(true);
        }
      }, 500);
    });
  },
  methods: {
    mobileSubmitClicked() {
      if (!this.isLoading && !this.submitDisabled) {
        this.$emit('submitClicked');
      }
    },
    mobileCancelClicked() {
      this.$emit('cancelClicked');
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';

footer {
  position: relative;
  text-align: right;
  margin-top: 40px;
  padding-bottom: 40px;
  @include breakpoint-lg-down() {
    margin-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 80px;
  }
  button {
    margin-right: 15px;
  }
  .submit-container {
    position: relative;
    display: inline-block;
  }
  .aula-spinner {
    width: 80px;
    height: 80px;
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    &.mobile {
      left: -7px;
      top: -8px;
    }
  }
  .aula-circle {
    display: inline-block;
    margin: auto;
    padding-top: 18px;
  }
  &.footer-new-message-mobile {
    .aula-circle {
      color: $color-primary-darker;
      padding-top: 18px;
    }
    .aula-circle-large {
      border-radius: 4px;
      height: 50px;
      width: 110px;
      background-color: unset;

      &.send-btn {
        .aula-circle {
          color: $color-white;
        }
      }
    }
    .aula-circle-small {
      border-radius: inherit;
      height: 100%;
      width: 100%;
      left: unset;
      top: unset;
    }
  }
  .aula-circle-large.disabled {
    opacity: 0.65;
  }
  .doubleBtn {
    width: 50%;
    float: left;
    .tool-link {
      margin-left: 0;
      margin-right: calc(100% - 90px);
    }
    &.first {
      .tool-link {
        margin-left: calc(100% - 90px);
        margin-right: 0;
        .aula-circle-large {
          background-color: transparent;
          box-shadow: 0 0 0 transparent;
          color: $color-primary-dark-employee;
        }
        .aula-circle-small {
          background-color: transparent;
          box-shadow: 0 0 0 transparent;
        }
      }
    }
    .aula-spinner {
      &.mobile {
        right: unset;
        margin-left: unset;
        margin-right: unset;
      }
    }
  }
}

.footer-new-message-mobile {
  .doubleBtn {
    float: unset;
    position: absolute;
    right: 15px;
    width: unset;
    &.first {
      right: 130px;
    }
  }
}
</style>
