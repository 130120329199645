import { performanceUtil } from '../../utils/performanceUtil';

export default {
  /**
   *
   * @param {HTMLElement} element
   */
  bind(element) {
    let clickCount = 0;
    const debounceResetCount = performanceUtil.debounce(() => {
      clickCount = 0;
    }, 250);
    element.addEventListener(
      'click',
      event => {
        if (clickCount > 0) {
          event.stopPropagation();
          event.preventDefault();
          return;
        }
        debounceResetCount();
        clickCount++;
      },
      {
        capture: true,
      }
    );
  },
};
