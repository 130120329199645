
















import AulaButton from '../AulaButton.vue';
import Icon from '../Icon.vue';
import { iconClassEnum } from '../../enums/iconClassEnum';
import { profileAdminService } from '../../services/api/profileAdmin.service';
import { PropType } from 'vue';
import { AdditionalDataResponseRevisionHistoryQuestionModel } from '../../models/additionalDataResponseRevisionHistoryQuestion.model';
import { providerKeyEnum } from '../../enums/providerKeyEnum.js';
import UserDetailsAdditionalDataResponseRevisionTable from './UserDetailsAdditionalDataResponseRevisionTable.vue';

export default {
  components: {
    UserDetailsAdditionalDataResponseRevisionTable,
    Icon,
    AulaButton,
  },
  inject: {
    getInstitutionProfile: providerKeyEnum.institutionProfile,
  },
  data() {
    return {
      questions: [] as PropType<AdditionalDataResponseRevisionHistoryQuestionModel[]>,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    institutionProfile() {
      return this.getInstitutionProfile();
    },
  },
  mounted() {
    this.getAdditionalDataResponseRevisionHistory();
  },
  methods: {
    async getAdditionalDataResponseRevisionHistory() {
      const { questions } = await profileAdminService.getAdditionalDataResponseRevisionHistory(
        this.institutionProfile.id
      );
      this.questions = questions;
    },
  },
};
