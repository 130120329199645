<template>
  <div class="consent-content">
    <template v-if="multipleColumns">
      <template v-for="(consent, i) in $parent.consents">
        <template v-if="consent.editable">
          <!-- Complex -->
          <template v-if="consent.allowedAnswers && consent.allowedAnswers.includes(consentAnswerType.NOT_AT_ALL)">
            <div :key="'mainQuestion' + i" class="questions complex-question">
              <div class="question">
                <template v-if="consent.consentId != consentNumber.NUMBER_36">
                  {{ consent.consentDescription }}
                </template>
                <template v-else>
                  {{ consent.consentDescription }}
                  <br />
                  {{ 'CONSENT_TIME_SPAN' | fromTextKey }}:
                  <template v-if="consent.fromDate !== null">
                    {{ 'CONSENT_FROM' | fromTextKey }}
                    {{ moment(consent.fromDate).format('DD-MM-YYYY') }}
                  </template>
                  <template v-if="consent.toDate !== null">
                    {{ 'CONSENT_TO' | fromTextKey }}
                    {{ moment(consent.toDate).format('DD-MM-YYYY') }}
                  </template>
                </template>
                <help-box
                  v-if="Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != null"
                  :id="getConsentHelpBoxUniqueId()"
                  is-html-content
                  title=""
                  :body="Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId)"
                />
              </div>
              <div v-for="(profile, i) in $parent.profiles" :key="i" class="answer" />
            </div>
            <div
              v-if="
                $parent.profiles.filter(
                  p =>
                    p.consents[consent.viewOrder] != null &&
                    p.consents[consent.viewOrder].allowedAnswers.includes(consentAnswerType.CLASS_OR_STUE)
                ).length > 0
              "
              :key="'subQuestion1-' + i"
              class="questions complex-question"
            >
              <div class="question sub">
                {{ 'SHARING_CONSENT_CLASS_OR_DAYCARE_ROOM' | fromTextKey }}
              </div>
              <template v-for="(profile, profileIndex) in $parent.profiles">
                <template
                  v-if="
                    profile.consents[consent.viewOrder] != undefined &&
                    profile.consents[consent.viewOrder].editable &&
                    profile.consents[consent.viewOrder].allowedAnswers.includes(consentAnswerType.CLASS_OR_STUE)
                  "
                >
                  <div
                    v-if="!isDisabled"
                    :key="'subAnswer' + profileIndex"
                    class="answer anwser-consent-complex"
                    :class="{
                      mobile: isMobile,
                      'red-border': isSubmitted && profile.consents[consent.viewOrder].answerSharing1 == null,
                    }"
                  >
                    <b-form-radio-group
                      v-model="$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing1"
                      class="complex-answer"
                      @change.native="$parent.responseComplexConsent($event, consent.viewOrder, profileIndex, 1)"
                    >
                      <b-form-radio :value="true">
                        <span aria-hidden="true">{{ 'YES' | fromTextKey }}</span>
                        <span class="sr-only">{{
                          'ARIA_LABEL_CHECK_YES'
                            | fromTextKey(
                              getReplaceText(
                                consent.consentDescription,
                                'SHARING_CONSENT_CLASS_OR_DAYCARE_ROOM',
                                profile.institutionProfile
                              )
                            )
                        }}</span>
                      </b-form-radio>
                      <b-form-radio :value="false">
                        <span aria-hidden="true">{{ 'NO' | fromTextKey }}</span>
                        <span class="sr-only">{{
                          'ARIA_LABEL_CHECK_NO'
                            | fromTextKey(
                              getReplaceText(
                                consent.consentDescription,
                                'SHARING_CONSENT_CLASS_OR_DAYCARE_ROOM',
                                profile.institutionProfile
                              )
                            )
                        }}</span>
                      </b-form-radio>
                    </b-form-radio-group>
                  </div>
                  <div v-else :key="'mainAnswer' + profileIndex" class="answer anwser-consent-complex">
                    <div class="answer-text complex-answer">
                      {{
                        (
                          'CONSENT_ANSWER_' + $parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing1
                        ).toUpperCase() | fromTextKey
                      }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div :key="profileIndex" class="answer" />
                </template>
              </template>
            </div>
            <div
              v-if="
                $parent.profiles.filter(
                  p =>
                    p.consents[consent.viewOrder] != null &&
                    p.consents[consent.viewOrder].allowedAnswers.includes(consentAnswerType.YEAR_AND_SFO)
                ).length > 0
              "
              :key="'subQuestion2-' + i"
              class="questions complex-question"
            >
              <div class="question sub">
                {{ 'SHARING_CONSENT_YEAR_AND_SFO' | fromTextKey }}
              </div>
              <template v-for="(profile, profileIndex) in $parent.profiles">
                <template
                  v-if="
                    profile.consents[consent.viewOrder] != undefined &&
                    profile.consents[consent.viewOrder].editable &&
                    profile.consents[consent.viewOrder].allowedAnswers.includes(consentAnswerType.YEAR_AND_SFO)
                  "
                >
                  <div
                    v-if="!isDisabled"
                    :key="'subAnswer' + profileIndex"
                    class="answer anwser-consent-complex"
                    :class="{
                      mobile: isMobile,
                      'red-border': isSubmitted && profile.consents[consent.viewOrder].answerSharing2 == null,
                    }"
                  >
                    <b-form-radio-group
                      v-model="$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing2"
                      class="complex-answer"
                      @change.native="$parent.responseComplexConsent($event, consent.viewOrder, profileIndex, 2)"
                    >
                      <b-form-radio :value="true">
                        <span aria-hidden="true">{{ 'YES' | fromTextKey }}</span>
                        <span class="sr-only">{{
                          'ARIA_LABEL_CHECK_YES'
                            | fromTextKey(
                              getReplaceText(
                                consent.consentDescription,
                                'SHARING_CONSENT_YEAR_AND_SFO',
                                profile.institutionProfile
                              )
                            )
                        }}</span>
                      </b-form-radio>
                      <b-form-radio :value="false">
                        <span aria-hidden="true">{{ 'NO' | fromTextKey }}</span>
                        <span class="sr-only">{{
                          'ARIA_LABEL_CHECK_NO'
                            | fromTextKey(
                              getReplaceText(
                                consent.consentDescription,
                                'SHARING_CONSENT_YEAR_AND_SFO',
                                profile.institutionProfile
                              )
                            )
                        }}</span>
                      </b-form-radio>
                    </b-form-radio-group>
                  </div>
                  <div v-else :key="'mainAnswer' + profileIndex" class="answer anwser-consent-complex">
                    <div class="answer-text complex-answer">
                      {{
                        (
                          'CONSENT_ANSWER_' + $parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing2
                        ).toUpperCase() | fromTextKey
                      }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div :key="profileIndex" class="answer anwser-consent-complex" />
                </template>
              </template>
            </div>
            <div
              v-if="
                $parent.profiles.filter(
                  p =>
                    p.consents[consent.viewOrder] != null &&
                    p.consents[consent.viewOrder].allowedAnswers.includes(consentAnswerType.INSTITUTION)
                ).length > 0
              "
              :key="'subQuestion3-' + i"
              class="questions"
            >
              <div class="question sub">
                {{ 'SHARING_CONSENT_INSTITUTION' | fromTextKey }}
              </div>
              <template v-for="(profile, profileIndex) in $parent.profiles">
                <template
                  v-if="
                    profile.consents[consent.viewOrder] != undefined &&
                    profile.consents[consent.viewOrder].editable &&
                    profile.consents[consent.viewOrder].allowedAnswers.includes(consentAnswerType.INSTITUTION)
                  "
                >
                  <div
                    v-if="!isDisabled"
                    :key="'subAnswer3' + profileIndex"
                    class="answer anwser-consent-complex"
                    :class="{
                      mobile: isMobile,
                      'red-border': isSubmitted && profile.consents[consent.viewOrder].answerSharing3 == null,
                    }"
                  >
                    <b-form-radio-group
                      v-model="$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing3"
                      class="complex-answer"
                      @change.native="$parent.responseComplexConsent($event, consent.viewOrder, profileIndex, 3)"
                    >
                      <b-form-radio :value="true">
                        <span aria-hidden="true">{{ 'YES' | fromTextKey }}</span>
                        <span class="sr-only">{{
                          'ARIA_LABEL_CHECK_YES'
                            | fromTextKey(
                              getReplaceText(
                                consent.consentDescription,
                                'SHARING_CONSENT_INSTITUTION',
                                profile.institutionProfile
                              )
                            )
                        }}</span>
                      </b-form-radio>
                      <b-form-radio :value="false">
                        <span aria-hidden="true">{{ 'NO' | fromTextKey }}</span>
                        <span class="sr-only">{{
                          'ARIA_LABEL_CHECK_NO'
                            | fromTextKey(
                              getReplaceText(
                                consent.consentDescription,
                                'SHARING_CONSENT_INSTITUTION',
                                profile.institutionProfile
                              )
                            )
                        }}</span>
                      </b-form-radio>
                    </b-form-radio-group>
                  </div>
                  <div v-else :key="'mainAnswer3' + profileIndex" class="answer anwser-consent-complex">
                    <div class="answer-text complex-answer">
                      {{
                        (
                          'CONSENT_ANSWER_' + $parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing3
                        ).toUpperCase() | fromTextKey
                      }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div :key="profileIndex" class="answer anwser-consent-complex" />
                </template>
              </template>
            </div>
          </template>
          <!-- Complex -->
          <div v-else :key="'subQuestion4-' + i" class="questions">
            <div class="question">
              <template v-if="consent.consentId != consentNumber.NUMBER_36">
                {{ consent.consentDescription }}
              </template>
              <template v-else>
                {{ consent.consentDescription }}
                <i
                  ><strong>{{ ('COMPLEX_CONSENT_' + consent.allowedAnswers[0].toUpperCase()) | fromTextKey }} </strong
                  >:</i
                >
                <br />
                {{ 'CONSENT_TIME_SPAN' | fromTextKey }}:
                <template v-if="consent.fromDate !== null">
                  {{ 'CONSENT_FROM' | fromTextKey }}
                  {{ moment(consent.fromDate).format('DD-MM-YYYY') }}
                </template>
                <template v-if="consent.toDate !== null">
                  {{ 'CONSENT_TO' | fromTextKey }}
                  {{ moment(consent.toDate).format('DD-MM-YYYY') }}
                </template>
              </template>
              <help-box
                v-if="
                  Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != null &&
                  $options.filters.fromTextKey('CONSENT_HELPTEXT_' + consent.consentId) != ''
                "
                :id="getConsentHelpBoxUniqueId()"
                is-html-content
                title=""
                :body="Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId)"
              />
            </div>
            <template v-for="(profile, profileIndex) in $parent.profiles">
              <div
                :key="profileIndex"
                class="answer"
                :class="{
                  'anwser-consent-complex':
                    profile.consents[consent.viewOrder] != undefined &&
                    profile.consents[consent.viewOrder].allowedAnswers.length > 2,
                  'red-border':
                    isSubmitted &&
                    profile.consents[consent.viewOrder] != undefined &&
                    !profile.consents[consent.viewOrder].isValid,
                }"
              >
                <template
                  v-if="
                    profile.consents[consent.viewOrder] != undefined && profile.consents[consent.viewOrder].editable
                  "
                >
                  <template v-if="!isDisabled">
                    <b-form-radio-group
                      v-model="$parent.profiles[profileIndex].consents[consent.viewOrder].consentResponseAnswer"
                      :disabled="isDisabled"
                      required
                      @change.native="
                        $parent.profiles[profileIndex].consents[consent.viewOrder].consentId ==
                          consentNumber.NUMBER_2 && $parent.changeConsentNo2($event, profileIndex)
                      "
                    >
                      <b-form-radio
                        :value="$parent.profiles[profileIndex].consents[consent.viewOrder].allowedAnswers[0]"
                      >
                        <span aria-hidden="true">{{ 'YES' | fromTextKey }}</span>
                        <span class="sr-only">{{
                          'ARIA_LABEL_CHECK_YES'
                            | fromTextKey({
                              question: consent.consentDescription,
                              profileName: profile.institutionProfile.name,
                              institutionName: profile.institutionProfile.institutionName,
                            })
                        }}</span>
                      </b-form-radio>
                      <b-form-radio
                        :value="$parent.profiles[profileIndex].consents[consent.viewOrder].allowedAnswers[1]"
                      >
                        <span aria-hidden="true">{{ 'NO' | fromTextKey }}</span>
                        <span class="sr-only">{{
                          'ARIA_LABEL_CHECK_NO'
                            | fromTextKey({
                              question: consent.consentDescription,
                              profileName: profile.institutionProfile.name,
                              institutionName: profile.institutionProfile.institutionName,
                            })
                        }}</span>
                      </b-form-radio>
                    </b-form-radio-group>
                  </template>
                  <template v-else>
                    <div class="answer-text">
                      {{
                        (
                          'CONSENT_ANSWER_' +
                          ($parent.profiles[profileIndex].consents[consent.viewOrder].consentResponseAnswer ==
                            $parent.profiles[profileIndex].consents[consent.viewOrder].allowedAnswers[0])
                        ).toUpperCase() | fromTextKey
                      }}
                    </div>
                  </template>
                </template>
              </div>
            </template>
          </div>
        </template>
      </template>
    </template>
    <template v-else>
      <template v-for="(consent, consentIndex) in $parent.profiles[profileIndex].consents">
        <template v-if="consent.editable">
          <template v-if="consent.allowedAnswers && consent.allowedAnswers.includes(consentAnswerType.NOT_AT_ALL)">
            <div :key="'mainQuestion' + consentIndex" class="question-yesno complex-question">
              <div class="question">
                {{ consent.consentDescription }}
                <help-box
                  v-if="
                    Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != null &&
                    Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != ''
                  "
                  :id="getConsentHelpBoxUniqueId()"
                  is-html-content
                  title=""
                  :body="Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId)"
                />
              </div>
              <div class="answer anwser-consent-complex" :class="{ mobile: isMobile }" />
            </div>
            <div
              v-if="consent.allowedAnswers.includes(consentAnswerType.CLASS_OR_STUE)"
              :key="'subQuestion1' + consentIndex"
              class="question-yesno complex-question"
            >
              <div class="question sub">
                {{ 'SHARING_CONSENT_CLASS_OR_DAYCARE_ROOM' | fromTextKey }}
              </div>
              <div
                class="answer anwser-consent-complex"
                :class="{
                  mobile: isMobile,
                  'red-border':
                    isSubmitted &&
                    $parent.profiles[profileIndex].consents[consent.viewOrder] != undefined &&
                    !$parent.profiles[profileIndex].consents[consent.viewOrder].isValid,
                }"
              >
                <div class="complex-answers" :class="{ mobile: isMobile }">
                  <template v-if="!isDisabled">
                    <div class="complex-answer" :class="{ mobile: isMobile }">
                      <b-form-radio-group
                        v-model="$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing1"
                        :disabled="isDisabled"
                        required
                        @change.native="$parent.responseComplexConsent($event, consent.viewOrder, profileIndex, 1)"
                      >
                        <b-form-radio :value="true">
                          <span aria-hidden="true">{{ 'YES' | fromTextKey }}</span>
                          <span class="sr-only">{{
                            'ARIA_LABEL_CHECK_YES'
                              | fromTextKey({
                                question: consent.consentDescription,
                                profileName: $parent.profiles[profileIndex].institutionProfile.name,
                                institutionName: $parent.profiles[profileIndex].institutionProfile.institutionName,
                              })
                          }}</span>
                        </b-form-radio>
                        <b-form-radio
                          :value="false"
                          :aria-label="
                            'ARIA_LABEL_CHECK_NO'
                              | fromTextKey({
                                question: consent.consentDescription,
                                profileName: $parent.profiles[profileIndex].institutionProfile.name,
                                institutionName: $parent.profiles[profileIndex].institutionProfile.institutionName,
                              })
                          "
                        >
                          <span aria-hidden="true">{{ 'NO' | fromTextKey }}</span>
                          <span class="sr-only">{{
                            'ARIA_LABEL_CHECK_NO'
                              | fromTextKey({
                                question: consent.consentDescription,
                                profileName: $parent.profiles[profileIndex].institutionProfile.name,
                                institutionName: $parent.profiles[profileIndex].institutionProfile.institutionName,
                              })
                          }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                    </div>
                  </template>
                  <template v-else>
                    <div class="answer-text complex-answer">
                      {{
                        (
                          'CONSENT_ANSWER_' + $parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing1
                        ).toUpperCase() | fromTextKey
                      }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div
              v-if="consent.allowedAnswers.includes(consentAnswerType.YEAR_AND_SFO)"
              :key="'subQuestion2' + consentIndex"
              class="question-yesno complex-question"
            >
              <div class="question sub">
                {{ 'SHARING_CONSENT_YEAR_AND_SFO' | fromTextKey }}
              </div>
              <div
                class="answer anwser-consent-complex"
                :class="{
                  mobile: isMobile,
                  'red-border':
                    isSubmitted &&
                    $parent.profiles[profileIndex].consents[consent.viewOrder] != undefined &&
                    !$parent.profiles[profileIndex].consents[consent.viewOrder].isValid,
                }"
              >
                <div class="complex-answers" :class="{ mobile: isMobile }">
                  <template v-if="!isDisabled">
                    <div class="complex-answer" :class="{ mobile: isMobile }">
                      <b-form-radio-group
                        v-model="$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing2"
                        :disabled="isDisabled"
                        required
                        @change.native="$parent.responseComplexConsent($event, consent.viewOrder, profileIndex, 2)"
                      >
                        <b-form-radio :value="true">
                          <span aria-hidden="true">{{ 'YES' | fromTextKey }}</span>
                          <span class="sr-only">{{
                            'ARIA_LABEL_CHECK_YES'
                              | fromTextKey({
                                question: consent.consentDescription,
                                profileName: $parent.profiles[profileIndex].institutionProfile.name,
                                institutionName: $parent.profiles[profileIndex].institutionProfile.institutionName,
                              })
                          }}</span>
                        </b-form-radio>
                        <b-form-radio :value="false">
                          <span aria-hidden="true">{{ 'NO' | fromTextKey }}</span>
                          <span class="sr-only">{{
                            'ARIA_LABEL_CHECK_NO'
                              | fromTextKey({
                                question: consent.consentDescription,
                                profileName: $parent.profiles[profileIndex].institutionProfile.name,
                                institutionName: $parent.profiles[profileIndex].institutionProfile.institutionName,
                              })
                          }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                    </div>
                  </template>
                  <template v-else>
                    <div class="answer-text complex-answer">
                      {{
                        (
                          'CONSENT_ANSWER_' + $parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing2
                        ).toUpperCase() | fromTextKey
                      }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div
              v-if="consent.allowedAnswers.includes(consentAnswerType.INSTITUTION)"
              :key="'subQuestion3' + consentIndex"
              class="question-yesno"
            >
              <div class="question sub">
                {{ 'SHARING_CONSENT_INSTITUTION' | fromTextKey }}
              </div>
              <div
                class="answer anwser-consent-complex"
                :class="{
                  mobile: isMobile,
                  'red-border':
                    isSubmitted &&
                    $parent.profiles[profileIndex].consents[consent.viewOrder] != undefined &&
                    !$parent.profiles[profileIndex].consents[consent.viewOrder].isValid,
                }"
              >
                <div class="complex-answers" :class="{ mobile: isMobile }">
                  <template v-if="!isDisabled">
                    <div class="complex-answer" :class="{ mobile: isMobile }">
                      <b-form-radio-group
                        v-model="$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing3"
                        :disabled="isDisabled"
                        required
                        @change.native="$parent.responseComplexConsent($event, consent.viewOrder, profileIndex, 3)"
                      >
                        <b-form-radio :value="true">
                          <span aria-hidden="true">{{ 'YES' | fromTextKey }}</span>
                          <span class="sr-only">{{
                            'ARIA_LABEL_CHECK_YES'
                              | fromTextKey({
                                question: consent.consentDescription,
                                profileName: $parent.profiles[profileIndex].institutionProfile.name,
                                institutionName: $parent.profiles[profileIndex].institutionProfile.institutionName,
                              })
                          }}</span>
                        </b-form-radio>
                        <b-form-radio :value="false">
                          <span aria-hidden="true">{{ 'NO' | fromTextKey }}</span>
                          <span class="sr-only">{{
                            'ARIA_LABEL_CHECK_NO'
                              | fromTextKey({
                                question: consent.consentDescription,
                                profileName: $parent.profiles[profileIndex].institutionProfile.name,
                                institutionName: $parent.profiles[profileIndex].institutionProfile.institutionName,
                              })
                          }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                    </div>
                  </template>
                  <template v-else>
                    <div class="answer-text complex-answer">
                      {{
                        (
                          'CONSENT_ANSWER_' + $parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing3
                        ).toUpperCase() | fromTextKey
                      }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <hr v-if="isMobile" :key="'line' + consentIndex" />
          </template>
          <template
            v-else-if="consent.allowedAnswers && !consent.allowedAnswers.includes(consentAnswerType.NOT_AT_ALL)"
          >
            <template v-if="!$parent.consentForLeavingInstitution.includes(consent.consentId)">
              <div :key="consentIndex" class="question-yesno">
                <div class="question">
                  {{ consent.consentDescription }}
                  <help-box
                    v-if="
                      Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != null &&
                      Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != ''
                    "
                    :id="getConsentHelpBoxUniqueId()"
                    is-html-content
                    title=""
                    :body="Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId)"
                  />
                </div>
                <div
                  class="answer"
                  :class="{
                    mobile: isMobile,
                    'red-border': isSubmitted && !consent.consentResponseAnswer,
                  }"
                >
                  <template v-if="!isDisabled">
                    <b-form-radio-group v-model="consent.consentResponseAnswer" :disabled="isDisabled">
                      <b-form-radio :value="consent.allowedAnswers[0]">
                        <span aria-hidden="true">{{ 'YES' | fromTextKey }}</span>
                        <span class="sr-only">{{
                          'ARIA_LABEL_CHECK_YES'
                            | fromTextKey({
                              question: consent.consentDescription,
                              profileName: $parent.profiles[profileIndex].institutionProfile.name,
                              institutionName: $parent.profiles[profileIndex].institutionProfile.institutionName,
                            })
                        }}</span>
                      </b-form-radio>
                      <b-form-radio
                        :value="$parent.profiles[profileIndex].consents[consent.viewOrder].allowedAnswers[1]"
                      >
                        <span aria-hidden="true">{{ 'NO' | fromTextKey }}</span>
                        <span class="sr-only">{{
                          'ARIA_LABEL_CHECK_NO'
                            | fromTextKey({
                              question: consent.consentDescription,
                              profileName: $parent.profiles[profileIndex].institutionProfile.name,
                              institutionName: $parent.profiles[profileIndex].institutionProfile.institutionName,
                            })
                        }}</span>
                      </b-form-radio>
                    </b-form-radio-group>
                  </template>
                  <template v-else>
                    <div class="answer-text">
                      {{
                        ('CONSENT_ANSWER_' + (consent.consentResponseAnswer == consent.allowedAnswers[0])).toUpperCase()
                          | fromTextKey
                      }}
                    </div>
                  </template>
                </div>
              </div>
              <hr v-if="isMobile" :key="'line' + consentIndex" />
            </template>
            <template v-else>
              <div :key="consentIndex" class="question-yesno">
                <div v-if="consent.consentId == consentNumber.NUMBER_36" class="question">
                  {{ consent.consentDescription }}
                  <help-box
                    v-if="Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != null"
                    :id="getConsentHelpBoxUniqueId()"
                    is-html-content
                    title=""
                    :body="Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId)"
                  />
                  <i
                    ><strong>{{ ('COMPLEX_CONSENT_' + consent.allowedAnswers[0].toUpperCase()) | fromTextKey }}</strong
                    >:</i
                  >
                  <br />
                  {{ 'CONSENT_TIME_SPAN' | fromTextKey }}:
                  <template v-if="consent.fromDate !== null">
                    {{ 'CONSENT_FROM' | fromTextKey }}
                    {{ moment(consent.fromDate).format('DD-MM-YYYY') }}
                  </template>
                  <template v-if="consent.toDate !== null">
                    {{ 'CONSENT_TO' | fromTextKey }}
                    {{ moment(consent.toDate).format('DD-MM-YYYY') }}
                  </template>
                  <help-box
                    v-if="
                      Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != null &&
                      Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != ''
                    "
                    :id="getConsentHelpBoxUniqueId()"
                    is-html-content
                    title=""
                    :body="Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId)"
                  />
                </div>
                <div v-else class="question">
                  {{ consent.consentDescription }}
                  <help-box
                    v-if="Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != null"
                    :id="getConsentHelpBoxUniqueId()"
                    is-html-content
                    title=""
                    :body="Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId)"
                  />
                </div>
                <div
                  class="answer"
                  :class="{
                    mobile: isMobile,
                    'red-border': isSubmitted && !consent.consentResponseAnswer,
                  }"
                >
                  <template v-if="!isDisabled">
                    <b-form-radio-group
                      v-model="consent.consentResponseAnswer"
                      :disabled="isDisabled"
                      required
                      @change.native="
                        consent.consentId == consentNumber.NUMBER_2 && $parent.changeConsentNo2($event, profileIndex)
                      "
                    >
                      <b-form-radio :value="consent.allowedAnswers[0]">
                        <span aria-hidden="true">{{ 'YES' | fromTextKey }}</span>
                        <span class="sr-only">{{
                          'ARIA_LABEL_CHECK_YES'
                            | fromTextKey({
                              question: consent.consentDescription,
                              profileName: $parent.profiles[profileIndex].institutionProfile.name,
                              institutionName: $parent.profiles[profileIndex].institutionProfile.institutionName,
                            })
                        }}</span>
                      </b-form-radio>
                      <b-form-radio :value="consent.allowedAnswers[1]">
                        <span aria-hidden="true">{{ 'NO' | fromTextKey }}</span>
                        <span class="sr-only">{{
                          'ARIA_LABEL_CHECK_NO'
                            | fromTextKey({
                              question: consent.consentDescription,
                              profileName: $parent.profiles[profileIndex].institutionProfile.name,
                              institutionName: $parent.profiles[profileIndex].institutionProfile.institutionName,
                            })
                        }}</span>
                      </b-form-radio>
                    </b-form-radio-group>
                  </template>
                  <template v-else>
                    <div class="answer-text">
                      {{
                        ('CONSENT_ANSWER_' + (consent.consentResponseAnswer == consent.allowedAnswers[0])).toUpperCase()
                          | fromTextKey
                      }}
                    </div>
                  </template>
                </div>
              </div>
              <hr v-if="isMobile" :key="'line' + consentIndex" />
            </template>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { additionalDataGrossListEnum } from '../../enums/additionalDataGrossList';
import { consentNumber } from '../../enums/consentNumber';
import { consentAnswerType } from '../../enums/consentAnswerType';
import uniqueId from 'lodash/uniqueId';

export default {
  props: {
    multipleColumns: { type: Boolean, default: true },
    isMobile: { type: Boolean, default: false },
    profileIndex: { type: Number, default: 0 },
    isDisabled: { type: Boolean, default: false },
    isSubmitted: { type: Boolean, default: false },
  },
  data() {
    return {
      Vue: Vue,
      additionalDataGrossListEnum: additionalDataGrossListEnum,
      consentNumber: consentNumber,
      consentAnswerType: consentAnswerType,
    };
  },
  methods: {
    getConsentHelpBoxUniqueId() {
      return `consent-${uniqueId()}`;
    },
    getReplaceText(question, subquestion, institutionProfile) {
      const completeQuestion = question + ' ' + Vue.filter('fromTextKey')(subquestion);
      return {
        question: completeQuestion,
        profileName: institutionProfile.name || null,
        institutionName: institutionProfile.institutionName || null,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/core/variables';
@import '../../assets/scss/core/breakpoints';
@import '../../assets/scss/elements/consentAdditional';
</style>

<style scoped lang="scss">
.consent-content {
  .question {
    position: relative;
    .help-tooltip {
      position: relative;
    }
  }
  .question-yesno {
    position: relative;
  }
  /deep/ .help-tooltip {
    position: unset;
    .tooltip-inner {
      left: unset;
    }
  }
}
</style>
