import { collapseDirectionEnum } from '../enums/collapseDirectionEnum';

export const collapseDirectionProp = {
  required: false,
  type: Array,
  default: () => null,
  validator(directions) {
    let isValid = true;
    const validValues = Array.from(Object.values(collapseDirectionEnum));
    for (const direction of directions) {
      if (!validValues.includes(direction)) {
        isValid = false;
        break;
      }
    }
    return isValid;
  },
};
