<template>
  <b-thead>
    <slot />
  </b-thead>
</template>
<script lang="js">
export default {
  name: 'TableHead',
};
</script>
