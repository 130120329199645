<template>
  <div>
    <aula-spinner v-if="isLoading" />
    <b-table-simple v-else>
      <caption>
        {{
          'RIGHTS_IN_GROUPS' | fromTextKey
        }}
      </caption>
      <b-thead>
        <b-tr>
          <b-th class="text-capitalize">{{ 'GROUP' | fromTextKey }}</b-th>
          <b-th v-for="(field, key) in roleFields" :key="key" class="role-table-header text-capitalize">
            {{ field.label }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="group in groupsWithRoleDefinitions" :key="group.groupId" @click="onGroupClicked(group)">
          <b-td :class="{ 'cursor-default': !group.isAccessibleByCurrentUser }">{{ group.groupName }}</b-td>
          <b-td
            v-for="(field, key) in roleFields"
            :key="key"
            :class="{ 'cursor-default': !group.isAccessibleByCurrentUser }"
            class="text-center"
          >
            <i v-if="getHasRoleOnGroup(group.roleDefinitions, field)" class="icon-Aula_check" />
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-pagination
      class="justify-content-end"
      :value="1"
      :total-rows="numberOfGroups"
      :per-page="pageSize"
      aria-controls="my-table"
      @change="onPageChanged"
    ></b-pagination>
  </div>
</template>
<script>
import { providerKeyEnum } from '../../enums/providerKeyEnum';
import { roleEnum } from '../../enums/roleEnum';
import { UserDetailsPortalProvideKey } from './UsersDetailsPortalProvideKey';

export default {
  name: 'UserDetailsAdministrationGroupsWithRoles',
  inject: {
    getGroupsWithRoleDefinitions: providerKeyEnum.groupsWithRoleDefinitions,
    getNumberOfGroups: providerKeyEnum.numberOfGroups,
    getInstitutionProfile: providerKeyEnum.institutionProfile,
    onInstitutionGroupClick: UserDetailsPortalProvideKey.onInstitutionGroupClick,
  },
  props: {
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      pageSize: 20,
    };
  },
  computed: {
    institutionProfile() {
      return this.getInstitutionProfile();
    },
    groupsWithRoleDefinitions() {
      return this.getGroupsWithRoleDefinitions();
    },
    roleFields() {
      return [
        { id: roleEnum.WRITE_POST_GROUP.toString(), label: this.$options.filters.fromTextKey('WRITE_GROUP_POSTS') },
        { id: roleEnum.INVITE_GROUP_TO_EVENT.toString(), label: this.$options.filters.fromTextKey('CREATE_EVENTS') },
        { id: roleEnum.SHARE_MEDIA_WITH_GROUP.toString(), label: this.$options.filters.fromTextKey('SHARE_MEDIA') },
        { id: roleEnum.WRITE_MESSAGE_GROUP.toString(), label: this.$options.filters.fromTextKey('CREATE_MESSAGES') },
      ];
    },
    numberOfGroups() {
      return this.getNumberOfGroups();
    },
  },
  methods: {
    getHasRoleOnGroup(definitions, selectedDefinition) {
      const definitionIds = definitions.map(definition => definition.id);
      return definitionIds.includes(selectedDefinition.id);
    },
    onPageChanged(pageNumber) {
      this.$emit('onPageChanged', pageNumber);
    },
    onGroupClicked(group) {
      if (group.isAccessibleByCurrentUser) {
        this.onInstitutionGroupClick({ id: group.groupId, institutionCode: this.institutionProfile.institutionCode });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.role-table-header {
  width: 150px;
  white-space: unset;
  vertical-align: top;
}
i.icon-Aula_check {
  --table-icon-font-size: 2rem;

  color: var(--color-primary-dark);
}
</style>
