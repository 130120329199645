
























import { PropType } from 'vue';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { types } from '../../store/types/types.js';
import { UpdateProfilePictureRequest } from '../../../shared/models/requests/updateProfilePicture.request';
import { profileService } from '../../../shared/services/api/profile.service';
import AvatarUpload from './AvatarUpload.vue';

export default {
  components: { AvatarUpload },
  props: {
    profile: { type: Object as PropType<InstitutionProfileModel>, required: true },
  },
  emits: ['cancel', 'saved'],
  data() {
    return {
      isUploadingForAllProfiles: false,
      selectedMedia: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      loggedInProfile: types.GET_CURRENT_PROFILE,
      institutions: types.GET_INSTITUTIONS,
    }),
    canApplyForAllProfiles() {
      return this.loggedInProfile.profileId === this.profile.profileId && this.institutions.length > 1;
    },
    canViewInstitutionDescription() {
      return this.canApplyForAllProfiles && !this.isUploadingForAllProfiles;
    },
  },
  mounted() {
    this.isUploadingForAllProfiles = this.loggedInProfile.profileId === this.profile.profileId;
  },
  methods: {
    ...mapMutations({
      setSuccessText: types.MUTATE_SUCCESS_TEXT,
      setErrorText: types.MUTATE_ERROR_TEXT,
    }),
    ...mapActions({
      createAttachments: types.CREATE_ATTACHMENTS,
    }),
    setSelectedMedia(media) {
      this.selectedMedia = media;
    },
    async handleSubmitClicked() {
      this.isLoading = true;
      const preparedMedia = this.$options.filters.prepareAttachments([this.selectedMedia]);
      const uploader = this.institutions.find(inst => inst.institutionCode === this.profile.institutionCode);

      preparedMedia.media[0].tags = [this.profile.id];
      preparedMedia.ownerInstitutionProfileId = uploader?.institutionProfileId || null;
      preparedMedia.institutionCode = uploader?.institutionCode || null;

      try {
        const uploadedAttachment = await this.createAttachments(preparedMedia);
        const media = uploadedAttachment.media[0];
        await this.updateProfilePicture(media);
        this.setSuccessText('UPDATE_PROFILE_PICTURE_TOASTR');
        this.emitSaved();
      } catch {
        this.setErrorText('API_ERROR_GENERAL');
      } finally {
        this.isLoading = false;
      }
    },
    updateProfilePicture(media) {
      const payload: UpdateProfilePictureRequest = {
        institutionProfileId: this.profile.id,
        mediaId: media.id,
        applyToAllInstitutionProfiles: this.isUploadingForAllProfiles,
      };
      return profileService.updateProfilePicture(payload);
    },
    emitCancel() {
      this.$emit('cancel');
    },
    emitSaved() {
      this.$emit('saved');
    },
  },
};
