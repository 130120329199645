







































































import { PropType } from 'vue';
import { ProfileDetailedModel } from '../../../shared/models/profileDetailed.model';
import ContactInformationInstitutionProfile from './ContactInformationInstitutionProfile.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';
import AulaButtons from '../../../shared/components/AulaButtons.vue';
import { InstitutionProfileMasterDataForm } from '../../business/institutionProfileMasterDataForm';
import { mapGetters, mapMutations } from 'vuex';
import { types } from '../../store/types/types.js';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import { profileService } from '../../../shared/services/api/profile.service';
import { UpdateProfilePictureRequest } from '../../../shared/models/requests/updateProfilePicture.request';
import { UpdateProfileMasterDataRequest } from '../../../shared/models/requests/updateProfileMasterData.request';
import { portalRoles } from '../../../shared/enums/portalRoles';
import ContactInformationUploadAvatarForm from './ContactInformationUploadAvatarForm.vue';
import ContactInformationPrimaryInstitution from './ContactInformationPrimaryInstitution.vue';

export default {
  components: {
    ContactInformationPrimaryInstitution,
    ContactInformationUploadAvatarForm,
    AulaButtons,
    AulaButton,
    ContactInformationInstitutionProfile,
  },
  props: {
    profile: { type: Object as PropType<ProfileDetailedModel>, required: true },
    isLoading: { type: Boolean, default: false },
    canEdit: { type: Boolean, default: false },
    showRelations: { type: Boolean, default: false },
  },
  emits: ['saved'],
  data() {
    return {
      isEditing: false,
      contactFormMap: new Map<number, InstitutionProfileMasterDataForm>(),
      isUpdating: false,
      selectedProfile: null as InstitutionProfileModel,
      primaryInstitutionProfileId: null,
      hasDataChanged: false,
    };
  },
  computed: {
    ...mapGetters({
      loggedInProfile: types.GET_CURRENT_PROFILE,
      institutions: types.GET_INSTITUTIONS,
    }),
    canShowShortcuts() {
      if (this.loggedInProfile.role === portalRoles.OTP) {
        return false;
      }
      return this.profile.profileId !== this.loggedInProfile.profileId;
    },
    canChangePrimaryInstitution() {
      const isRoleAllowed = [portalRoles.EMPLOYEE, portalRoles.GUARDIAN].includes(this.loggedInProfile.role);
      return this.canEdit && isRoleAllowed && this.institutions.length > 1;
    },
  },
  mounted() {
    this.setPrimaryInstitutionProfileId();
  },
  methods: {
    ...mapMutations({
      setSuccessText: types.MUTATE_SUCCESS_TEXT,
      setErrorText: types.MUTATE_ERROR_TEXT,
    }),
    setPrimaryInstitutionProfileId() {
      this.primaryInstitutionProfileId =
        this.institutions.find(profile => profile.isPrimary)?.institutionProfileId || null;
    },
    setEditState(state: boolean) {
      this.isEditing = state;
    },
    handlePrimaryInstitutionChange() {
      this.hasDataChanged = true;
    },
    handleContactInput(contactForm: InstitutionProfileMasterDataForm) {
      this.hasDataChanged = true;
      this.contactFormMap.set(contactForm.institutionProfileId, contactForm);
    },
    handleCancelClicked() {
      if (this.hasDataChanged) {
        this.$refs.unsavedDataWarningModal.show();
      } else {
        this.cancelEditing();
      }
    },
    handleCancelConfirmed() {
      this.$refs.unsavedDataWarningModal.hide();
      this.cancelEditing();
    },
    cancelEditing() {
      this.setEditState(false);
      this.reset();
    },
    reset() {
      this.setPrimaryInstitutionProfileId();
      this.hasDataChanged = false;
    },
    async updateContacts() {
      const contactForms: InstitutionProfileMasterDataForm[] = Array.from(this.contactFormMap.values());
      const isValid = contactForms.every(form => form.isValid);

      if (!isValid) {
        return;
      }

      const primaryInstitutionProfileId = this.canChangePrimaryInstitution ? this.primaryInstitutionProfileId : null;
      const payload: UpdateProfileMasterDataRequest = {
        institutionProfilesMasterData: contactForms,
        primaryInstitutionProfileId,
      };

      this.isUpdating = true;
      try {
        await profileService.updateProfileMasterData(payload);
        this.setSuccessText('PROFILE_UPDATE_SUCCESS_TOASTR');
        this.$emit('saved');
      } catch {
        this.setErrorText('API_ERROR_UPDATE_PROFILE_MASTER_DATA');
      }
      this.isEditing = false;
      this.isUpdating = false;
    },
    handleEditAvatar(profile: InstitutionProfileModel) {
      this.selectedProfile = profile;
      this.$refs.uploadAvatarModal.show();
    },
    handleAvatarChanged() {
      this.$refs.uploadAvatarModal.hide();
      this.$emit('saved');
    },
    handleDeleteAvatarClicked(profile: InstitutionProfileModel) {
      this.selectedProfile = profile;
      this.$refs.avatarDeletedWarningModal.show();
    },
    async deleteSelectedAvatar() {
      const payload: UpdateProfilePictureRequest = {
        institutionProfileId: this.selectedProfile.id,
      };

      try {
        await profileService.updateProfilePicture(payload);
        this.setSuccessText('PROFILE_PICTURE_DELETED');
        this.$refs.avatarDeletedWarningModal.hide();
        this.$emit('saved');
      } catch {
        this.setErrorText('API_ERROR_GENERAL');
      }
    },
  },
};
