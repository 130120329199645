var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"consent-content"},[(_vm.multipleColumns)?[_vm._l((_vm.$parent.consents),function(consent,i){return [(consent.editable)?[(consent.allowedAnswers && consent.allowedAnswers.includes(_vm.consentAnswerType.NOT_AT_ALL))?[_c('div',{key:'mainQuestion' + i,staticClass:"questions complex-question"},[_c('div',{staticClass:"question"},[(consent.consentId != _vm.consentNumber.NUMBER_36)?[_vm._v("\n                "+_vm._s(consent.consentDescription)+"\n              ")]:[_vm._v("\n                "+_vm._s(consent.consentDescription)+"\n                "),_c('br'),_vm._v("\n                "+_vm._s(_vm._f("fromTextKey")('CONSENT_TIME_SPAN'))+":\n                "),(consent.fromDate !== null)?[_vm._v("\n                  "+_vm._s(_vm._f("fromTextKey")('CONSENT_FROM'))+"\n                  "+_vm._s(_vm.moment(consent.fromDate).format('DD-MM-YYYY'))+"\n                ")]:_vm._e(),_vm._v(" "),(consent.toDate !== null)?[_vm._v("\n                  "+_vm._s(_vm._f("fromTextKey")('CONSENT_TO'))+"\n                  "+_vm._s(_vm.moment(consent.toDate).format('DD-MM-YYYY'))+"\n                ")]:_vm._e()],_vm._v(" "),(_vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != null)?_c('help-box',{attrs:{"id":_vm.getConsentHelpBoxUniqueId(),"is-html-content":"","title":"","body":_vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId)}}):_vm._e()],2),_vm._v(" "),_vm._l((_vm.$parent.profiles),function(profile,i){return _c('div',{key:i,staticClass:"answer"})})],2),_vm._v(" "),(
              _vm.$parent.profiles.filter(
                function (p) { return p.consents[consent.viewOrder] != null &&
                  p.consents[consent.viewOrder].allowedAnswers.includes(_vm.consentAnswerType.CLASS_OR_STUE); }
              ).length > 0
            )?_c('div',{key:'subQuestion1-' + i,staticClass:"questions complex-question"},[_c('div',{staticClass:"question sub"},[_vm._v("\n              "+_vm._s(_vm._f("fromTextKey")('SHARING_CONSENT_CLASS_OR_DAYCARE_ROOM'))+"\n            ")]),_vm._v(" "),_vm._l((_vm.$parent.profiles),function(profile,profileIndex){return [(
                  profile.consents[consent.viewOrder] != undefined &&
                  profile.consents[consent.viewOrder].editable &&
                  profile.consents[consent.viewOrder].allowedAnswers.includes(_vm.consentAnswerType.CLASS_OR_STUE)
                )?[(!_vm.isDisabled)?_c('div',{key:'subAnswer' + profileIndex,staticClass:"answer anwser-consent-complex",class:{
                    mobile: _vm.isMobile,
                    'red-border': _vm.isSubmitted && profile.consents[consent.viewOrder].answerSharing1 == null,
                  }},[_c('b-form-radio-group',{staticClass:"complex-answer",nativeOn:{"change":function($event){return _vm.$parent.responseComplexConsent($event, consent.viewOrder, profileIndex, 1)}},model:{value:(_vm.$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing1),callback:function ($$v) {_vm.$set(_vm.$parent.profiles[profileIndex].consents[consent.viewOrder], "answerSharing1", $$v)},expression:"$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing1"}},[_c('b-form-radio',{attrs:{"value":true}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('YES')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_YES',
                            _vm.getReplaceText(
                              consent.consentDescription,
                              'SHARING_CONSENT_CLASS_OR_DAYCARE_ROOM',
                              profile.institutionProfile
                            )
                          )))])]),_vm._v(" "),_c('b-form-radio',{attrs:{"value":false}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('NO')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_NO',
                            _vm.getReplaceText(
                              consent.consentDescription,
                              'SHARING_CONSENT_CLASS_OR_DAYCARE_ROOM',
                              profile.institutionProfile
                            )
                          )))])])],1)],1):_c('div',{key:'mainAnswer' + profileIndex,staticClass:"answer anwser-consent-complex"},[_c('div',{staticClass:"answer-text complex-answer"},[_vm._v("\n                    "+_vm._s(_vm._f("fromTextKey")((
                        'CONSENT_ANSWER_' + _vm.$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing1
                      ).toUpperCase()))+"\n                  ")])])]:[_c('div',{key:profileIndex,staticClass:"answer"})]]})],2):_vm._e(),_vm._v(" "),(
              _vm.$parent.profiles.filter(
                function (p) { return p.consents[consent.viewOrder] != null &&
                  p.consents[consent.viewOrder].allowedAnswers.includes(_vm.consentAnswerType.YEAR_AND_SFO); }
              ).length > 0
            )?_c('div',{key:'subQuestion2-' + i,staticClass:"questions complex-question"},[_c('div',{staticClass:"question sub"},[_vm._v("\n              "+_vm._s(_vm._f("fromTextKey")('SHARING_CONSENT_YEAR_AND_SFO'))+"\n            ")]),_vm._v(" "),_vm._l((_vm.$parent.profiles),function(profile,profileIndex){return [(
                  profile.consents[consent.viewOrder] != undefined &&
                  profile.consents[consent.viewOrder].editable &&
                  profile.consents[consent.viewOrder].allowedAnswers.includes(_vm.consentAnswerType.YEAR_AND_SFO)
                )?[(!_vm.isDisabled)?_c('div',{key:'subAnswer' + profileIndex,staticClass:"answer anwser-consent-complex",class:{
                    mobile: _vm.isMobile,
                    'red-border': _vm.isSubmitted && profile.consents[consent.viewOrder].answerSharing2 == null,
                  }},[_c('b-form-radio-group',{staticClass:"complex-answer",nativeOn:{"change":function($event){return _vm.$parent.responseComplexConsent($event, consent.viewOrder, profileIndex, 2)}},model:{value:(_vm.$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing2),callback:function ($$v) {_vm.$set(_vm.$parent.profiles[profileIndex].consents[consent.viewOrder], "answerSharing2", $$v)},expression:"$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing2"}},[_c('b-form-radio',{attrs:{"value":true}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('YES')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_YES',
                            _vm.getReplaceText(
                              consent.consentDescription,
                              'SHARING_CONSENT_YEAR_AND_SFO',
                              profile.institutionProfile
                            )
                          )))])]),_vm._v(" "),_c('b-form-radio',{attrs:{"value":false}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('NO')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_NO',
                            _vm.getReplaceText(
                              consent.consentDescription,
                              'SHARING_CONSENT_YEAR_AND_SFO',
                              profile.institutionProfile
                            )
                          )))])])],1)],1):_c('div',{key:'mainAnswer' + profileIndex,staticClass:"answer anwser-consent-complex"},[_c('div',{staticClass:"answer-text complex-answer"},[_vm._v("\n                    "+_vm._s(_vm._f("fromTextKey")((
                        'CONSENT_ANSWER_' + _vm.$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing2
                      ).toUpperCase()))+"\n                  ")])])]:[_c('div',{key:profileIndex,staticClass:"answer anwser-consent-complex"})]]})],2):_vm._e(),_vm._v(" "),(
              _vm.$parent.profiles.filter(
                function (p) { return p.consents[consent.viewOrder] != null &&
                  p.consents[consent.viewOrder].allowedAnswers.includes(_vm.consentAnswerType.INSTITUTION); }
              ).length > 0
            )?_c('div',{key:'subQuestion3-' + i,staticClass:"questions"},[_c('div',{staticClass:"question sub"},[_vm._v("\n              "+_vm._s(_vm._f("fromTextKey")('SHARING_CONSENT_INSTITUTION'))+"\n            ")]),_vm._v(" "),_vm._l((_vm.$parent.profiles),function(profile,profileIndex){return [(
                  profile.consents[consent.viewOrder] != undefined &&
                  profile.consents[consent.viewOrder].editable &&
                  profile.consents[consent.viewOrder].allowedAnswers.includes(_vm.consentAnswerType.INSTITUTION)
                )?[(!_vm.isDisabled)?_c('div',{key:'subAnswer3' + profileIndex,staticClass:"answer anwser-consent-complex",class:{
                    mobile: _vm.isMobile,
                    'red-border': _vm.isSubmitted && profile.consents[consent.viewOrder].answerSharing3 == null,
                  }},[_c('b-form-radio-group',{staticClass:"complex-answer",nativeOn:{"change":function($event){return _vm.$parent.responseComplexConsent($event, consent.viewOrder, profileIndex, 3)}},model:{value:(_vm.$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing3),callback:function ($$v) {_vm.$set(_vm.$parent.profiles[profileIndex].consents[consent.viewOrder], "answerSharing3", $$v)},expression:"$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing3"}},[_c('b-form-radio',{attrs:{"value":true}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('YES')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_YES',
                            _vm.getReplaceText(
                              consent.consentDescription,
                              'SHARING_CONSENT_INSTITUTION',
                              profile.institutionProfile
                            )
                          )))])]),_vm._v(" "),_c('b-form-radio',{attrs:{"value":false}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('NO')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_NO',
                            _vm.getReplaceText(
                              consent.consentDescription,
                              'SHARING_CONSENT_INSTITUTION',
                              profile.institutionProfile
                            )
                          )))])])],1)],1):_c('div',{key:'mainAnswer3' + profileIndex,staticClass:"answer anwser-consent-complex"},[_c('div',{staticClass:"answer-text complex-answer"},[_vm._v("\n                    "+_vm._s(_vm._f("fromTextKey")((
                        'CONSENT_ANSWER_' + _vm.$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing3
                      ).toUpperCase()))+"\n                  ")])])]:[_c('div',{key:profileIndex,staticClass:"answer anwser-consent-complex"})]]})],2):_vm._e()]:_c('div',{key:'subQuestion4-' + i,staticClass:"questions"},[_c('div',{staticClass:"question"},[(consent.consentId != _vm.consentNumber.NUMBER_36)?[_vm._v("\n              "+_vm._s(consent.consentDescription)+"\n            ")]:[_vm._v("\n              "+_vm._s(consent.consentDescription)+"\n              "),_c('i',[_c('strong',[_vm._v(_vm._s(_vm._f("fromTextKey")(('COMPLEX_CONSENT_' + consent.allowedAnswers[0].toUpperCase())))+" ")]),_vm._v(":")]),_vm._v(" "),_c('br'),_vm._v("\n              "+_vm._s(_vm._f("fromTextKey")('CONSENT_TIME_SPAN'))+":\n              "),(consent.fromDate !== null)?[_vm._v("\n                "+_vm._s(_vm._f("fromTextKey")('CONSENT_FROM'))+"\n                "+_vm._s(_vm.moment(consent.fromDate).format('DD-MM-YYYY'))+"\n              ")]:_vm._e(),_vm._v(" "),(consent.toDate !== null)?[_vm._v("\n                "+_vm._s(_vm._f("fromTextKey")('CONSENT_TO'))+"\n                "+_vm._s(_vm.moment(consent.toDate).format('DD-MM-YYYY'))+"\n              ")]:_vm._e()],_vm._v(" "),(
                _vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != null &&
                _vm.$options.filters.fromTextKey('CONSENT_HELPTEXT_' + consent.consentId) != ''
              )?_c('help-box',{attrs:{"id":_vm.getConsentHelpBoxUniqueId(),"is-html-content":"","title":"","body":_vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId)}}):_vm._e()],2),_vm._v(" "),_vm._l((_vm.$parent.profiles),function(profile,profileIndex){return [_c('div',{key:profileIndex,staticClass:"answer",class:{
                'anwser-consent-complex':
                  profile.consents[consent.viewOrder] != undefined &&
                  profile.consents[consent.viewOrder].allowedAnswers.length > 2,
                'red-border':
                  _vm.isSubmitted &&
                  profile.consents[consent.viewOrder] != undefined &&
                  !profile.consents[consent.viewOrder].isValid,
              }},[(
                  profile.consents[consent.viewOrder] != undefined && profile.consents[consent.viewOrder].editable
                )?[(!_vm.isDisabled)?[_c('b-form-radio-group',{attrs:{"disabled":_vm.isDisabled,"required":""},nativeOn:{"change":function($event){_vm.$parent.profiles[profileIndex].consents[consent.viewOrder].consentId ==
                        _vm.consentNumber.NUMBER_2 && _vm.$parent.changeConsentNo2($event, profileIndex)}},model:{value:(_vm.$parent.profiles[profileIndex].consents[consent.viewOrder].consentResponseAnswer),callback:function ($$v) {_vm.$set(_vm.$parent.profiles[profileIndex].consents[consent.viewOrder], "consentResponseAnswer", $$v)},expression:"$parent.profiles[profileIndex].consents[consent.viewOrder].consentResponseAnswer"}},[_c('b-form-radio',{attrs:{"value":_vm.$parent.profiles[profileIndex].consents[consent.viewOrder].allowedAnswers[0]}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('YES')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_YES',{
                            question: consent.consentDescription,
                            profileName: profile.institutionProfile.name,
                            institutionName: profile.institutionProfile.institutionName,
                          })))])]),_vm._v(" "),_c('b-form-radio',{attrs:{"value":_vm.$parent.profiles[profileIndex].consents[consent.viewOrder].allowedAnswers[1]}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('NO')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_NO',{
                            question: consent.consentDescription,
                            profileName: profile.institutionProfile.name,
                            institutionName: profile.institutionProfile.institutionName,
                          })))])])],1)]:[_c('div',{staticClass:"answer-text"},[_vm._v("\n                    "+_vm._s(_vm._f("fromTextKey")((
                        'CONSENT_ANSWER_' +
                        (_vm.$parent.profiles[profileIndex].consents[consent.viewOrder].consentResponseAnswer ==
                          _vm.$parent.profiles[profileIndex].consents[consent.viewOrder].allowedAnswers[0])
                      ).toUpperCase()))+"\n                  ")])]]:_vm._e()],2)]})],2)]:_vm._e()]})]:[_vm._l((_vm.$parent.profiles[_vm.profileIndex].consents),function(consent,consentIndex){return [(consent.editable)?[(consent.allowedAnswers && consent.allowedAnswers.includes(_vm.consentAnswerType.NOT_AT_ALL))?[_c('div',{key:'mainQuestion' + consentIndex,staticClass:"question-yesno complex-question"},[_c('div',{staticClass:"question"},[_vm._v("\n              "+_vm._s(consent.consentDescription)+"\n              "),(
                  _vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != null &&
                  _vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != ''
                )?_c('help-box',{attrs:{"id":_vm.getConsentHelpBoxUniqueId(),"is-html-content":"","title":"","body":_vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId)}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"answer anwser-consent-complex",class:{ mobile: _vm.isMobile }})]),_vm._v(" "),(consent.allowedAnswers.includes(_vm.consentAnswerType.CLASS_OR_STUE))?_c('div',{key:'subQuestion1' + consentIndex,staticClass:"question-yesno complex-question"},[_c('div',{staticClass:"question sub"},[_vm._v("\n              "+_vm._s(_vm._f("fromTextKey")('SHARING_CONSENT_CLASS_OR_DAYCARE_ROOM'))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"answer anwser-consent-complex",class:{
                mobile: _vm.isMobile,
                'red-border':
                  _vm.isSubmitted &&
                  _vm.$parent.profiles[_vm.profileIndex].consents[consent.viewOrder] != undefined &&
                  !_vm.$parent.profiles[_vm.profileIndex].consents[consent.viewOrder].isValid,
              }},[_c('div',{staticClass:"complex-answers",class:{ mobile: _vm.isMobile }},[(!_vm.isDisabled)?[_c('div',{staticClass:"complex-answer",class:{ mobile: _vm.isMobile }},[_c('b-form-radio-group',{attrs:{"disabled":_vm.isDisabled,"required":""},nativeOn:{"change":function($event){return _vm.$parent.responseComplexConsent($event, consent.viewOrder, _vm.profileIndex, 1)}},model:{value:(_vm.$parent.profiles[_vm.profileIndex].consents[consent.viewOrder].answerSharing1),callback:function ($$v) {_vm.$set(_vm.$parent.profiles[_vm.profileIndex].consents[consent.viewOrder], "answerSharing1", $$v)},expression:"$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing1"}},[_c('b-form-radio',{attrs:{"value":true}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('YES')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_YES',{
                              question: consent.consentDescription,
                              profileName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.name,
                              institutionName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.institutionName,
                            })))])]),_vm._v(" "),_c('b-form-radio',{attrs:{"value":false,"aria-label":_vm._f("fromTextKey")('ARIA_LABEL_CHECK_NO',{
                              question: consent.consentDescription,
                              profileName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.name,
                              institutionName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.institutionName,
                            })}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('NO')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_NO',{
                              question: consent.consentDescription,
                              profileName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.name,
                              institutionName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.institutionName,
                            })))])])],1)],1)]:[_c('div',{staticClass:"answer-text complex-answer"},[_vm._v("\n                    "+_vm._s(_vm._f("fromTextKey")((
                        'CONSENT_ANSWER_' + _vm.$parent.profiles[_vm.profileIndex].consents[consent.viewOrder].answerSharing1
                      ).toUpperCase()))+"\n                  ")])]],2)])]):_vm._e(),_vm._v(" "),(consent.allowedAnswers.includes(_vm.consentAnswerType.YEAR_AND_SFO))?_c('div',{key:'subQuestion2' + consentIndex,staticClass:"question-yesno complex-question"},[_c('div',{staticClass:"question sub"},[_vm._v("\n              "+_vm._s(_vm._f("fromTextKey")('SHARING_CONSENT_YEAR_AND_SFO'))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"answer anwser-consent-complex",class:{
                mobile: _vm.isMobile,
                'red-border':
                  _vm.isSubmitted &&
                  _vm.$parent.profiles[_vm.profileIndex].consents[consent.viewOrder] != undefined &&
                  !_vm.$parent.profiles[_vm.profileIndex].consents[consent.viewOrder].isValid,
              }},[_c('div',{staticClass:"complex-answers",class:{ mobile: _vm.isMobile }},[(!_vm.isDisabled)?[_c('div',{staticClass:"complex-answer",class:{ mobile: _vm.isMobile }},[_c('b-form-radio-group',{attrs:{"disabled":_vm.isDisabled,"required":""},nativeOn:{"change":function($event){return _vm.$parent.responseComplexConsent($event, consent.viewOrder, _vm.profileIndex, 2)}},model:{value:(_vm.$parent.profiles[_vm.profileIndex].consents[consent.viewOrder].answerSharing2),callback:function ($$v) {_vm.$set(_vm.$parent.profiles[_vm.profileIndex].consents[consent.viewOrder], "answerSharing2", $$v)},expression:"$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing2"}},[_c('b-form-radio',{attrs:{"value":true}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('YES')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_YES',{
                              question: consent.consentDescription,
                              profileName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.name,
                              institutionName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.institutionName,
                            })))])]),_vm._v(" "),_c('b-form-radio',{attrs:{"value":false}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('NO')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_NO',{
                              question: consent.consentDescription,
                              profileName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.name,
                              institutionName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.institutionName,
                            })))])])],1)],1)]:[_c('div',{staticClass:"answer-text complex-answer"},[_vm._v("\n                    "+_vm._s(_vm._f("fromTextKey")((
                        'CONSENT_ANSWER_' + _vm.$parent.profiles[_vm.profileIndex].consents[consent.viewOrder].answerSharing2
                      ).toUpperCase()))+"\n                  ")])]],2)])]):_vm._e(),_vm._v(" "),(consent.allowedAnswers.includes(_vm.consentAnswerType.INSTITUTION))?_c('div',{key:'subQuestion3' + consentIndex,staticClass:"question-yesno"},[_c('div',{staticClass:"question sub"},[_vm._v("\n              "+_vm._s(_vm._f("fromTextKey")('SHARING_CONSENT_INSTITUTION'))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"answer anwser-consent-complex",class:{
                mobile: _vm.isMobile,
                'red-border':
                  _vm.isSubmitted &&
                  _vm.$parent.profiles[_vm.profileIndex].consents[consent.viewOrder] != undefined &&
                  !_vm.$parent.profiles[_vm.profileIndex].consents[consent.viewOrder].isValid,
              }},[_c('div',{staticClass:"complex-answers",class:{ mobile: _vm.isMobile }},[(!_vm.isDisabled)?[_c('div',{staticClass:"complex-answer",class:{ mobile: _vm.isMobile }},[_c('b-form-radio-group',{attrs:{"disabled":_vm.isDisabled,"required":""},nativeOn:{"change":function($event){return _vm.$parent.responseComplexConsent($event, consent.viewOrder, _vm.profileIndex, 3)}},model:{value:(_vm.$parent.profiles[_vm.profileIndex].consents[consent.viewOrder].answerSharing3),callback:function ($$v) {_vm.$set(_vm.$parent.profiles[_vm.profileIndex].consents[consent.viewOrder], "answerSharing3", $$v)},expression:"$parent.profiles[profileIndex].consents[consent.viewOrder].answerSharing3"}},[_c('b-form-radio',{attrs:{"value":true}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('YES')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_YES',{
                              question: consent.consentDescription,
                              profileName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.name,
                              institutionName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.institutionName,
                            })))])]),_vm._v(" "),_c('b-form-radio',{attrs:{"value":false}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('NO')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_NO',{
                              question: consent.consentDescription,
                              profileName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.name,
                              institutionName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.institutionName,
                            })))])])],1)],1)]:[_c('div',{staticClass:"answer-text complex-answer"},[_vm._v("\n                    "+_vm._s(_vm._f("fromTextKey")((
                        'CONSENT_ANSWER_' + _vm.$parent.profiles[_vm.profileIndex].consents[consent.viewOrder].answerSharing3
                      ).toUpperCase()))+"\n                  ")])]],2)])]):_vm._e(),_vm._v(" "),(_vm.isMobile)?_c('hr',{key:'line' + consentIndex}):_vm._e()]:(consent.allowedAnswers && !consent.allowedAnswers.includes(_vm.consentAnswerType.NOT_AT_ALL))?[(!_vm.$parent.consentForLeavingInstitution.includes(consent.consentId))?[_c('div',{key:consentIndex,staticClass:"question-yesno"},[_c('div',{staticClass:"question"},[_vm._v("\n                "+_vm._s(consent.consentDescription)+"\n                "),(
                    _vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != null &&
                    _vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != ''
                  )?_c('help-box',{attrs:{"id":_vm.getConsentHelpBoxUniqueId(),"is-html-content":"","title":"","body":_vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId)}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"answer",class:{
                  mobile: _vm.isMobile,
                  'red-border': _vm.isSubmitted && !consent.consentResponseAnswer,
                }},[(!_vm.isDisabled)?[_c('b-form-radio-group',{attrs:{"disabled":_vm.isDisabled},model:{value:(consent.consentResponseAnswer),callback:function ($$v) {_vm.$set(consent, "consentResponseAnswer", $$v)},expression:"consent.consentResponseAnswer"}},[_c('b-form-radio',{attrs:{"value":consent.allowedAnswers[0]}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('YES')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_YES',{
                            question: consent.consentDescription,
                            profileName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.name,
                            institutionName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.institutionName,
                          })))])]),_vm._v(" "),_c('b-form-radio',{attrs:{"value":_vm.$parent.profiles[_vm.profileIndex].consents[consent.viewOrder].allowedAnswers[1]}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('NO')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_NO',{
                            question: consent.consentDescription,
                            profileName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.name,
                            institutionName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.institutionName,
                          })))])])],1)]:[_c('div',{staticClass:"answer-text"},[_vm._v("\n                    "+_vm._s(_vm._f("fromTextKey")(('CONSENT_ANSWER_' + (consent.consentResponseAnswer == consent.allowedAnswers[0])).toUpperCase()))+"\n                  ")])]],2)]),_vm._v(" "),(_vm.isMobile)?_c('hr',{key:'line' + consentIndex}):_vm._e()]:[_c('div',{key:consentIndex,staticClass:"question-yesno"},[(consent.consentId == _vm.consentNumber.NUMBER_36)?_c('div',{staticClass:"question"},[_vm._v("\n                "+_vm._s(consent.consentDescription)+"\n                "),(_vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != null)?_c('help-box',{attrs:{"id":_vm.getConsentHelpBoxUniqueId(),"is-html-content":"","title":"","body":_vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId)}}):_vm._e(),_vm._v(" "),_c('i',[_c('strong',[_vm._v(_vm._s(_vm._f("fromTextKey")(('COMPLEX_CONSENT_' + consent.allowedAnswers[0].toUpperCase()))))]),_vm._v(":")]),_vm._v(" "),_c('br'),_vm._v("\n                "+_vm._s(_vm._f("fromTextKey")('CONSENT_TIME_SPAN'))+":\n                "),(consent.fromDate !== null)?[_vm._v("\n                  "+_vm._s(_vm._f("fromTextKey")('CONSENT_FROM'))+"\n                  "+_vm._s(_vm.moment(consent.fromDate).format('DD-MM-YYYY'))+"\n                ")]:_vm._e(),_vm._v(" "),(consent.toDate !== null)?[_vm._v("\n                  "+_vm._s(_vm._f("fromTextKey")('CONSENT_TO'))+"\n                  "+_vm._s(_vm.moment(consent.toDate).format('DD-MM-YYYY'))+"\n                ")]:_vm._e(),_vm._v(" "),(
                    _vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != null &&
                    _vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != ''
                  )?_c('help-box',{attrs:{"id":_vm.getConsentHelpBoxUniqueId(),"is-html-content":"","title":"","body":_vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId)}}):_vm._e()],2):_c('div',{staticClass:"question"},[_vm._v("\n                "+_vm._s(consent.consentDescription)+"\n                "),(_vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId) != null)?_c('help-box',{attrs:{"id":_vm.getConsentHelpBoxUniqueId(),"is-html-content":"","title":"","body":_vm.Vue.filter('fromTextKey')('CONSENT_HELPTEXT_' + consent.consentId)}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"answer",class:{
                  mobile: _vm.isMobile,
                  'red-border': _vm.isSubmitted && !consent.consentResponseAnswer,
                }},[(!_vm.isDisabled)?[_c('b-form-radio-group',{attrs:{"disabled":_vm.isDisabled,"required":""},nativeOn:{"change":function($event){consent.consentId == _vm.consentNumber.NUMBER_2 && _vm.$parent.changeConsentNo2($event, _vm.profileIndex)}},model:{value:(consent.consentResponseAnswer),callback:function ($$v) {_vm.$set(consent, "consentResponseAnswer", $$v)},expression:"consent.consentResponseAnswer"}},[_c('b-form-radio',{attrs:{"value":consent.allowedAnswers[0]}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('YES')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_YES',{
                            question: consent.consentDescription,
                            profileName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.name,
                            institutionName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.institutionName,
                          })))])]),_vm._v(" "),_c('b-form-radio',{attrs:{"value":consent.allowedAnswers[1]}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('NO')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_NO',{
                            question: consent.consentDescription,
                            profileName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.name,
                            institutionName: _vm.$parent.profiles[_vm.profileIndex].institutionProfile.institutionName,
                          })))])])],1)]:[_c('div',{staticClass:"answer-text"},[_vm._v("\n                    "+_vm._s(_vm._f("fromTextKey")(('CONSENT_ANSWER_' + (consent.consentResponseAnswer == consent.allowedAnswers[0])).toUpperCase()))+"\n                  ")])]],2)]),_vm._v(" "),(_vm.isMobile)?_c('hr',{key:'line' + consentIndex}):_vm._e()]]:_vm._e()]:_vm._e()]})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }