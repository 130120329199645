<template>
  <div class="aula-table" :class="stickyHeader ? 'sticky-header' : ''">
    <b-row v-if="search" class="search-container">
      <b-col cols="3">
        <b-input-group>
          <b-form-input v-model="filter" :placeholder="'TABLE_SEARCH_PLACEHOLDER' | fromTextKey" />
          <b-input-group-addon>
            <i
              class="icon-Aula_search"
              style="font-size: 1.5em"
              tabindex="0"
              role="button"
              :aria-label="'ARIA_LABEL_SEARCH' | fromTextKey"
              @click="search"
              @keydown.enter="search"
            />
          </b-input-group-addon>
        </b-input-group>
      </b-col>
    </b-row>
    <!-- Main table element -->
    <b-table
      v-show="!isMobile || (isMobile && parent === parentTypes.ADMINISTRATION)"
      :ref="tableRef"
      :hover="hover"
      :striped="striped"
      small
      show-empty
      :selectable="rowClickable && rowSelectable"
      :tbody-tr-class="rowClickable ? 'cursor-pointer' : ''"
      :responsive="responsive"
      :items="serverSide ? myProvider : filteredItems"
      :fields="listFields"
      :current-page="localCurrentPage"
      :per-page="pageSize"
      :no-local-sorting="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy.sync="isBusy"
      :empty-filtered-text="emptyTableFilteredText | fromTextKey"
      :empty-text="emptyTableText | fromTextKey"
      @row-clicked="rowClicked"
      @sort-changed="sortingChanged"
    >
      <template v-for="(field, key) in localFields" #[field.slotHead]="data">
        <slot :name="field.slotHead" v-bind="data">
          <template
            v-if="
              (field.filtering && field.sortable && !field.dateFiltering) ||
              (field.filtering && !field.sortable && !field.dateFiltering)
            "
          >
            <div :key="'head-' + key">
              <div class="nowrap filter-spacing">
                {{ data.label }}
              </div>
              <i
                v-if="field.uniqueItems.length > 0"
                class="icon-Aula_down-arrow custom-table-icon"
                :aria-label="'ARIA_LABEL_OPEN_FILTERING_DROPDOWN' | fromTextKey"
                tabindex="0"
                role="button"
                @click.stop="
                  hideFilterDropdown();
                  field.showFilterDropdown = !field.showFilterDropdown;
                "
                @keydown.esc.stop="field.showFilterDropdown = false"
                @keydown.enter.stop="
                  hideFilterDropdown();
                  field.showFilterDropdown = !field.showFilterDropdown;
                "
              />
              <div
                v-if="field.showFilterDropdown"
                :ref="key"
                v-click-outside="hideFilterDropdown"
                class="table-filtering"
                :class="'header-' + key"
                @keydown.esc.stop="hideFilterDropdown"
                @click.stop
              >
                <b-form-checkbox
                  v-if="field.selectedFilters.length > 0"
                  :disabled="isBusy"
                  @input="resetFilter(field, key)"
                  @click.native.stop
                >
                  {{ 'TABLE_DESELECT_ALL_FILTERS' | fromTextKey }}
                </b-form-checkbox>
                <b-form-checkbox-group v-model="field.selectedFilters" stacked>
                  <b-form-checkbox
                    v-for="(uniqueItem, i) in field.uniqueItems.filter(item => checkFilterItemEmpty(item))"
                    :key="i"
                    :disabled="isBusy"
                    :value="serverSide ? uniqueItem.key : uniqueItem"
                    @change="checked => filterTable(key, serverSide ? uniqueItem.key : key, checked)"
                    @click.native.stop
                  >
                    {{ getFilterLabel(uniqueItem, field.label) }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </div>
            </div>
          </template>
          <template
            v-if="
              (field.dateFiltering && field.sortable && !field.filtering) ||
              (field.dateFiltering && !field.sortable && !field.filtering)
            "
          >
            <div :key="'head-' + key">
              <i
                class="icon-Aula_down-arrow custom-table-icon"
                :aria-label="'ARIA_LABEL_OPEN_FILTERING_DROPDOWN' | fromTextKey"
                tabindex="0"
                role="button"
                @click.stop="
                  hideFilterDropdown();
                  field.showFilterDropdown = !field.showFilterDropdown;
                "
                @keydown.enter.stop="
                  hideFilterDropdown();
                  field.showFilterDropdown = !field.showFilterDropdown;
                "
              />
              <div
                v-if="field.showFilterDropdown"
                :ref="key"
                v-click-outside="hideFilterDropdown"
                class="table-filtering datepicker"
                :class="'header-' + key"
              >
                <b-row>
                  <b-col>{{ 'TABLE_DATE_FILTER_TITLE' | fromTextKey }}</b-col>
                  <b-col>
                    {{ 'TABLE_DATE_FILTER_START' | fromTextKey }}
                    <el-date-picker
                      id="startDate"
                      v-model="startDate"
                      :picker-options="availableStartDate"
                      format="dd-MM-yyyy"
                      value-format="yyyy-MM-dd"
                      type="date"
                      :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
                      :class="{ elPickerActive: isStartDateClicked }"
                      @focus="
                        datePickerModifier();
                        isStartDateClicked = true;
                      "
                      @blur="isStartDateClicked = false"
                      @change="filterDateTable(key)"
                    />
                  </b-col>
                  <b-col>
                    {{ 'TABLE_DATE_FILTER_END' | fromTextKey }}
                    <el-date-picker
                      id="endDate"
                      v-model="endDate"
                      :picker-options="availableEndDate"
                      format="dd-MM-yyyy"
                      value-format="yyyy-MM-dd"
                      type="date"
                      :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
                      :class="{ elPickerActive: isEndDateClicked }"
                      @focus="
                        datePickerModifier();
                        isEndDateClicked = true;
                      "
                      @blur="isEndDateClicked = false"
                      @change="filterDateTable(key)"
                    />
                  </b-col>
                </b-row>
              </div>
              <div class="nowrap">
                {{ data.label }}
              </div>
            </div>
          </template>
          <template
            v-if="
              (field.sortable && !field.filtering && !field.dateFiltering) ||
              (!field.sortable && !field.filtering && !field.dateFiltering)
            "
          >
            <span :key="'head-' + key">{{ data.label }}</span>
          </template>
          <template v-if="field.showSelectAllInHeader">
            <b-form-checkbox
              v-model="field.allSelected"
              class="ml-2"
              inline
              @change="$emit('toggleAllCheckboxes', $event)"
              @click.native.stop
            />
          </template>
        </slot>
      </template>

      <template v-for="(field, key, i) in localFields" #[field.slotCell]="data">
        <slot :name="field.slotCell" v-bind="data">
          <badge-notification
            v-if="data.item.hasBadge && i === 0"
            :key="'cell-' + key"
            :show-exclamation-icon-instead-of-amount="true"
            class="cell-badge"
          />
          <template
            v-if="
              field.type == 'action' ||
              (field.type == 'html' && data.item.object && data.item.object.descriptionType == 'action')
            "
          >
            <span v-for="(action, k) in data.value" :key="k" :class="action.class">
              <b-form-checkbox
                v-if="action.actionType === 'checkbox'"
                v-model="action.value"
                v-b-tooltip.hover
                class="d-inline mr-2"
                :title="action.title"
                :aria-label="action.title"
              />
              <b-btn
                v-else
                size="sm"
                class="margin-right"
                :class="{ 'has-icon': action.icon }"
                :variant="action.variant"
                :disabled="action.disabled ? action.disabled : false"
                :aria-label="action.title"
                @click.stop="
                  $emit(action.method, {
                    item: data.item,
                    parameters: action.parameters,
                    refs: $refs,
                  })
                "
              >
                <template v-if="action.textkey">
                  {{ action.textkey | fromTextKey }}
                </template>
                <template v-if="action.icon">
                  <i v-if="!action.title" :class="action.icon" />
                  <i v-else v-b-tooltip.hover :class="action.icon" :title="action.title" />
                </template>
                <template v-if="action.text">
                  {{ action.text }}
                </template>
              </b-btn>
            </span>
          </template>
          <template v-else-if="field.type == 'checkbox'">
            <b-form-checkbox
              v-if="data.item.rowType == 'parentRow'"
              v-model="field.selectedArrays[data.value.checkboxType].allSelected"
              :disabled="field.selectedArrays[data.value.checkboxType].disabled || data.value.disabled"
              :aria-label="'ARIA_LABEL_CHECK_ALL' | fromTextKey"
              @change="checkAllSelected(field, data), $emit(data.value.method, localFields, data)"
            />
            <b-form-checkbox
              v-else
              v-model="field.selectedArrays[data.value.checkboxType].selected"
              :disabled="field.selectedArrays[data.value.checkboxType].disabled || data.value.disabled"
              :aria-label="field.itemAriaLabel"
              :value="data.item.itemId"
              @click.native.stop
              @change="checkSelected(field, data), $emit(data.value.method, localFields, data)"
            />
          </template>
          <template v-else-if="field.type == 'detailRow' && data.item.rowType == 'parentRow'">
            <div
              :key="'cell-' + key"
              :aria-label="'ARIA_LABEL_SEE_DETAILS' | fromTextKey"
              tabindex="0"
              role="button"
              class="d-flex justify-content-between align-items-center"
              @click="toggleDetailRows(data.item.parentId)"
              @keydown.enter="toggleDetailRows(data.item.parentId)"
            >
              {{ data.value }}
              <i
                v-if="checkChildRow(data)"
                class="custom-icon custom-icon-variables"
                :class="getChildRowIconClass(data)"
              />
            </div>
          </template>
          <template v-else-if="field.type == 'html'">
            <span :key="'cell-' + key" v-html="data.value" />
          </template>
          <template v-else-if="field.type == 'icon'">
            <div :key="'cell-' + key" class="icon">
              <i :class="data.value" />
            </div>
          </template>
          <template v-else>
            <template v-if="field.textkey">
              {{ data.value | fromTextKey }}
            </template>
            <template v-else-if="field.image">
              <user-icon
                :id="data.item.id"
                :name="data.item.username"
                :short-name="data.item.itemId.shortName"
                :picture-object="{ url: data.value }"
                class="table-img"
              />
            </template>
            <template v-else>
              {{ data.value }}
            </template>
          </template>
        </slot>
      </template>
    </b-table>
    <div
      class="d-flex align-items-center pb-4"
      :class="showPageSizeOptions ? 'justify-content-between' : 'justify-content-end'"
    >
      <div v-if="showPageSizeOptions" class="d-flex align-items-center">
        {{ 'TABLE_SELECT_SHOW_PAGE_OPTIONS' | fromTextKey }}
        <aula-select
          v-model="selectedPageSize"
          class="page-option-select px-2"
          :fit-input-width="true"
          @change="$emit('onPageSizeChanged')"
        >
          <aula-option
            v-for="pageSizeOption in pageSizeOptions"
            :key="pageSizeOption"
            :label="pageSizeOption"
            :value="pageSizeOption"
          />
        </aula-select>
        {{ 'TABLE_SELECT_PAGE_OPTIONS' | fromTextKey }}
      </div>
      <b-pagination
        v-if="
          ((serverSide && serverSideTotalRows / pageSize > 1) || (!serverSide && totalRows / pageSize > 1)) &&
          showPagination &&
          !isMobile
        "
        v-model="localCurrentPage"
        :total-rows="serverSide ? serverSideTotalRows : totalRows"
        :per-page="pageSize"
        class="m-0"
      />
    </div>
    <div v-if="isMobile && parent === parentTypes.PORTAL" class="table">
      <div v-if="filteredItems == null || (filteredItems != null && filteredItems.length == 0)" class="table-row body">
        <div class="table-cell collapsed-only">
          {{ emptyTableText | fromTextKey }}
        </div>
      </div>
      <div v-for="(item, i) in filteredItems" :key="i" class="table-row body">
        <div class="table-cell collapsed-only">
          <div v-for="(field, key) in fields" :key="key" class="item">
            <template v-if="field.type == 'action'">
              <span>{{ field.label }}</span>
              <span>
                <b-btn
                  v-for="(action, j) in item[key]"
                  :key="i + '-' + j"
                  variant="link"
                  @click="
                    $emit(action.method, {
                      item: item,
                      parameters: action.parameters,
                      refs: $refs,
                    })
                  "
                  >{{ action.textkey | fromTextKey }}</b-btn
                >
              </span>
            </template>
            <template v-else>
              <span>{{ field.label }}: </span>{{ item[key] }}
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { portal } from '../assets/plugins/axios/axios.js';
import { types } from '../../src_adm/store/types/types';
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import BadgeNotification from './BadgeNotification';
import { parentTypes } from '../enums/parentTypes.ts';
import { pageSizeOption } from '../enums/pageSizeOption';
import isEmpty from 'lodash/isEmpty';

export default {
  components: { BadgeNotification },
  props: {
    parent: { type: String, default: parentTypes.ADMINISTRATION },
    tableRef: String,
    perPage: { type: Number, default: 200 },
    totalRows: { type: Number, default: 200 },
    currentPage: { type: Number, default: 1 },
    items: Array,
    search: { type: Boolean, default: false },
    fields: Object,
    rowClickable: { type: Boolean, default: false },
    rowSelectable: { type: Boolean, default: false },
    striped: { type: Boolean, default: false },
    hover: { type: Boolean, default: false },
    serverSide: { type: Boolean, default: false },
    serverSideUrl: { type: String, default: '' },
    emptyTableFilteredText: {
      type: String,
      default: 'TABLE_EMPTY_FILTERED_TEXT',
    },
    emptyTableText: { type: String, default: 'TABLE_EMPTY_TEXT' },
    responsive: { type: Boolean, default: false },
    stickyHeader: { type: Boolean, default: false },
    initialSortBy: { type: String, default: '' },
    initialSortDesc: { type: Boolean, default: false },
    showPagination: { type: Boolean, default: false },
    showPageSizeOptions: { type: Boolean, default: false },
    searchFields: {
      type: Array,
      default: () => [],
    },
    prepareParams: Function,
    prepareItems: Function,
    isHttpPost: { type: Boolean, default: false },
    filterCountFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      parentTypes: parentTypes,
      sortDesc: this.initialSortDesc,
      sortBy: this.initialSortBy,
      isBusy: false,
      filter: null,
      filteredItems: [],
      selected: [],
      vue: Vue,
      localCurrentPage: 1,
      localFields: {},
      selectedFilter: {},
      startDate: '',
      endDate: '',
      isStartDateClicked: false,
      isEndDateClicked: false,
      isFieldsDataInitialized: false,
      availableStartDate: {
        disabledDate: time => {
          let to;
          if (this.endDate) {
            to = moment(this.endDate).startOf('day').unix();
          } else {
            to = moment(time).endOf('month').unix();
          }
          return time.getTime() / 1000 > to;
        },
      },
      availableEndDate: {
        disabledDate: time => {
          let from;
          if (this.startDate) {
            from = moment(this.startDate).startOf('day').unix();
          } else {
            this.endDate = null;
            return true;
          }
          return time.getTime() / 1000 < from;
        },
      },
      serverSideTotalRows: 0,
      pageSizeOptions: pageSizeOption.PAGE_SIZE_OPTIONS,
      selectedPageSize: pageSizeOption.DEFAULT_PAGE_SIZE,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
    }),
    listFields() {
      return Object.keys(this.fields).map(key => ({
        ...this.fields[key],
        key,
      }));
    },
    pageSize() {
      if (this.showPageSizeOptions) {
        return this.selectedPageSize;
      }
      return this.perPage;
    },
  },
  watch: {
    currentPage() {
      this.localCurrentPage = this.currentPage;
    },
    items() {
      if (this.items != null && this.items.length >= 0 && !this.serverSide) {
        this.updateAllFiltersAndRows('');
      }
    },
    fields: {
      handler() {
        this.populateLocalFields();
      },
      deep: true,
    },
    filteredItems(newVal) {
      if (!this.serverSide) {
        this.$emit('updateFilteredItems', newVal);
      }
    },
    localCurrentPage() {
      this.$emit('paginationChanged', this.localCurrentPage);
    },
  },
  mounted() {
    this.populateLocalFields();
    this.setFilteredItemsToAll();
    this.prepareFields();
    this.sortBy = this.initialSortBy;
    this.sortDesc = this.initialSortDesc;
  },
  methods: {
    getFilterLabel(filter, fieldLabel) {
      if (!this.serverSide) {
        return filter;
      }
      if (!this.filterCountFields.includes(fieldLabel)) {
        return filter.name;
      }
      return `${filter.name} (${filter.count})`;
    },
    checkChildRow(data) {
      return this.items.some(item => item.rowType == 'childRow' && item.parentId == data.item.parentId);
    },
    getChildRowIconClass(data) {
      return this.filteredItems.find(item => item.rowType == 'childRow' && item.parentId == data.item.parentId)
        ?.showChild
        ? 'icon-Aula_down-arrow'
        : 'icon-Aula_up-arrow';
    },
    populateLocalFields() {
      this.localFields = Object.keys(this.fields).reduce((reducer, key) => {
        reducer[key] = {
          ...this.fields[key],
          slotHead: `head(${key})`,
          slotCell: `cell(${key})`,
        };
        return reducer;
      }, {});
      this.restoreSelectedFilter();
    },
    clearSelectedFilters() {
      for (const fieldKey in this.localFields) {
        this.localFields[fieldKey].selectedFilters = [];
      }
    },
    checkFilterItemEmpty(filterItem) {
      if (filterItem === '') {
        return false;
      }
      return true;
    },
    checkSelected(field, data) {
      Vue.nextTick(() => {
        if (field.selectedArrays[data.value.checkboxType].allSelected != null) {
          field.selectedArrays[data.value.checkboxType].selected.length == data.item.items.length
            ? (field.selectedArrays[data.value.checkboxType].allSelected = true)
            : (field.selectedArrays[data.value.checkboxType].allSelected = false);
        }
      });
    },
    checkAllSelected(field, data) {
      Vue.nextTick(function () {
        if (field.selectedArrays[data.value.checkboxType].allSelected != null) {
          if (field.selectedArrays[data.value.checkboxType].allSelected) {
            field.selectedArrays[data.value.checkboxType].selected = data.item.items;
          } else {
            field.selectedArrays[data.value.checkboxType].selected = [];
          }
        }
      });
    },
    setFilteredItemsToAll() {
      this.filteredItems = this.items;
      // Hide child rows if they are all selected or unselected. Only show if there is inconsistency
      if (this.filteredItems != null) {
        for (const item of this.filteredItems) {
          if (item.rowType == 'parentRow') {
            let toggleRow = true;
            for (const field in this.localFields) {
              if (
                this.fields[field].selectedArrays != null &&
                !this.fields[field].selectedArrays[item.type].allSelected &&
                this.fields[field].selectedArrays[item.type].selected.length > 0
              ) {
                toggleRow = false;
              }
            }
            if (toggleRow) {
              this.toggleDetailRows(item.parentId);
            }
          }
        }
      }
    },
    toggleDetailRows(parentId) {
      this.filteredItems = [];
      for (const item of this.items) {
        if (item.rowType === 'childRow' && item.parentId === parentId && item.showChild) {
          item.showChild = false;
        } else if (item.rowType === 'childRow' && item.parentId === parentId && !item.showChild) {
          item.showChild = true;
          this.filteredItems.push(item);
        } else if (item.rowType === 'childRow' && !item.showChild) {
          continue;
        } else {
          this.filteredItems.push(item);
        }
      }
    },
    filterTable(field, filterKey, checked) {
      this.localCurrentPage = 1;
      if (!this.serverSide) {
        this.localFields[filterKey].filterIsActive = this.localFields[filterKey].selectedFilters.length !== 0;
        this.updateAllFiltersAndRows(filterKey);
      } else {
        this.storeSelectedFilter(field, filterKey, checked);
        this.$refs[this.tableRef].refresh();
      }
    },
    resetFilter(field, filterKey) {
      field.selectedFilters = [];
      this.filterTable(filterKey, filterKey);
    },
    filterDateTable(filterKey) {
      this.localFields[filterKey].filterIsActive = true;
      this.updateAllDateFiltersAndRows(filterKey);
    },
    updateAllFiltersAndRows(filterKey) {
      if (this.items.length === 0) {
        this.filteredItems = [];
        return;
      }
      if (!this.serverSide) {
        const keys = Object.keys(this.items[0]);

        // Update rows
        this.filteredItems = [];
        let allFiltersInactive = true;
        for (const row of this.items) {
          let addRowToTable = true;
          for (const key of keys) {
            if (this.localFields.hasOwnProperty(key)) {
              if (this.localFields[key].filtering && this.localFields[key].filterIsActive) {
                allFiltersInactive = false;
                if (this.localFields[key].selectedFilters.filter(filter => row[key].includes(filter)).length == 0) {
                  addRowToTable = false;
                }
              }
            }
          }
          if (addRowToTable) {
            this.filteredItems.push(row);
          }
        }

        if (allFiltersInactive) {
          this.setFilteredItemsToAll();
        }

        // Update Filters
        for (const key of keys) {
          if (this.localFields.hasOwnProperty(key)) {
            if (this.localFields[key].filtering && key != filterKey && !this.localFields[key].filterIsActive) {
              if (this.localFields[key].advancedFiltering) {
                this.prepareFields();
              } else {
                this.localFields[key].uniqueItems = [];
                const unique = [...new Set(this.items.map(item => item[key]))];
                for (const val of unique) {
                  this.filteredItems.map(item =>
                    item[key] == val && !this.localFields[key].uniqueItems.includes(val)
                      ? this.localFields[key].uniqueItems.push(val)
                      : ''
                  );
                }
              }
            } else if (
              this.localFields.hasOwnProperty(key) &&
              this.localFields[key].dateFiltering &&
              !this.startDate &&
              !this.endDate
            ) {
              this.localFields[key].filterIsActive = false;
            }
          }
        }
      }
    },
    updateAllDateFiltersAndRows() {
      if (this.items.length === 0) {
        this.filteredItems = [];
        return;
      }
      const keys = Object.keys(this.items[0]);

      // Update rows
      this.filteredItems = [];
      let allFiltersInactive = true;
      for (const row of this.items) {
        let addRowToTable = true;
        for (const key of keys) {
          if (this.localFields.hasOwnProperty(key)) {
            if (this.localFields[key].dateFiltering && this.localFields[key].filterIsActive) {
              allFiltersInactive = false;
              const dateValues = row[key].split('-');
              const dateValue = dateValues[2] + '-' + dateValues[1] + '-' + dateValues[0];
              if (
                (this.startDate != '' && moment(dateValue).isBefore(moment(this.startDate))) ||
                (this.endDate != '' && moment(dateValue).isAfter(moment(this.endDate)))
              ) {
                addRowToTable = false;
              }
            }
          }
        }
        if (addRowToTable) {
          this.filteredItems.push(row);
        }
      }

      if (allFiltersInactive) {
        this.setFilteredItemsToAll();
      }
    },
    prepareFields() {
      if (typeof this.items !== 'undefined' && this.items.length > 0 && !this.serverSide) {
        const keys = Object.keys(this.items[0]);
        for (const key of keys) {
          if (this.localFields.hasOwnProperty(key) && this.localFields[key].filtering) {
            if (this.localFields[key].advancedFiltering) {
              for (const item of this.items) {
                const textSplit = item[key].split(',');
                for (const elm of textSplit) {
                  if (!this.localFields[key].uniqueItems.includes(elm.trim()) && elm !== '') {
                    this.localFields[key].uniqueItems.push(elm.trim());
                  }
                }
              }
            } else {
              this.localFields[key].uniqueItems = [...new Set(this.items.map(item => item[key]))];
              this.localFields[key].uniqueItems = this.localFields[key].uniqueItems.filter(function (n) {
                return n != '';
              });
            }
          }
        }
      }
    },
    rowClicked(item) {
      if (this.rowClickable) {
        this.$emit('rowClicked', item);
      }
    },
    sortingChanged(ctx) {
      if (ctx.sortBy == null) {
        ctx.sortBy = this.initialSortBy;
        this.sortBy = this.initialSortBy;
      }
      if (ctx.sortDesc == null) {
        ctx.sortDesc = this.initialSortDesc;
        this.sortDesc = this.initialSortDesc;
      }
      if (!this.serverSide) {
        if (ctx.sortDesc) {
          this.filteredItems = this.filteredItems.sort((a, b) => this.sortItemsDesc(a, b, ctx));
        } else {
          this.filteredItems = this.filteredItems.sort((a, b) => this.sortItemsAsc(a, b, ctx));
        }
      }
    },
    sortItemsDesc(a, b, ctx) {
      if (!a[ctx.sortBy]) {
        a[ctx.sortBy] = '';
      }
      if (!b[ctx.sortBy]) {
        b[ctx.sortBy] = '';
      }
      if (Number.isInteger(a[ctx.sortBy])) {
        return a[ctx.sortBy] - b[ctx.sortBy];
      } else if (typeof a[ctx.sortBy] == 'string') {
        return a[ctx.sortBy].localeCompare(b[ctx.sortBy], 'da', {
          sensitivity: 'base',
        });
      } else if (typeof a[ctx.sortBy] == 'object' && typeof a[ctx.sortBy].name == 'string') {
        return a[ctx.sortBy].name.localeCompare(b[ctx.sortBy].name, 'da', {
          sensitivity: 'base',
        });
      } else {
        return 0;
      }
    },
    sortItemsAsc(a, b, ctx) {
      if (!a[ctx.sortBy]) {
        a[ctx.sortBy] = '';
      }
      if (!b[ctx.sortBy]) {
        b[ctx.sortBy] = '';
      }
      if (Number.isInteger(a[ctx.sortBy])) {
        return b[ctx.sortBy] - a[ctx.sortBy];
      } else if (typeof a[ctx.sortBy] == 'string') {
        return b[ctx.sortBy].localeCompare(a[ctx.sortBy], 'da', {
          sensitivity: 'base',
        });
      } else if (typeof a[ctx.sortBy] == 'object' && typeof a[ctx.sortBy].name == 'string') {
        return b[ctx.sortBy].name.localeCompare(a[ctx.sortBy].name, 'da', {
          sensitivity: 'base',
        });
      } else {
        return 0;
      }
    },
    hideFilterDropdown() {
      for (const key in this.localFields) {
        this.localFields[key].showFilterDropdown = false;
      }
    },
    myProvider(ctx) {
      const params = this.prepareParams(ctx, this.localFields);
      const promise = this.isHttpPost
        ? portal.post(this.serverSideUrl, params)
        : portal.get(this.serverSideUrl, { params: params });

      return promise
        .then(response => {
          const items = this.prepareItems(response);
          if (response.data.data.totalAmount) {
            this.serverSideTotalRows = response.data.data.totalAmount;
          } else if (response.data.data.totalSize) {
            this.serverSideTotalRows = response.data.data.totalSize;
          } else if (response.data.data.totalResultCount) {
            this.serverSideTotalRows = response.data.data.totalResultCount;
          } else if (response.data.data.total) {
            this.serverSideTotalRows = response.data.data.total;
          } else {
            this.serverSideTotalRows = items.length;
          }
          this.filteredItems = items;

          if (!this.isFieldsDataInitialized) {
            this.populateLocalFields();
            this.isFieldsDataInitialized = true;
          }

          return items;
        })
        .catch(() =>
          // Returning an empty array, allows table to correctly handle busy state in case of error
          []
        );
    },
    refresh() {
      this.localCurrentPage = 1;
      this.isFieldsDataInitialized = false;
      this.clearSelectedFilters();
      this.$refs[this.tableRef].refresh();
    },
    refreshCurrentPage() {
      this.$refs[this.tableRef].refresh();
    },
    storeSelectedFilter(field, filterKey, checked) {
      const resetSelectedFilter = field === filterKey;
      if (resetSelectedFilter) {
        this.selectedFilter[field] = [];
        return;
      }
      this.selectedFilter[field] = checked;
    },
    restoreSelectedFilter() {
      if (this.serverSide && !isEmpty(this.selectedFilter)) {
        for (const key in this.selectedFilter) {
          this.localFields[key].selectedFilters = this.selectedFilter[key];
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';

.cell-badge {
  --position: relative;
  display: inline-block;
  padding-right: 4px;
}
.custom-icon-variables {
  --table-icon-font-size: 0.5em;
}
.custom-icon {
  margin-left: 4px;
}

.custom-table-icon {
  font-size: 8px;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 1000;

  .create-additional-data-container & {
    right: -5px;
  }
}

.nowrap {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.filter-spacing {
  padding-right: 30px;
}

.aula-table /deep/ .table-responsive {
  min-height: 350px;
  position: sticky;
  margin-bottom: 10px;
  & > table {
    width: calc(100% - 1px);
  }
}
.table-responsive {
  /deep/.sorting {
    padding-left: 3em;
    padding-right: 17px;
    &:before {
      left: 2em !important;
    }
    &:after {
      left: 1.5em !important;
    }
  }
}
.page-option-select {
  width: 90px;
}

.sticky-header {
  /deep/ .table-responsive {
    overflow-y: visible;
    overflow-x: visible;
  }
  /deep/ .b-table.table > thead > tr > th {
    position: sticky;
    z-index: 2;

    /*
      The distance is 1px less than the topbar because the th is pushed down 1px due to a border of a parent element.
    */
    top: 119px;
    @include breakpoint-xl-down() {
      top: 79px;
    }
    @include breakpoint-lg-down() {
      top: 57px;
    }
  }
}
</style>
