export class FileValidationService {
  private readonly extensions: string[];
  private readonly maximumFileSize: number;

  constructor(extensions, maximumFileSize = 10000) {
    this.extensions = extensions;
    this.maximumFileSize = maximumFileSize;
  }

  validateSize(file: File): boolean {
    return file.size <= this.maximumFileSize * 1024 * 1024;
  }
  validateExtension(file: File): boolean {
    const extension = this.getFileExtension(file);
    return this.extensions.includes(extension);
  }

  private getFileExtension(file: File) {
    const nameChunks = file.name.split('.') || '';
    return nameChunks[nameChunks.length - 1].toLowerCase();
  }
}
