
















import AulaButton from '../AulaButton.vue';
import Icon from '../Icon.vue';
import { iconClassEnum } from '../../enums/iconClassEnum';
import UserDetailsContactInformationRevisionTable from './UserDetailsContactInformationRevisionTable.vue';
import { providerKeyEnum } from '../../enums/providerKeyEnum.js';
import { PropType } from 'vue';
import { consentsAdminService } from '../../services/api/consentsAdmin.service';
import { ConsentResponseRevisionHistoryQuestionModel } from '../../models/ConsentResponseRevisionHistoryQuestion.model';

export default {
  components: { UserDetailsContactInformationRevisionTable, Icon, AulaButton },
  inject: {
    getInstitutionProfile: providerKeyEnum.institutionProfile,
  },
  data() {
    return {
      questions: [] as PropType<ConsentResponseRevisionHistoryQuestionModel[]>,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    institutionProfile() {
      return this.getInstitutionProfile();
    },
  },
  mounted() {
    this.getAdditionalDataResponseRevisionHistory();
  },
  methods: {
    async getAdditionalDataResponseRevisionHistory() {
      const { questions } = await consentsAdminService.getConsentResponseRevisionHistory(this.institutionProfile.id);
      this.questions = questions;
    },
  },
};
