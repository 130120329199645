<template>
  <aula-table
    ref="table"
    :per-page="perPage"
    :search="false"
    :fields="fields"
    :current-page="currentPage"
    :responsive="true"
    table-ref="usersLockReasons"
    :server-side="true"
    :server-side-url="serverSideUrl"
    :is-http-post="false"
    :prepare-params="getParams"
    :prepare-items="getItems"
    :show-pagination="true"
  >
    <template #[causeBodyCell]="data">
      <p class="m-0 cause-content">
        {{ data.item.cause }}
      </p>
    </template>
    <template #[incidentBodyCell]="data">
      <span v-if="data.item.incident === lockStatusEnum.LOCKED || data.item.incident === lockStatusEnum.LOCKING">
        {{ 'USERS_LOCK_USER_INCIDENT_LOCKED' | fromTextKey }}
      </span>
      <span v-else>
        {{ 'USERS_LOCK_USER_INCIDENT_UNLOCKED' | fromTextKey }}
      </span>
    </template>
  </aula-table>
</template>
<script lang="js">
import {UserDetailsPortalProvideKey} from "./UsersDetailsPortalProvideKey";
import {providerKeyEnum} from "../../enums/providerKeyEnum";
import {lockStatusEnum} from "../../enums/lockStatusEnum";
import {dateFormatTypes} from "../../enums/dateFormatTypes";

export default {
  name: 'UserDetailsPortalAdministrationTabsLockUserTable',
  inject: {
    callbackService: UserDetailsPortalProvideKey.refreshDataTableCallbackService,
    getInstitutionProfile: providerKeyEnum.institutionProfile,
  },
  data() {
    return {
      lockStatusEnum,
      causeBodyCell: 'cell(cause)',
      incidentBodyCell: 'cell(incident)',
      items: [],
      perPage: 20,
      currentPage: 1,
      serverSideUrl: '?method=profilesAdmin.getLockHistory',
      fields: {
        date: {
          label: this.$options.filters.fromTextKey('USERS_LOCK_USER_TABLE_HEADER_DATE'),
        },
        user: {
          label: this.$options.filters.fromTextKey('USERS_LOCK_USER_TABLE_HEADER_USER'),
        },
        incident: {
          label: this.$options.filters.fromTextKey('USERS_LOCK_USER_TABLE_HEADER_INCIDENT'),
        },
        cause: {
          label: this.$options.filters.fromTextKey('USERS_LOCK_USER_TABLE_HEADER_CAUSE'),
        },
      },
    };
  },
  computed: {
    institutionProfile() {
      return this.getInstitutionProfile();
    }
  },
  mounted() {
    this.callbackService.addCallback(this.refreshTable);
  },
  beforeDestroy() {
    this.callbackService.removeCallback(this.refreshTable);
  },
  methods: {
    getItems(response) {
      return response.data.data.lockHistory.map(lockItem => ({
          date: this.$options.filters.date(dateFormatTypes.dayMonthYearTime, lockItem.createdAt),
          user: lockItem.createdBy.name,
          incident: lockItem.lockStatus,
          cause: lockItem.reason,
        })
      );
    },
    getParams(ctx) {
      return {
        institutionProfileId: this.institutionProfile.id,
        offset: ctx.currentPage * ctx.perPage - ctx.perPage,
        limit: ctx.perPage,
      };
    },
    refreshTable() {
      const tableRef = this.$refs.table;
      tableRef.refreshCurrentPage();
    },
  },
}
</script>
<style lang="scss" scoped>
.cause-content {
  max-width: 250px;
  overflow-wrap: break-word;
}
</style>
