




















import TableContainer from '../../libs/table/components/TableContainer.vue';
import TableHead from '../../libs/table/components/TableHead.vue';
import TableRow from '../../libs/table/components/TableRow.vue';
import TableHeading from '../../libs/table/components/TableHeading.vue';
import TableBody from '../../libs/table/components/TableBody.vue';
import TableCell from '../../libs/table/components/TableCell.vue';
import { PropType } from 'vue';
import { AdditionalDataResponseRevisionModel } from '../../models/additionalDataResponseRevision.model';
import * as dateUtil from '../../utils/dateUtil.js';
import { dateFormatEnum } from '../../enums/dateFormatEnum';

export default {
  components: { TableCell, TableBody, TableHeading, TableRow, TableHead, TableContainer },
  props: {
    revisions: { type: Array as PropType<AdditionalDataResponseRevisionModel[]>, required: true },
  },
  methods: {
    formatDateTime(datetime) {
      return dateUtil.format(datetime, dateFormatEnum.COMPACT_DATE_TIME);
    },
    getRevisionYesNoResponse(revision) {
      if (revision.yesNoResponse === true) {
        return this.$t('label.yes');
      }
      if (revision.yesNoResponse === false) {
        return this.$t('label.no');
      }
      return '';
    },
  },
};
