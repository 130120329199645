export class RoleUtil {
  getRoleIdsToAssign({ existingRoles, roleIdsToAssign, roleIdsToUnAssign }) {
    const roleDefinitionIdDictionary = {};
    for (const existingRole of existingRoles) {
      roleDefinitionIdDictionary[existingRole.id] = true;
    }

    for (const id of roleIdsToAssign) {
      roleDefinitionIdDictionary[id] = true;
    }

    for (const id of roleIdsToUnAssign) {
      delete roleDefinitionIdDictionary[id];
    }

    return Object.keys(roleDefinitionIdDictionary);
  }
}

export const roleUtil = new RoleUtil();
