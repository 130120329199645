export const consentNumber = Object.freeze({
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_11: 11,
  NUMBER_26: 26,
  NUMBER_27: 27,
  NUMBER_32: 32,
  NUMBER_36: 36,
});
