<template>
  <div>
    <p class="my-4">{{ 'USERS_LOCK_USER_INTRO' | fromTextKey }}</p>
    <AulaButton :disabled="isLockButtonDisabled" class="text-uppercase" variant="primary" @click="onLockUserClick">
      <span>{{ lockUserTextKey | fromTextKey }}</span>
    </AulaButton>
    <b-alert variant="danger" :show="showLockingWarning">
      {{ 'USERS_LOCK_USER_LOCKING_WARNING' | fromTextKey }}
    </b-alert>
    <b-alert variant="danger" :show="showUnlockingWarning">
      {{ 'USERS_LOCK_USER_UNLOCKING_WARNING' | fromTextKey }}
    </b-alert>
    <hr class="mb-5 mt-4" />
    <UserDetailsPortalAdministrationTabsLockUserTable />
  </div>
</template>
<script lang="js">
import {UserDetailsPortalProvideKey} from "./UsersDetailsPortalProvideKey";
import AulaButton from "../AulaButton";
import UserDetailsPortalAdministrationTabsLockUserTable from "./UserDetailsAdministrationTabsLockUserTable";
import {providerKeyEnum} from "../../enums/providerKeyEnum";
import {lockStatusEnum} from "../../enums/lockStatusEnum";

export default {
  name: 'UserDetailsPortalAdministrationTabsLockUser',
  components: {
    UserDetailsPortalAdministrationTabsLockUserTable,
    AulaButton,
  },
  inject: {
    setShowLockReason: UserDetailsPortalProvideKey.setShowLockReasonForm,
    getInstitutionProfile: providerKeyEnum.institutionProfile,
    setInstitutionProfileOfLockUser: UserDetailsPortalProvideKey.setInstitutionProfile,
  },
  computed: {
    institutionProfile() {
      return this.getInstitutionProfile();
    },
    isLockButtonDisabled(){
      return this.institutionProfile.profileStatus === lockStatusEnum.DEACTIVATED;
    },
    lockUserTextKey() {
      const status = this.institutionProfile.profileStatus;
      if (status === lockStatusEnum.LOCKED || status === lockStatusEnum.LOCKING) {
        return 'USERS_UNLOCK_USER';
      }

      return 'USERS_LOCK_USER';
    },
    showLockingWarning() {
      return this.institutionProfile.profileStatus === lockStatusEnum.LOCKING;
    },
    showUnlockingWarning() {
      return this.institutionProfile.profileStatus === lockStatusEnum.UNLOCKING;
    }
  },
  methods: {
    onLockUserClick($event) {
      this.setShowLockReason($event);
      this.setInstitutionProfileOfLockUser(this.institutionProfile);
    },
  },
}
</script>
