<template>
  <li class="list-item" v-on="$listeners">
    <div class="before">
      <slot name="before"></slot>
    </div>
    <div class="primary-content">
      <slot />
    </div>
    <div class="end">
      <slot name="end" />
    </div>
  </li>
</template>

<script>
export default {
  name: 'AulaListItem',
};
</script>

<style lang="scss" scoped>
.list-item {
  display: flex;
  align-items: stretch;
}

.primary-content {
  margin-right: auto;
}
</style>
