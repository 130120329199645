import { render, staticRenderFns } from "./ContactInformationPrimaryInstitution.vue?vue&type=template&id=3348df68&scoped=true&"
import script from "./ContactInformationPrimaryInstitution.vue?vue&type=script&lang=ts&"
export * from "./ContactInformationPrimaryInstitution.vue?vue&type=script&lang=ts&"
import style0 from "./ContactInformationPrimaryInstitution.vue?vue&type=style&index=0&id=3348df68&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3348df68",
  null
  
)

export default component.exports