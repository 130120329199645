





























import { PropType } from 'vue';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import { InstitutionProfileMasterDataForm } from '../../business/institutionProfileMasterDataForm';
import ContactInformationMainContact from './ContactInformationMainContact.vue';
import ContactInformationRelatedContact from './ContactInformationRelatedContact.vue';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types.js';
import { permissionEnum } from '../../../shared/enums/permissionEnum';

export default {
  components: { ContactInformationRelatedContact, ContactInformationMainContact },
  props: {
    institutionProfile: { type: Object as PropType<InstitutionProfileModel>, required: true },
    isEditing: { type: Boolean, default: false },
    overConsentAge: { type: Boolean, default: false },
    showRelations: { type: Boolean, default: false },
    showShortcuts: { type: Boolean, default: false },
  },
  emits: ['input', 'deleteAvatar', 'editAvatar'],
  computed: {
    ...mapGetters({
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      hasPermission: types.HAS_PERMISSION,
    }),
    relatedInstitutionProfiles() {
      return this.institutionProfile.relations;
    },
    canViewConsents() {
      if (
        !this.institutionProfile.currentUserCanViewContactInformation ||
        this.institutionProfile.role !== portalRoles.CHILD
      ) {
        return false;
      }
      const hasPermission = this.hasPermissionOnInstitution(
        permissionEnum.VIEW_USERS_CONSENTS,
        this.institutionProfile.institutionCode
      );
      return hasPermission && this.overConsentAge;
    },
    canViewAdditionalConsents() {
      if (this.institutionProfile.role !== portalRoles.CHILD) {
        return false;
      }
      return this.hasPermissionOnInstitution(
        permissionEnum.VIEW_USERS_ADDITIONAL_DATA,
        this.institutionProfile.institutionCode
      );
    },
    isCommunicationBlocked() {
      return !this.hasPermission(permissionEnum.WRITE_MESSAGE) || this.institutionProfile.communicationBlocked;
    },
  },
  methods: {
    emitInput(form: InstitutionProfileMasterDataForm) {
      this.$emit('input', form);
    },
    emitDeleteAvatar(profile: InstitutionProfileModel) {
      this.$emit('deleteAvatar', profile);
    },
    emitEditAvatar(profile: InstitutionProfileModel) {
      this.$emit('editAvatar', profile);
    },
    hasBlockedCommunicationChannels(profile: InstitutionProfileModel) {
      return profile.hasBlockedCommunicationChannels;
    },
    canShowShortcuts(profile: InstitutionProfileModel) {
      return this.showShortcuts && !this.isCommunicationBlocked && !this.hasBlockedCommunicationChannels(profile);
    },
  },
};
