<template>
  <UserDetailsAdministrationGroupsWithRoles :is-loading="isLoading" @onPageChanged="onGroupPageChanged" />
</template>
<script>
import UserDetailsAdministrationGroupsWithRoles from './UserDetailsAdministrationGroupsWithRoles';
import { mapGetters, mapActions } from 'vuex';
import { types } from '../../../src_adm/store/types/types';
import { providerKeyEnum } from '../../enums/providerKeyEnum';

export default {
  name: 'UserDetailsAdministrationTabsGroups',
  components: { UserDetailsAdministrationGroupsWithRoles },
  provide() {
    return {
      [providerKeyEnum.groupsWithRoleDefinitions]: () => this.groupsWithRoleDefinitions,
      [providerKeyEnum.numberOfGroups]: () => this.numberOfGroups,
    };
  },
  inject: {
    getInstitutionProfile: providerKeyEnum.institutionProfile,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      groupsWithRoleDefinitions: types.GET_SELECTED_USER_GROUPS_WITH_ROLE_DEFINITIONS,
      numberOfGroups: types.GET_SELECTED_USER_NUMBER_OF_GROUPS,
    }),
    institutionProfile() {
      return this.getInstitutionProfile();
    },
  },
  mounted() {
    this.loadGroupsWithRolesByPage(1);
  },
  methods: {
    ...mapActions({
      getAssignedGroupRoles: types.ACTION_GET_ASSIGNED_GROUP_ROLES,
    }),
    onGroupPageChanged(pageNumber) {
      this.loadGroupsWithRolesByPage(pageNumber);
    },
    loadGroupsWithRolesByPage(pageNumber) {
      this.isLoading = true;
      this.getAssignedGroupRoles({ institutionProfileId: this.institutionProfile.id, pageNumber }).then(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>
