import { HttpError } from '../../../src/business/httpError';
import { AxiosError } from 'axios';
import { portal } from '../../assets/plugins/axios/axios';
import { AdditionalDataResponseRevisionHistoryModel } from '../../models/additionalDataResponseRevisionHistory.model';

class ProfileAdminService {
  public getAdditionalDataResponseRevisionHistory = async (
    institutionProfileId: number
  ): Promise<AdditionalDataResponseRevisionHistoryModel> => {
    try {
      const { data } = await portal.get('?method=profilesAdmin.getAdditionalDataResponseRevisionHistory', {
        params: { institutionProfileId },
      });
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
}

export const profileAdminService = new ProfileAdminService();
