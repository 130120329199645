



































import AulaButton from '../../../shared/components/AulaButton.vue';
import Icon from '../../../shared/components/Icon.vue';
import { PropType } from 'vue';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import AulaButtons from '../../../shared/components/AulaButtons.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { types } from '../../store/types/types.js';
import { additionalDataGrossListEnum } from '../../../shared/enums/additionalDataGrossList.js';
import ConsentAdditional from '../../../shared/components/consentAdditional/ConsentAdditional.vue';
import { portalRoles } from '../../../shared/enums/portalRoles';

export default {
  components: { ConsentAdditional, AulaButtons, Icon, AulaButton },
  props: {
    profile: { type: Object as PropType<InstitutionProfileModel>, required: true },
  },
  data() {
    return {
      isExpanded: false,
      isEditing: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      additionalConsents: types.GET_PROFILE_ADDITIONAL_DATA,
      loggedInProfile: types.GET_CURRENT_PROFILE,
    }),
    icon() {
      if (this.isExpanded) {
        return iconClassEnum.MINUS;
      }
      return iconClassEnum.PLUS;
    },
    canEdit() {
      if (this.loggedInProfile.role !== portalRoles.EMPLOYEE) {
        return false;
      }

      for (const consent of this.additionalConsents) {
        if (consent.additionalDataResponses.some(response => response.canCurrentProfileRespond)) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    setEditState(state) {
      this.isEditing = state;
    },
    ...mapMutations({
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
      resetConsents: types.MUTATE_RESET_PROFILE_CONSENT,
    }),
    ...mapActions({
      loadAdditionalData: types.LOAD_PROFILE_ADDITIONAL_DATA,
    }),
    toggle() {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded) {
        this.expand();
      }
    },
    expand() {
      if (!this.isSteppedUp) {
        this.redirectToStepUp();
        return;
      }
      this.loadConsents();
    },
    redirectToStepUp() {
      this.setStepUpNotification({
        showStepUpNotification: true,
        redirectedUrl: window.location.href,
      });
    },
    handleConsentsSaved() {
      this.isEditing = false;
      this.loadConsents();
    },
    async loadConsents() {
      this.isLoading = true;
      await this.loadAdditionalData({ instProfileIds: [this.profile.id] });
      this.isLoading = false;
    },
    getConsentAnswerText(answer) {
      if (answer.answerType !== additionalDataGrossListEnum.YESNO) {
        return answer.response || this.$options.filters.fromTextKey('CONSENT_ANSWER_NOT_ANSWERED');
      }
      if (answer.yesNoResponse === true) {
        return this.$options.filters.fromTextKey('ADDITIONAL_ANSWER_YES');
      }
      if (answer.yesNoResponse === false) {
        return this.$options.filters.fromTextKey('ADDITIONAL_ANSWER_NO');
      }
      return this.$options.filters.fromTextKey('CONSENT_ANSWER_NOT_ANSWERED');
    },
    getConsentOptionalQuestionAnswerText(answer) {
      return answer.response || this.$options.filters.fromTextKey('CONSENT_ANSWER_NOT_ANSWERED');
    },
    getOptionalQuestion(answer) {
      if (answer.yesNoResponse === true) {
        return answer.optionalQuestionForYes;
      }
      if (answer.yesNoResponse === false) {
        return answer.optionalQuestionForNo;
      }
      return '';
    },
  },
};
