






import UserDetailsContactInformationRevision from './UserDetailsContactInformationRevision.vue';
import UserDetailsAdditionalDataResponseRevision from './UserDetailsAdditionalDataResponseRevision.vue';

export default {
  components: { UserDetailsAdditionalDataResponseRevision, UserDetailsContactInformationRevision },
};
