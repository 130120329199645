<template>
  <b-th :class="collapseDirectionClasses">
    <slot />
  </b-th>
</template>
<script lang="js">
import {collapseDirectionProp} from "../props/collapseDirectionProp";
import {tableProvider} from "../providers/tableProvider";
import {collapseDirectionEnum} from "../enums/collapseDirectionEnum";

export default {
  name: 'TableHeading',
  inject: {
    getCollapseDirections: tableProvider.COLLAPSE_DIRECTIONS,
  },
  props: {
    collapseDirections: collapseDirectionProp,
  },
  computed: {
    collapseDirectionClasses() {
      const classes = ['table-head-cell'];
      let directions = this.getCollapseDirections();

      if (this.collapseDirections != null) {
        directions = this.collapseDirections;
      }

      for (const direction of directions) {
        switch (direction) {
          case collapseDirectionEnum.TOP:
            classes.push('collapse-top');
            break;
          case collapseDirectionEnum.BOTTOM:
            classes.push('collapse-bottom');
            break;
          case collapseDirectionEnum.LEFT:
            classes.push('collapse-left');
            break;
          case collapseDirectionEnum.RIGHT:
            classes.push('collapse-right');
            break;
        }
      }

      return classes;
    },
  }
};
</script>
<style lang="scss" scoped>
th.table-head-cell {
  background-color: var(--color-base) !important;
  border-right-color: var(--border-color-right, var(--color-base)) !important;
  border-left-color: var(--border-color-left, var(--color-base)) !important;
  border-top-color: var(--border-color-top, var(--color-base)) !important;
  border-bottom-color: var(--border-color-bottom, var(--color-base)) !important;

  &.collapse-right {
    border-right-style: unset !important;
    border-right-width: 0 !important;
  }

  &.collapse-left {
    border-left-style: unset !important;
    border-left-width: 0 !important;
  }

  &.collapse-bottom {
    border-bottom-style: unset !important;
    border-bottom-width: 0 !important;
  }

  &.collapse-top {
    border-top-style: unset !important;
    border-top-width: 0 !important;
  }
}
</style>
