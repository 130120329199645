






















import TableContainer from '../../libs/table/components/TableContainer.vue';
import TableHead from '../../libs/table/components/TableHead.vue';
import TableRow from '../../libs/table/components/TableRow.vue';
import TableHeading from '../../libs/table/components/TableHeading.vue';
import TableBody from '../../libs/table/components/TableBody.vue';
import TableCell from '../../libs/table/components/TableCell.vue';
import { PropType } from 'vue';
import { ConsentResponseRevisionModel } from '../../models/ConsentResponseRevision.model';
import { consentAnswerType } from '../../enums/consentAnswerType.js';
import * as dateUtil from '../../utils/dateUtil.js';
import { dateFormatEnum } from '../../enums/dateFormatEnum';

export default {
  components: { TableCell, TableBody, TableHeading, TableRow, TableHead, TableContainer },
  props: {
    revisions: { type: Array as PropType<ConsentResponseRevisionModel[]>, required: true },
  },
  methods: {
    getClassRoomAnswer(revision) {
      const response = [
        consentAnswerType.CLASS_OR_STUE,
        consentAnswerType.YEAR_AND_SFO,
        consentAnswerType.INSTITUTION,
      ].includes(revision.answer);
      return response ? this.$t('label.yes') : this.$t('label.no');
    },
    getYearAndSfoAnswer(revision) {
      const response = [consentAnswerType.YEAR_AND_SFO, consentAnswerType.INSTITUTION].includes(revision.answer);
      return response ? this.$t('label.yes') : this.$t('label.no');
    },
    getInstitutionAnswer(revision) {
      const response = [consentAnswerType.INSTITUTION].includes(revision.answer);
      return response ? this.$t('label.yes') : this.$t('label.no');
    },
    formatDateTime(datetime) {
      return dateUtil.format(datetime, dateFormatEnum.COMPACT_DATE_TIME);
    },
  },
};
