<template>
  <b-table-simple>
    <caption>
      {{
        'GROUP_MEMBERSHIP_RIGHTS' | fromTextKey
      }}
    </caption>
    <b-thead>
      <b-tr>
        <b-th class="text-capitalize">{{ 'GROUP' | fromTextKey }}</b-th>
        <b-th class="text-capitalize">{{ 'USER_RIGHT' | fromTextKey }}</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr v-if="groupsWithAssignedRoles.length === 0">
        <b-td colspan="2" class="text-center cursor-default">{{ 'NO_ASSIGNED_ROLES' | fromTextKey }}</b-td>
      </b-tr>
      <b-tr v-for="group in groupsWithAssignedRoles" :key="group.groupId">
        <b-td
          :class="{ 'cursor-default': !canCurrentUserAccessGroups }"
          class="align-top"
          @click="onGroupClicked(group)"
          >{{ group.groupName }}</b-td
        >
        <b-td class="cursor-default">
          <div v-for="assignment in group.assignments" :key="assignment.roleDefinition.id" class="m-1">
            {{ assignment.roleDefinition.name }}
            <UserDetailsAdministrationUserRightHelpBox :role="assignment.roleDefinition" />
          </div>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>
<script lang="js">
import {providerKeyEnum} from "../../enums/providerKeyEnum";
import {UserDetailsPortalProvideKey} from "./UsersDetailsPortalProvideKey";
import UserDetailsAdministrationUserRightHelpBox from "./UserDetailsAdministrationUserRightHelpBox";

export default {
  name: 'UserDetailsAdministrationGroupMembershipRights',
  components: {
    UserDetailsAdministrationUserRightHelpBox
  },
  inject: {
    getGroupsWithAssignedRoles: providerKeyEnum.groupsWithAssignedRoles,
    getCanCurrentUserAccessGroups: providerKeyEnum.canCurrentUserAccessGroups,
    onInstitutionGroupClick: UserDetailsPortalProvideKey.onInstitutionGroupClick,
  },
  computed: {
    groupsWithAssignedRoles() {
      return this.getGroupsWithAssignedRoles();
    },
    canCurrentUserAccessGroups() {
      return this.getCanCurrentUserAccessGroups();
    }
  },
  methods: {
    onGroupClicked(group) {
      if(this.canCurrentUserAccessGroups) {
        this.onInstitutionGroupClick({ id: group.groupId, institutionCode: group.institutionCode });
      }
    }
  }
}
</script>
<style scoped lang="scss">
.b-table {
  --table-btn-link-decoration: none;
  --questionmark-icon-background-color: var(--color-grey-base);
  --questionmark-icon-color: var(--color-primary-darker);
  --table-icon-font-size: 1rem;
}
</style>
