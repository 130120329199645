export const userDataTableCategory = Object.freeze({
  ADDITIONAL_DATA: 'Supplerende stamdata og tilladelser',
  CONSENTS: 'Deling af kontaktoplysninger',
  CONTACT_INFO: 'Kontaktinformation',
  DESCRIPTION: 'Beskrivelse',
  MASTER_DATA: 'Stamdata',
  GROUP: 'Gruppe',
  POST: 'Opslag',
  SUBSCRIPTION: 'Beskedtråd',
  EVENT: 'Begivenhed',
  INVITATION: 'Invitation',
  DOCUMENT: 'Dokument',
  MEDIA: 'Medie',
  PICKUP: 'Henteansvarlige',
  HOLIDAY: 'Ferie',
  COMMENTS: 'Kommentarer',
});
