<template>
  <div class="consent-content">
    <template v-if="multipleColumns">
      <additional-question-for-profiles
        v-for="(question, index) in additionalQuestions"
        :key="index"
        :question="question"
        :profiles="profiles"
        :is-submitted="isSubmitted"
        :is-disabled="isDisabled"
        :is-revision-history-view-mode="isRevisionHistoryViewMode"
        :is-edit-on-behalf-mode="isEditOnBehalfMode"
      />
    </template>
    <template v-else>
      <additional-question-for-profiles
        v-for="(question, questionIndex) in profile.additionals"
        :key="questionIndex"
        :question="question"
        :profiles="[profile]"
        :is-submitted="isSubmitted"
        :is-disabled="isDisabled"
        :is-multiple-profiles="false"
        :is-revision-history-view-mode="isRevisionHistoryViewMode"
        :is-edit-on-behalf-mode="isEditOnBehalfMode"
      />
    </template>
  </div>
</template>

<script>
import { additionalDataGrossListEnum } from '../../enums/additionalDataGrossList';
import AdditionalQuestionForProfiles from './AdditionalQuestionForProfiles';
import cloneDeep from 'lodash/cloneDeep';

export default {
  components: {
    AdditionalQuestionForProfiles,
  },
  props: {
    multipleColumns: { type: Boolean, default: true },
    isMobile: { type: Boolean, default: false },
    profileIndex: { type: Number, default: 0 },
    isSubmitted: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    questions: { type: Array, default: () => [] },
    profiles: { type: Array, default: () => [] },
    profile: { type: Object, default: null },
    isRevisionHistoryViewMode: { type: Boolean, default: false },
    isEditOnBehalfMode: { type: Boolean, default: false },
  },
  data() {
    return {
      additionalDataGrossListEnum: additionalDataGrossListEnum,
      additionalQuestions: [],
    };
  },
  watch: {
    questions() {
      this.additionalQuestions = cloneDeep(this.questions);
    },
  },
  mounted() {
    this.additionalQuestions = cloneDeep(this.questions);
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/core/variables';
@import '../../assets/scss/core/breakpoints';
@import '../../assets/scss/elements/consentAdditional';
</style>
