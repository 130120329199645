<template>
  <div
    v-if="amount > 0 || showExclamationIconInsteadOfAmount"
    class="badge-notification badge-notification-variables"
    :class="css"
  >
    <span class="sr-only">{{ 'ARIA_LABEL_NEW_CONTENT' | fromTextKey }}</span>
    <AulaBadge>
      <template v-if="amount > 0">
        <span v-if="amount < 100">{{ amount }}</span>
        <span v-else-if="amount >= 100">99+</span>
      </template>
      <i v-else-if="showExclamationIconInsteadOfAmount" class="icon-Aula_exclamation_sign" />
    </AulaBadge>
  </div>
</template>

<script>
import AulaBadge from './AulaBadge';
export default {
  name: 'BadgeNotification',
  components: { AulaBadge },
  props: {
    amount: { type: Number, default: 0 },
    showExclamationIconInsteadOfAmount: { type: Boolean, default: false },
    border: { type: Boolean, default: true },
    css: { type: String, default: '' },
  },
};
</script>

<style scoped lang="scss">
.badge-notification-variables {
  --position: absolute;
}
.badge-notification {
  position: var(--position);
}
</style>
