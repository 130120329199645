export const entityTypesRightToBeForgotten = Object.freeze({
  EVENTS: 'Events',
  THREADS: 'Threads',
  DOCUMENTS: 'Documents',
  MEDIA: 'Media',
  POSTS: 'Posts',
  GROUPS: 'Groups',
  COMMENTS: 'Comments',
  CONTACT_INFORMATION: 'AdditionalDataConsentContactInfo',
  PICKUP_RESPONSIBLES: 'PickupResponsibles',
});
