import { InstitutionProfileModel } from '../../shared/models/institutionProfile.model';
import { textUtil } from '../../shared/utils/textUtil';

export class InstitutionProfileMasterDataForm {
  static readonly DESCRIPTION_MAX_CHARACTER_NUMBER = 600;
  profileId: number;
  institutionProfileId?: number;
  email?: string;
  homePhoneNumber?: string;
  mobilePhoneNumber?: string;
  workPhoneNumber?: string;
  description?: string;

  constructor(institutionProfile: InstitutionProfileModel) {
    this.profileId = institutionProfile.profileId;
    this.institutionProfileId = institutionProfile.id;
    this.email = institutionProfile.email;
    this.homePhoneNumber = institutionProfile.homePhoneNumber;
    this.mobilePhoneNumber = institutionProfile.mobilePhoneNumber;
    this.workPhoneNumber = institutionProfile.workPhoneNumber;
    this.description = institutionProfile.institutionProfileDescriptions;
  }

  get isValidHomePhoneNumber() {
    return textUtil.isPhoneNumber(this.homePhoneNumber);
  }
  get isValidMobilePhoneNumber() {
    return textUtil.isPhoneNumber(this.mobilePhoneNumber);
  }
  get isValidWorkPhoneNumber() {
    return textUtil.isPhoneNumber(this.workPhoneNumber);
  }
  get isValidEmail() {
    return textUtil.isEmail(this.email);
  }
  get isValidDescription() {
    if (!this.description) {
      return true;
    }
    return this.description.length < InstitutionProfileMasterDataForm.DESCRIPTION_MAX_CHARACTER_NUMBER;
  }
  get isValid() {
    return (
      this.isValidHomePhoneNumber &&
      this.isValidMobilePhoneNumber &&
      this.isValidWorkPhoneNumber &&
      this.isValidEmail &&
      this.isValidDescription
    );
  }
}
