<template>
  <b-form-group class="mb-3">
    <label class="font-weight-bold" for="lock_user_reason">{{ 'USERS_LOCK_ADD_REASON' | fromTextKey }}</label>
    <b-textarea
      id="lock_user_reason"
      :placeholder="'USERS_LOCK_USER_PLACEHOLDER' | fromTextKey"
      :value="lockReason"
      :maxlength="maxCharacters"
      :rows="5"
      @input="onUpdateLockReason"
    />
    <b-alert variant="danger" :show="showLockReasonWarning">
      {{ 'USERS_LOCK_MAX_CHARACTERS' | fromTextKey }}
    </b-alert>
  </b-form-group>
</template>
<script lang="js">
import {UserDetailsPortalProvideKey} from './UsersDetailsPortalProvideKey';
import {lockUserConstant} from "./constants/lockUser";

export default {
  name: 'UserDetailsAdministrationLockUserFormGroupCause',
  inject: {
    getShowLockReasonWarning: UserDetailsPortalProvideKey.showLockReasonMaxCharacterWarning,
    getLockReason: UserDetailsPortalProvideKey.lockReasonValue,
    updateLockReason: UserDetailsPortalProvideKey.onUpdateLockReason,
  },
  data() {
    return {
      maxCharacters: lockUserConstant.LOCK_DESCRIPTION_CHARACTER_LIMIT,
    }
  },
  computed: {
    lockReason() {
      return this.getLockReason();
    },
    showLockReasonWarning() {
      return this.getShowLockReasonWarning();
    },
  },
  methods: {
    onUpdateLockReason(value) {
      this.updateLockReason(value);
    },
  }
}
</script>
<style lang="scss" scoped>
/deep/ .tox .tox-edit-area__iframe {
  background-color: var(--color-grey-light);
}
</style>
