<template>
  <div class="consent-page">
    <div v-if="!isMobile" class="consent-header">
      <span>
        {{ 'STEP_DESCRIPTION_CONSENT_1' | fromTextKey }} <br />
        {{ 'STEP_DESCRIPTION_CONSENT_2' | fromTextKey }}
      </span>
      <div class="d-flex align-items-center">
        <template v-if="isViewMode">
          <b-btn variant="primary" class="ml-3 pull-right" @click="stepUpLogin()">
            {{ 'BUTTON_EDIT' | fromTextKey }}
          </b-btn>
        </template>
        <template v-else>
          <b-btn variant="link" class="ml-3" @click="showCancelModal()">
            {{ 'CANCEL' | fromTextKey }}
          </b-btn>
          <b-btn variant="primary" class="ml-3" @click="showWarningModal()">
            {{ 'BUTTON_SAVE' | fromTextKey }}
          </b-btn>
        </template>
      </div>
    </div>
    <div class="consent-header-container">
      <div v-if="isMobile" class="consent-header-mobile">
        <mobile-topbar
          :left-icon="isViewMode ? 'icon-Aula_app_arrow_slideback' : null"
          :left-text="isViewMode ? null : 'CANCEL'"
          :text-key="'CONSENT_LABEL'"
          :right-text-icon="isViewMode ? 'icon-Aula_edit_pen' : null"
          :right-text="isViewMode ? 'BUTTON_EDIT_SMALL' : 'BUTTON_SAVE_SMALL'"
          :background-color="null"
          :color="null"
          :css-class="isViewMode ? 'consent-view-mode-header' : 'consent-edit-mode-header'"
          @leftIconClicked="close()"
          @leftTextClicked="showCancelModal()"
          @rightTextClicked="isViewMode ? stepUpLogin() : showWarningModal()"
        />
      </div>
    </div>
    <aula-spinner v-if="isLoading" />
    <div v-if="isMobile" class="p-3">
      <span>
        {{ 'STEP_DESCRIPTION_CONSENT_1' | fromTextKey }} <br />
        {{ 'STEP_DESCRIPTION_CONSENT_2' | fromTextKey }}
      </span>
    </div>
    <consent-additional
      v-if="
        !isLoading &&
        (profile.role == portalRoles.GUARDIAN ||
          profile.role == portalRoles.CHILD ||
          profile.role == portalRoles.OTP) &&
        getFilteredConsents().length > 0
      "
      ref="consentMyProfile"
      :class="{ 'consent-table-mobile': isMobile, 'view-mode': isViewMode }"
      :data="getFilteredConsents()"
      :show-title="false"
      :is-disabled="isViewMode"
    />
    <aula-modal
      ref="cancelModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.cancelModal.hide()"
      @okClicked="cancelModalOkClicked()"
    >
      {{ 'CONSENT_CANCEL_MODAL_TEXT1' | fromTextKey }}<br />
      {{ 'CONSENT_CANCEL_MODAL_TEXT2' | fromTextKey }}
    </aula-modal>
    <aula-modal ref="warningModal" @cancelClicked="warningModalUndoClicked()" @okClicked="warningModalOkClicked()">
      <template v-for="(consent, i) in declinedSpecialConsentIds">
        {{ getDeclinedWarningMessage(consent) }}<br :key="i" />
      </template>
      {{ 'CONSENT_WARNING_DECLINED_2' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { types } from '../../store/types/types';
import { portalRoles } from '../../../shared/enums/portalRoles';
import ConsentAdditional from '../../../shared/components/consentAdditional/ConsentAdditional';
import MobileTopbar from '../../../shared/components/MobileTopbar';
import { consentNumber } from '../../../shared/enums/consentNumber';
import Vue from 'vue';

export default {
  props: {
    parent: { type: String, default: 'profile' },
  },
  data: function () {
    return {
      portalRoles: portalRoles,
      consentNumber: consentNumber,
      isViewMode: true,
      isLoading: false,
      declinedSpecialConsentIds: [],
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      profileConsent: types.GET_MYPROFILE_CONSENTS,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      isMobile: types.GET_IS_MOBILE,
      activeGroupHome: types.GET_ACTIVE_GROUP_HOME,
      groupHomes: types.GET_GROUP_HOMES,
    }),
  },
  emits: ['closeConsent'],
  methods: {
    ...mapMutations({
      resetConsent: types.MUTATE_RESET_MYPROFILE_CONSENT,
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
    }),
    ...mapActions({
      getConsentResponse: types.ACTION_GET_CONSENTS_RESPONSE,
      updateConsentResponses: types.ACTION_UPDATE_CONSENT_RESPONSES,
      setIsMobile: types.MUTATE_IS_MOBILE,
      setIsTablet: types.MUTATE_IS_TABLET,
    }),
    getFilteredConsents() {
      if (this.profile.role === this.portalRoles.OTP) {
        return this.getFilteredConsentsForActiveGroupHome();
      } else {
        return this.profileConsent;
      }
    },
    getFilteredConsentsForActiveGroupHome() {
      const groupHomeChildren = this.activeGroupHome.children.map(child => child.id);
      const consentsReleatedToGroupHomeChildren = this.profileConsent.filter(consent =>
        groupHomeChildren.includes(consent.institutionProfile.id)
      );
      return consentsReleatedToGroupHomeChildren;
    },
    getDeclinedWarningMessage(consent) {
      let message;
      if (consent.consentId == this.consentNumber.NUMBER_36) {
        const previousScope = Vue.filter('fromTextKey')('COMPLEX_CONSENT_' + consent.previousAnswer.toUpperCase());
        message = Vue.filter('fromTextKey')('CONSENT_WARNING_DECLINED_CONSENT_NUMBER_' + consent.consentId, {
          previousScope: previousScope,
        });
      } else {
        message = Vue.filter('fromTextKey')('CONSENT_WARNING_DECLINED_CONSENT_NUMBER_' + consent.consentId);
      }
      return message;
    },
    submitConsent() {
      this.isViewMode = true;
      const data = this.$refs.consentMyProfile.submitConsentResponse();
      this.updateConsentResponses(data).then(() => {
        this.init();
      });
    },
    showWarningModal() {
      this.declinedSpecialConsentIds = this.$refs.consentMyProfile.declinedSpecialQuestionEdited();
      if (this.declinedSpecialConsentIds.length > 0) {
        this.$refs.warningModal.show();
      } else {
        this.submitConsent();
      }
    },
    warningModalUndoClicked() {
      this.$refs.warningModal.hide();
    },
    warningModalOkClicked() {
      this.$refs.warningModal.hide();
      this.submitConsent();
    },
    showCancelModal() {
      if (!this.$refs.consentMyProfile.isTableEdited()) {
        this.isViewMode = true;
      } else {
        this.$refs.cancelModal.show();
      }
    },
    cancelModalOkClicked() {
      this.isViewMode = true;
      this.$refs.cancelModal.hide();
      this.$refs.consentMyProfile.resetTable();
    },
    stepUpLogin() {
      if (this.isSteppedUp) {
        this.isViewMode = false;
        return;
      }
      this.setStepUpNotification({
        showStepUpNotification: true,
        redirectedUrl: window.location.href,
        cancelUrl: null,
      });
    },
    close() {
      this.$emit('close');
    },
    init() {
      this.isLoading = true;
      this.resetConsent();
      this.getConsentResponse().then(() => (this.isLoading = false));
    },
    detectIsMobile() {
      this.setIsMobile();
      this.setIsTablet();
    },
  },
  mounted() {
    if (!this.isSteppedUp) {
      this.init();
    }

    window.addEventListener('orientationchange', this.detectIsMobile);
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.detectIsMobile, false);
  },
  components: {
    MobileTopbar,
    ConsentAdditional,
  },
};
</script>
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables';
@import '../../../shared/assets/scss/core/breakpoints';

.consent-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

@include breakpoint-lg-down() {
  .consent-header-container {
    color: var(--color-white);
    position: sticky;
    top: 0;
    z-index: $modal-sticky-header-z-index;
  }

  .consent-page {
    position: fixed;
    top: 0;
    left: 0;
    bottom: var(--menu-item-height);
    z-index: $consent-header-mobile-z-index;
    overflow: auto;
  }
}
</style>
