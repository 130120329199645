<template>
  <b-td v-bind="{ ...$attrs }" :class="classes">
    <slot />
  </b-td>
</template>
<script lang="js">
import {tableProvider} from "../providers/tableProvider";
import {collapseDirectionEnum} from "../enums/collapseDirectionEnum";
import {collapseDirectionProp} from "../props/collapseDirectionProp";

export default {
  name: 'TableCell',
  inject: {
    getIsHoverable: tableProvider.IS_HOVERABLE,
    getCollapseDirections: tableProvider.COLLAPSE_DIRECTIONS,
    getVerticalAlign: tableProvider.VERTICAL_ALIGN,
  },
  props: {
    collapseDirections: collapseDirectionProp,
  },
  computed: {
    hoverable() {
      return this.getIsHoverable();
    },
    verticalAlign() {
      return this.getVerticalAlign();
    },
    collapseDirectionClasses() {
      const classes = [];
      let directions = this.getCollapseDirections();

      if (this.collapseDirections != null) {
        directions = this.collapseDirections;
      }

      for (const direction of directions) {
        switch (direction) {
          case collapseDirectionEnum.TOP:
            classes.push('collapse-top');
            break;
          case collapseDirectionEnum.BOTTOM:
            classes.push('collapse-bottom');
            break;
          case collapseDirectionEnum.LEFT:
            classes.push('collapse-left');
            break;
          case collapseDirectionEnum.RIGHT:
            classes.push('collapse-right');
            break;
        }
      }

      return classes;
    },
    classes() {
      const classes = ['table-cell', ...this.collapseDirectionClasses];

      if (!this.hoverable) {
        classes.push('not-hoverable');
      }

      if (!this.verticalAlign) {
        classes.push('not-vertical-aligned');
      }

      return classes;
    }
  }
};
</script>
<style lang="scss" scoped>
td.table-cell {
  background-color: var(--color-base) !important;
  border-right-color: var(--border-color-right, var(--color-base)) !important;
  border-left-color: var(--border-color-left, var(--color-base)) !important;
  border-top-color: var(--border-color-top, var(--color-base)) !important;
  border-bottom-color: var(--border-color-bottom, var(--color-base)) !important;

  &.not-hoverable:hover {
    cursor: auto;
    background-color: var(--color-base) !important;
  }

  &.not-vertical-aligned {
    vertical-align: top;
  }

  &.collapse-right {
    border-right-style: unset !important;
    border-right-width: 0 !important;
  }

  &.collapse-left {
    border-left-style: unset !important;
    border-left-width: 0 !important;
  }

  &.collapse-bottom {
    border-bottom-style: unset !important;
    border-bottom-width: 0 !important;
  }

  &.collapse-top {
    border-top-style: unset !important;
    border-top-width: 0 !important;
  }
}
</style>
