import { roleEnum } from './roleEnum';
import {
  INSTITUTION_ADMINISTRATOR_DESCRIPTION_REVISION_HISTORY,
  INSTITUTION_ADMINISTRATOR_REVISION_HISTORY,
} from '../../src_adm/texts/users';

export const municipalAdministratorRightDescriptions = [
  {
    roleDefinitionEnum: roleEnum.HANDLE_RIGHT_TO_INSIGHT,
    userRightTextKey: 'MUNICIPAL_ADMINISTRATOR_HANDLE_RIGHT_TO_INSIGHT',
    rightDescriptionTextKey: 'MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_RIGHT_TO_INSIGHT',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_REPORTS,
    userRightTextKey: 'MUNICIPAL_ADMINISTRATOR_HANDLE_REPORTS',
    rightDescriptionTextKey: 'MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_REPORTS',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_ALLOWED_RECIPIENTS,
    userRightTextKey: 'MUNICIPAL_ADMINISTRATOR_HANDLE_ALLOWED_RECIPIENTS',
    rightDescriptionTextKey: 'MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_ALLOWED_RECIPIENTS',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_USER_ROLES,
    userRightTextKey: 'MUNICIPAL_ADMINISTRATOR_HANDLE_USER_ROLES',
    rightDescriptionTextKey: 'MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_USER_ROLES',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_RESOURCES_MUNICIPALITY,
    userRightTextKey: 'MUNICIPAL_ADMINISTRATOR_HANDLE_RESOURCES_MUNICIPALITY',
    rightDescriptionTextKey: 'MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_RESOURCES_MUNICIPALITY',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_COMMUNICATION_CHANNELS,
    userRightTextKey: 'MUNICIPAL_ADMINISTRATOR_HANDLE_COMMUNICATION_CHANNELS',
    rightDescriptionTextKey: 'MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_COMMUNICATION_CHANNELS',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_SHARED_INBOX,
    userRightTextKey: 'MUNICIPAL_ADMINISTRATOR_HANDLE_SHARED_INBOX',
    rightDescriptionTextKey: 'MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_SHARED_INBOX',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_ADMINISTRATIVE_AUTHORITY,
    userRightTextKey: 'MUNICIPAL_ADMINISTRATOR_HANDLE_ADMINISTRATIVE_AUTHORITY',
    rightDescriptionTextKey: 'MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_ADMINISTRATIVE_AUTHORITY',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_GROUPS_MUNICIPALITY,
    userRightTextKey: 'MUNICIPAL_ADMINISTRATOR_HANDLE_GROUPS_MUNICIPALITY',
    rightDescriptionTextKey: 'MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_GROUPS_MUNICIPALITY',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_TRANSITION_YEAR,
    userRightTextKey: 'MUNICIPAL_ADMINISTRATOR_HANDLE_TRANSITION_YEAR',
    rightDescriptionTextKey: 'MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_TRANSITION_YEAR',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_DASHBOARDS,
    userRightTextKey: 'MUNICIPAL_ADMINISTRATOR_HANDLE_DASHBOARDS',
    rightDescriptionTextKey: 'MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_DASHBOARDS',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_MUNICIPAL_FILE_SENT_TO_ESDH,
    userRightTextKey: 'MUNICIPAL_ADMINISTRATOR_HANDLE_MUNICIPAL_FILES_TO_ESDH',
    rightDescriptionTextKey: 'MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_MUNICIPAL_FILES_TO_ESDH',
  },
];

export const institutionsAdministratorDescriptions = [
  {
    roleDefinitionEnum: roleEnum.HANDLE_REPORTS,
    userRightTextKey: 'INSTITUTION_ADMINISTRATOR_HANDLE_REPORTS',
    rightDescriptionTextKey: 'INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_REPORTS',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_ADDITIONAL_MASTER_DATA,
    userRightTextKey: 'INSTITUTION_ADMINISTRATOR_HANDLE_ADDITIONAL_MASTER_DATA',
    rightDescriptionTextKey: 'INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_ADDITIONAL_MASTER_DATA',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_USER_ROLES,
    userRightTextKey: 'INSTITUTION_ADMINISTRATOR_HANDLE_USER_ROLES',
    rightDescriptionTextKey: 'INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_USER_ROLES',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_RESOURCES_INSTITUTION,
    userRightTextKey: 'INSTITUTION_ADMINISTRATOR_HANDLE_RESOURCES_INSTITUTION',
    rightDescriptionTextKey: 'INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_RESOURCES_INSTITUTION',
  },
  {
    roleDefinitionEnum: roleEnum.PRESENCE_PLANNING,
    userRightTextKey: 'INSTITUTION_ADMINISTRATOR_PRESENCE_PLANNING',
    rightDescriptionTextKey: 'INSTITUTION_ADMINISTRATOR_DESCRIPTION_PRESENCE_PLANNING',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_SHARED_INBOX,
    userRightTextKey: 'INSTITUTION_ADMINISTRATOR_HANDLE_SHARED_INBOX',
    rightDescriptionTextKey: 'INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_SHARED_INBOX',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_COMMON_FILES,
    userRightTextKey: 'INSTITUTION_ADMINISTRATOR_HANDLE_COMMON_FILES',
    rightDescriptionTextKey: 'INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_COMMON_FILES',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_GROUPS_INSTITUTION,
    userRightTextKey: 'INSTITUTION_ADMINISTRATOR_HANDLE_GROUPS_INSTITUTION',
    rightDescriptionTextKey: 'INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_GROUPS_INSTITUTION',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_DASHBOARDS,
    userRightTextKey: 'INSTITUTION_ADMINISTRATOR_HANDLE_DASHBOARDS',
    rightDescriptionTextKey: 'INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_DASHBOARDS',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_PRESENCE,
    userRightTextKey: 'INSTITUTION_ADMINISTRATOR_HANDLE_PRESENCE',
    rightDescriptionTextKey: 'INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_PRESENCE',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_DELETED_SECURE_FILES,
    userRightTextKey: 'INSTITUTION_ADMINISTRATOR_HANDLE_DELETED_SECURE_FILES',
    rightDescriptionTextKey: 'INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_DELETED_SECURE_FILES',
  },
  {
    roleDefinitionEnum: roleEnum.REVISION_HISTORY,
    userRightTextKey: 'INSTITUTION_ADMINISTRATOR_REVISION_HISTORY',
    rightDescriptionTextKey: 'INSTITUTION_ADMINISTRATOR_DESCRIPTION_REVISION_HISTORY',
  },
];

export const otherRightsDescriptions = [
  {
    roleDefinitionEnum: roleEnum.BOARD_MEMBER,
    userRightTextKey: 'OTHER_ADMINISTRATOR_BOARD_MEMBER',
    rightDescriptionTextKey: 'OTHER_ADMINISTRATOR_DESCRIPTION_BOARD_MEMBER',
  },
  {
    roleDefinitionEnum: roleEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS,
    userRightTextKey: 'OTHER_ADMINISTRATOR_USE_GROUPS_AS_DISTRIBUTION_LISTS',
    rightDescriptionTextKey: 'OTHER_ADMINISTRATOR_DESCRIPTION_USE_GROUPS_AS_DISTRIBUTION_LISTS',
  },
  {
    roleDefinitionEnum: roleEnum.EXPORT_SECURE_FILES,
    userRightTextKey: 'OTHER_ADMINISTRATOR_EXPORT_SECURE_FILES',
    rightDescriptionTextKey: 'OTHER_ADMINISTRATOR_DESCRIPTION_EXPORT_SECURE_FILES',
  },
  {
    roleDefinitionEnum: roleEnum.HANDLE_OTHERS_EVENTS,
    userRightTextKey: 'OTHER_ADMINISTRATOR_HANDLE_OTHERS_EVENTS',
    rightDescriptionTextKey: 'OTHER_ADMINISTRATOR_DESCRIPTION_HANDLE_OTHERS_EVENTS',
  },
  {
    roleDefinitionEnum: roleEnum.CONTACT_PARENT,
    userRightTextKey: 'OTHER_ADMINISTRATOR_SEE_CONTACT_PARENTS_CONTACT_INFO',
    rightDescriptionTextKey: 'OTHER_ADMINISTRATOR_DESCRIPTION_SEE_CONTACT_PARENTS_CONTACT_INFO',
  },
  {
    roleDefinitionEnum: roleEnum.INSTITUTION_CALENDAR_ADMIN,
    userRightTextKey: 'OTHER_ADMINISTRATOR_INSTITUTION_CALENDAR_ADMIN',
    rightDescriptionTextKey: 'OTHER_ADMINISTRATOR_DESCRIPTION_INSTITUTION_CALENDAR_ADMIN',
  },
  {
    roleDefinitionEnum: roleEnum.CREATE_PARENTAL_PERFORMANCE_MEETINGS,
    userRightTextKey: 'OTHER_ADMINISTRATOR_CREATE_PARENTAL_PERFORMANCE_MEETINGS',
    rightDescriptionTextKey: 'OTHER_ADMINISTRATOR_DESCRIPTION_CREATE_PARENTAL_PERFORMANCE_MEETINGS',
  },
  {
    roleDefinitionEnum: roleEnum.ASSOCIATE_SECURE_DOCUMENTS_TO_ALL_GROUPS,
    userRightTextKey: 'OTHER_ADMINISTRATOR_ASSOCIATE_SECURE_DOCUMENTS_TO_ALL_GROUPS',
    rightDescriptionTextKey: 'OTHER_ADMINISTRATOR_DESCRIPTION_ASSOCIATE_SECURE_DOCUMENTS_TO_ALL_GROUPS',
  },
  {
    roleDefinitionEnum: roleEnum.VIEW_NAME_PROTECTION,
    userRightTextKey: 'OTHER_ADMINISTRATOR_VIEW_NAME_PROTECTION',
    rightDescriptionTextKey: 'OTHER_ADMINISTRATOR_DESCRIPTION_VIEW_NAME_PROTECTION',
  },
  {
    roleDefinitionEnum: roleEnum.VIEW_PERSONAL_REFERENCE_DATA_FOR_ALL_CHILDREN_AND_GUARDIAN,
    userRightTextKey: 'OTHER_ADMINISTRATOR_VIEW_PERSONAL_REFERENCE_DATA_FOR_ALL_CHILDREN_AND_GUARDIAN',
    rightDescriptionTextKey:
      'OTHER_ADMINISTRATOR_DESCRIPTION_VIEW_PERSONAL_REFERENCE_DATA_FOR_ALL_CHILDREN_AND_GUARDIAN',
  },
  {
    roleDefinitionEnum: roleEnum.SECURE_DOCUMENTS_ACCESS_ALL,
    userRightTextKey: 'OTHER_ADMINISTRATOR_SECURE_DOCUMENTS_ACCESS_ALL',
    rightDescriptionTextKey: 'OTHER_ADMINISTRATOR_DESCRIPTION_SECURE_DOCUMENTS_ACCESS_ALL',
  },
  {
    roleDefinitionEnum: roleEnum.VIEW_CONTACT_INFORMATION_ALL,
    userRightTextKey: 'OTHER_ADMINISTRATOR_VIEW_CONTACT_INFORMATION_ALL',
    rightDescriptionTextKey: 'OTHER_ADMINISTRATOR_DESCRIPTION_VIEW_CONTACT_INFORMATION_ALL',
  },
  {
    roleDefinitionEnum: roleEnum.ACCESS_GOOGLE_DRIVE,
    userRightTextKey: 'OTHER_ADMINISTRATOR_ACCESS_GOOGLE_DRIVE',
    rightDescriptionTextKey: 'OTHER_ADMINISTRATOR_DESCRIPTION_ACCESS_GOOGLE_DRIVE',
  },
  {
    roleDefinitionEnum: roleEnum.ACCESS_ONEDRIVE,
    userRightTextKey: 'OTHER_ADMINISTRATOR_ACCESS_ONEDRIVE',
    rightDescriptionTextKey: 'OTHER_ADMINISTRATOR_DESCRIPTION_ACCESS_ONEDRIVE',
  },
  {
    roleDefinitionEnum: roleEnum.EDIT_SHARED_ALBUMS,
    userRightTextKey: 'INSTITUTION_ADMINISTRATOR_EDIT_SHARED_ALBUMS',
    rightDescriptionTextKey: 'INSTITUTION_ADMINISTRATOR_EDIT_SHARED_ALBUMS_DESCRIPTION',
  },
  {
    roleDefinitionEnum: roleEnum.JOURNALING_TO_ESDH,
    userRightTextKey: 'OTHER_ADMINISTRATOR_JOURNALIZING_TO_ESDH_SYSTEM',
    rightDescriptionTextKey: 'OTHER_ADMINISTRATOR_DESCRIPTION_JOURNALIZING_TO_ESDH_SYSTEM',
  },
];
