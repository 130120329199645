export const consentAnswerType = Object.freeze({
  CLASS_OR_STUE: 'class_or_stue',
  YEAR_AND_SFO: 'year_and_sfo',
  INSTITUTION: 'institution',
  NOT_AT_ALL: 'not_at_all',
  DECLINED: 'declined',
  ACCEPTED: 'accepted',
  YES: 'yes',
  NO: 'no',
});
