<template>
  <form novalidate>
    <UserDetailsAdministrationLockUserFormGroupCause />
  </form>
</template>
<script lang="js">
import {UserDetailsPortalProvideKey} from'./UsersDetailsPortalProvideKey';
import UserDetailsAdministrationLockUserFormGroupCause from "./UserDetailsAdministrationLockUserFormGroupCause";

export default {
  name: 'UserDetailsPortalAdministrationLockUserForm',
  components: {
    UserDetailsAdministrationLockUserFormGroupCause,
  },
  provide() {
    return {
      [UserDetailsPortalProvideKey.lockReasonValue]: () => this.value,
      [UserDetailsPortalProvideKey.onUpdateLockReason]: lockReason => this.$emit('input', lockReason),
      [UserDetailsPortalProvideKey.showLockReasonMaxCharacterWarning]: () => this.showMaxCharactersExceededOnLockReason,
    };
  },
  props: {
    value: {
      required: true,
      type: String,
    },
    showMaxCharactersExceededOnLockReason: {
      required: false,
      default: false,
      type: Boolean,
    }
  },
}
</script>
