









































import AulaImg from '../../../shared/components/AulaImg.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';
import IconContainer from '../../../shared/components/IconContainer.vue';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';

export default {
  components: { Icon, IconContainer, AulaButton, AulaImg },
  props: {
    file: { type: File, default: null },
  },
  emits: ['rotate', 'delete'],
  data() {
    return {
      rotationDegree: 0,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    imageThumbnaiUrl() {
      return URL.createObjectURL(this.file);
    },
  },
  methods: {
    rotate(degree) {
      this.rotationDegree = this.rotationDegree + degree;
      this.$refs.imageThumbnail.$el.style.transform = 'rotate(' + this.rotationDegree + 'deg)';
      let angle = this.rotationDegree % 360;
      if (angle < 0) {
        angle += 360;
      }
      return this.$emit('rotate', angle);
    },
    deleteClicked() {
      return this.$emit('delete');
    },
  },
};
