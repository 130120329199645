import { HttpError } from '../../../src/business/httpError';
import { AxiosError } from 'axios';
import { portal } from '../../assets/plugins/axios/axios';
import { ConsentResponseRevisionHistoryModel } from '../../models/ConsentResponseRevisionHistory.model';

class ConsentsAdminService {
  public getConsentResponseRevisionHistory = async (
    institutionProfileId: number
  ): Promise<ConsentResponseRevisionHistoryModel> => {
    try {
      const { data } = await portal.get('?method=consentsAdmin.getConsentResponseRevisionHistory', {
        params: { institutionProfileId },
      });
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
}

export const consentsAdminService = new ConsentsAdminService();
